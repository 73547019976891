import React, { FormEvent } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card } from "reactstrap";
import Container from "reactstrap/lib/Container";
import { LoginData, ResetConfirm } from "../../auth";
import { NewPasswordForm } from "../../components/NewPasswordForm/NewPasswordForm.component";
import { PasswordResetConfirmForm } from "../../components/PasswordResetConfirmForm/PasswordResetConfirmForm.component";
import { PasswordResetForm } from "../../components/PasswordResetForm/PasswordResetForm.component";
import { SigninForm } from "../../components/SigninForm/SigninForm.component";
import { LoginPageProps } from "./Signin";
import { LoginStates } from "./Signin.action.types";

const SigninComponent = ({
  location,
  setRedirect,
  signin,
  resetPassword,
  changeFormTo,
  history,
  errorMessage,
  confirmReset,
  setNewPassword,
  signinState,
  redirect
}: LoginPageProps) => {
  useEffect(() => {
    const redirectPath: string = (location.state || {}).from || "/search";
    if (redirect !== redirectPath && !redirectPath.startsWith("/signin")) {
      setRedirect(redirectPath);
    }
    const expireIn = localStorage.getItem("expire");
    const auth = localStorage.getItem("auth");
    if (expireIn && auth) {
      const expireInNumber = parseInt(expireIn, 10);
      if (expireInNumber > new Date().getTime() / 1000) {
        history.push(redirectPath);
      }
    }
  });
  const onSubmit = (
    e: FormEvent,
    credentials: LoginData,
    remember: boolean
  ) => {
    e.preventDefault();
    localStorage.setItem("rememberMe", remember ? `${remember}` : "");
    signin(credentials);
  };

  const onReset = (e: FormEvent, value: string) => {
    e.preventDefault();
    resetPassword(value);
  };

  const returnToLogin = () => {
    changeFormTo(LoginStates.signin);
  };

  const onConfirm = (e: FormEvent, data: ResetConfirm) => {
    e.preventDefault();
    confirmReset(data);
  };

  const onSetNewPassword = (e: FormEvent, password: string) => {
    e.preventDefault();
    setNewPassword(password);
  };

  return (
    <div
      className={"d-flex"}
      style={{
        height: "100vh",
        alignItems: "center"
      }}
    >
      <Container className={"d-flex justify-content-center"}>
        <Card>
          {LoginStates.signin === signinState && (
            <SigninForm
              handleSubmit={(e, { email, password, rememberMe }) =>
                onSubmit(e, { email, password }, rememberMe)
              }
              errorMessage={errorMessage}
              resetPasswordState={() => changeFormTo(LoginStates.reset)}
            />
          )}

          {LoginStates.reset === signinState && (
            <PasswordResetForm
              handleReset={(e, value) => onReset(e, value)}
              returnToLogin={returnToLogin}
            />
          )}

          {LoginStates.confirm === signinState && (
            <PasswordResetConfirmForm
              onConfirm={(e, data) => onConfirm(e, data)}
              returnToLogin={returnToLogin}
            />
          )}
          {LoginStates.challengePassword === signinState && (
            <NewPasswordForm
              handleNewPassword={(e, password) => onSetNewPassword(e, password)}
              returnToLogin={returnToLogin}
            />
          )}
        </Card>
      </Container>
    </div>
  );
};

export const SigninView = withRouter(SigninComponent);
