import "bootstrap/dist/css/bootstrap.css";
import "britecharts-react/dist/britecharts-react.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import { ConnectedRouter } from "connected-react-router";
import { configureStore, history } from "./App.store";
const appContainer = document.getElementById("root");

const store = configureStore(undefined);
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { createAuthInterceptor, createRefreshInterceptor } from "./auth";

createAuthInterceptor(store);
createRefreshInterceptor(store);

library.add(faSignOutAlt);

// If the app container is present then render the app.
if (appContainer) {
  ReactDOM.render(
    <Provider store={store as any}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    appContainer
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
