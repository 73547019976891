import {
  ADD_ALERT,
  AlertActionTypes,
  AlertTypes,
  REMOVE_ALERT
} from "./Alerts.action.types";

export const addAlertAction = (
  alertType: AlertTypes,
  message: string
): AlertActionTypes => ({
  type: ADD_ALERT,
  alertType,
  message
});

export const removeAlertAction = (index: number): AlertActionTypes => ({
  type: REMOVE_ALERT,
  index
});
