import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState } from "react";

interface Props {
  importFiles: (files: File[]) => void;
  className: string;
}

export const FileUpload = ({ importFiles, className }: Props) => {
  const [activeClass, setClass] = useState("");
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    const event = e;
    event.stopPropagation();
    setClass("active-dnd");
    event.preventDefault();
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    const event = e;
    event.stopPropagation();
    event.preventDefault();
    setClass("active-dnd");
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const event = e;
    event.stopPropagation();
    event.preventDefault();
    const items = e.dataTransfer.items;
    const files: File[] = [];
    Object.keys(items).forEach((key: string) => {
      const file = items[Number(key)].getAsFile();
      if (file) {
        files.push(file);
      }
    });
    importFiles(files);
    setClass("");
  };

  const onDragLeave = () => {
    setClass("");
  };
  return (
    <div
      data-test-id={"file-upload-dnd"}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onFileDrop}
      onDragLeave={onDragLeave}
      className={
        className +
        " dnd-block pt-5 pb-4 d-block align-items-center " +
        activeClass
      }
    >
      <label
        htmlFor="file-upload"
        className={
          "col h-100 text-center justify-content-center align-items-center d-flex flex-column"
        }
      >
        <div className={"d-flex flex-column align-items-center"}>
          <FontAwesomeIcon icon={faFile} color={"#c6c6c6"} size="3x" />
          <h6 className={"text-secondary my-2"}>
            Drag File(s) or Click to Upload CSV/JSON
          </h6>
        </div>
        <input
          className={"d-none"}
          id="file-upload"
          type="file"
          multiple
          onChange={e => {
            const files = e.target.files;
            if (files) {
              const items = Object.keys(files).map(
                (key: string) => files[Number(key)]
              );
              importFiles(items);
            }
          }}
        />
      </label>
    </div>
  );
};
