import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";

export interface WorkBadgeProps {
  works: string[];
  id: string;
}

export const WorkBadge = ({ works, id }: WorkBadgeProps) => (
  <React.Fragment>
    <span className="badge badge-light badge-pill" id={id.replace(/\W/g, "")}>
      {works.length} Works
    </span>
    <UncontrolledTooltip placement="bottom" target={id.replace(/\W/g, "")}>
      {works.map(work => (
        <div className="text-truncate text-left" key={work}>
          {work}
        </div>
      ))}
    </UncontrolledTooltip>
  </React.Fragment>
);
