import * as React from "react";
import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";

import { ImportStatus, Organization } from "../../types";

interface FormValuesType {
  status?: ImportStatus;
  namespace?: string;
}

interface Props {
  values: FormValuesType;
  onValueChange: (values: FormValuesType) => void;
  organizations: Organization[];
}

export const ImportsFilter = ({
  values,
  onValueChange,
  organizations
}: Props) => {
  const [dropdownNamespaceOpen, setDropdownNamespaceOpen] = useState(false);
  const [dropdownStateOpen, setDropdownStateOpen] = useState(false);
  const onNamespaceChange = (namespace?: string) => {
    onValueChange({
      namespace,
      status: values.status
    });
  };
  const onStateChange = (status?: ImportStatus) => {
    onValueChange({
      namespace: values.namespace,
      status
    });
  };

  const currentState = (value?: ImportStatus): string => {
    switch (value) {
      case ImportStatus.COMPLETED:
        return "Completed";
      case ImportStatus.COMPLETED_WITH_ERRORS:
        return "Completed with Errors";
      case ImportStatus.FAILED:
        return "Failed";
      case ImportStatus.IN_PROGRESS:
        return "In Progress";
      default:
        return "All States";
    }
  };

  const getOrganizationName = (): string => {
    const org = organizations.find(o => o.id === values.namespace);
    return org ? org.name : "All Suppliers";
  };
  return (
    <div className={"d-block mt-5 mb-4"}>
      <div className={"d-flex"}>
        <Dropdown
          isOpen={dropdownNamespaceOpen}
          toggle={() => setDropdownNamespaceOpen(!dropdownNamespaceOpen)}
          className={"mr-2 d-flex"}
        >
          <DropdownToggle caret color={"primary"}>
            {getOrganizationName()}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => onNamespaceChange()}>
              All Suppliers
            </DropdownItem>
            {organizations.map(
              organization =>
                organization && (
                  <DropdownItem
                    key={organization.id}
                    onClick={() => onNamespaceChange(organization.id)}
                  >
                    {organization.name}
                  </DropdownItem>
                )
            )}
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          isOpen={dropdownStateOpen}
          toggle={() => setDropdownStateOpen(!dropdownStateOpen)}
          className={"d-flex"}
        >
          <DropdownToggle caret outline>
            {currentState(values.status)}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => onStateChange()}>
              All States
            </DropdownItem>
            <DropdownItem onClick={() => onStateChange(ImportStatus.COMPLETED)}>
              Completed
            </DropdownItem>
            <DropdownItem
              onClick={() => onStateChange(ImportStatus.COMPLETED_WITH_ERRORS)}
            >
              Completed with Errors
            </DropdownItem>
            <DropdownItem
              onClick={() => onStateChange(ImportStatus.IN_PROGRESS)}
            >
              In Progress
            </DropdownItem>
            <DropdownItem onClick={() => onStateChange(ImportStatus.FAILED)}>
              Failed
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};
