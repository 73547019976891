import { Work } from "../../types";
import {
  FETCH_SEARCH,
  FETCH_SEARCH_FAIL,
  FETCH_SEARCH_SUCCESS,
  SearchActionTypes
} from "./Search.action.types";

export const fetchSearchSuccessAction = (
  searchResult: Work[],
  nextLink: string
): SearchActionTypes => ({
  type: FETCH_SEARCH_SUCCESS,
  searchResult,
  nextLink
});

export const fetchSearchAction = (
  type: string,
  id: string,
  nextPage: boolean
): SearchActionTypes => ({
  type: FETCH_SEARCH,
  searchType: type,
  id,
  nextPage
});

export const fetchSearchFailAction = (error: string): SearchActionTypes => ({
  type: FETCH_SEARCH_FAIL,
  error
});
