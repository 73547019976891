import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { ExportType } from "../../types";
import { createExportEffect } from "../Export/Export.effects";
import { ImportView } from "./Import.component";
import {
  cancelImportEffect,
  downloadImportEffect,
  downloadLogFileEffect,
  fetchImportDetailsEffect,
  fetchImportsEffect,
  fetchOrganizationsEffect,
  setPeriodicRefreshEffect,
  uploadFilesEffect
} from "./Import.effects";

const mapStateToProps = ({ imports, signin }: AppState) => ({
  importsList: imports.importsList,
  organizations: imports.organizations,
  loadingState: imports.loadingState,
  importsError: imports.importsError,
  nextToken: imports.nextToken,
  fileImportError: imports.fileImportError,
  fileDownloadError: imports.fileDownloadError,
  user: signin.user
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  uploadFiles: (files: File[]) => dispatch(uploadFilesEffect(files)),
  fetchImports: (nextPage?: boolean) => dispatch(fetchImportsEffect(nextPage)),
  fetchImportDetails: (importId: string) =>
    dispatch(fetchImportDetailsEffect(importId)),
  fetchOrganizations: () => dispatch(fetchOrganizationsEffect()),
  stopPeriodicRefresh: () => dispatch(setPeriodicRefreshEffect(0)),
  fetchExportByImportId: (importId: string) =>
    dispatch(createExportEffect({ importId, exportType: ExportType.ISWC })),
  cancelImport: (importId: string) => dispatch(cancelImportEffect(importId)),
  downloadLogFile: (importId: string, file: string) =>
    dispatch(downloadLogFileEffect(importId, file)),
  downloadImport: (importId: string, downloadToken: string) =>
    dispatch(downloadImportEffect(importId, downloadToken))
});

export const ImportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportView);

export type ImportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{
    status: string;
    namespace: string;
  }>;
