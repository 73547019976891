import axios from "axios";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { apiUrls } from "../../api.config";
import {
  ErrorResponse,
  ExportDetailsResponse,
  ExportDownloadLinkResponse,
  ExportResponse,
  ExportsResponse
} from "../../api.types";
import { AppState } from "../../App.reducer";
import { DownloadType } from "../../types";
import { isExportStatusLoading } from "../../utils/helpers";
import { addAlertAction } from "../Alerts/Alert.actions";
import {
  fetchExportDetailsSuccessAction,
  fetchExportDownloadFailAction,
  fetchExportsAction,
  fetchExportsFailAction,
  fetchExportsSuccessAction,
  postExportAction,
  postExportFailAction,
  postExportSuccessAction,
  setPeriodicExportsRefreshAction
} from "./Export.actions";
import { ExportFilterType } from "./types";

export const formatData = (props: ExportFilterType): object => {
  const {
    nameIPI,
    importId,
    linkClassification,
    dates,
    exportType,
    organizationId,
    conflicts
  } = props;
  let data = {
    fromDate: dates && dates.fromDate,
    toDate: dates && dates.toDate,
    nameIPI,
    importId,
    exportType,
    organizationId,
    conflicts
  };
  const removeEmptyFields = (fields: any) =>
    Object.keys(fields).reduce((acc, fieldKey) => {
      if (!!fields[fieldKey]) {
        return { ...acc, [fieldKey]: fields[fieldKey] };
      }
      return acc;
    }, {});

  if (linkClassification && linkClassification.length) {
    // backend gives 500 if linkClassification is empty
    // @ts-ignore
    data = { ...data, linkClassification };
  }
  return removeEmptyFields(data);
};

export const createExportEffect = ({
  nameIPI,
  importId,
  linkClassification,
  dates,
  exportType,
  organizationId,
  conflicts
}: ExportFilterType): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => {
  dispatch(postExportAction());
  axios
    .post<ExportResponse>(
      apiUrls.postExport,
      formatData({
        dates,
        nameIPI,
        importId,
        linkClassification,
        exportType,
        organizationId,
        conflicts
      })
    )
    .then(response => {
      if (response.status === 200) {
        dispatch(postExportSuccessAction(response.data.response));
        dispatch(setPeriodicExportsRefreshEffect(10000));
        dispatch(fetchExportsEffect());
        dispatch(
          addAlertAction(
            "success",
            "Export is being created. You will receive an email with a download\n" +
              "            link soon."
          )
        );
      } else {
        dispatch(addAlertAction("danger", response.data.error.message));
        dispatch(postExportFailAction(response.data.error.message));
      }
    })
    .catch((e: ErrorResponse) => {
      const message = e.response ? e.response.data.error.message : e.message;
      dispatch(addAlertAction("danger", message));
      dispatch(postExportFailAction(message));
    });
};

export const fetchExportsEffect = (): ThunkAction<
  void,
  AppState,
  void,
  Action<string>
> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  dispatch(fetchExportsAction());
  axios
    .get<ExportsResponse>(apiUrls.exports)
    .then(response => {
      if (response.status === 200) {
        const {
          exports,
          _links: { cancelExport, downloadCsv, downloadJson }
        } = response.data.response;
        dispatch(
          fetchExportsSuccessAction(exports, {
            cancelExport,
            downloadCsv,
            downloadJson
          })
        );

        const exportsInProgress = exports.filter(({ status }) =>
          isExportStatusLoading(status)
        );

        const intervalId = getState().export.intervalId;
        if (!intervalId || !exportsInProgress.length) {
          dispatch(setPeriodicExportsRefreshEffect(60000));
        }
        exportsInProgress.forEach(({ exportId }) =>
          fetchExportDetailsEffect(exportId)
        );
      } else {
        dispatch(addAlertAction("danger", response.data.error.message));
        dispatch(fetchExportsFailAction(response.data.error.message));
      }
    })
    .catch(e => {
      const message = e.response ? e.response.data.error.message : e.message;
      dispatch(addAlertAction("danger", message));
      dispatch(fetchExportsFailAction(message));
    });
};

export const fetchExportDownloadLinkEffect = (
  id: string,
  type: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch,
  getState
) => {
  return axios
    .get<ExportDownloadLinkResponse>(`${apiUrls.exportDetail}/${id}`)
    .then(response => {
      if (response.status === 200) {
        let linkUrl = "";
        if (type === DownloadType.JSON) {
          linkUrl = getState().export.downloadJsonUrl.replace("{exportId}", id);
        }
        if (type === DownloadType.CSV) {
          linkUrl = getState().export.downloadCsvUrl.replace("{exportId}", id);
        }
        return axios
          .get(linkUrl)
          .then(exportDownload => {
            if (exportDownload.status === 200) {
              return exportDownload.data.response._links.download.href;
            } else {
              dispatch(
                fetchExportDownloadFailAction(response.data.error.message)
              );
            }
          })
          .catch(e => {
            dispatch(
              fetchExportDownloadFailAction(
                e.response ? e.response.data.error.message : e.message
              )
            );
          });
      } else {
        dispatch(fetchExportDownloadFailAction(response.data.error.message));
      }
    })
    .catch(e => {
      dispatch(
        fetchExportDownloadFailAction(
          e.response ? e.response.data.error.message : e.message
        )
      );
    });
};

export const fetchExportDetailsEffect = (
  exportId: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => {
  axios
    .get<ExportDetailsResponse>(`${apiUrls.exportDetail}/${exportId}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(
          fetchExportDetailsSuccessAction(
            exportId,
            response.data.response.export
          )
        );
      }
    });
};

export const cancelExportEffect = (
  id: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch,
  getState
): Promise<void> => {
  const cancelExportUrl = getState().export.cancelExportUrl || "";
  const cancelUrl = cancelExportUrl.replace("{exportId}", id);
  return axios.delete(cancelUrl).then(response => {
    if (response.status === 200) {
      dispatch(fetchExportDetailsEffect(id));
    }
  });
};

export const setPeriodicExportsRefreshEffect = (
  milliseconds: number
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  if (milliseconds !== getState().export.interval) {
    clearInterval(getState().export.intervalId);
    const intervalId = milliseconds
      ? window.setInterval(() => {
          dispatch(fetchExportsEffect());
        }, milliseconds)
      : undefined;
    dispatch(setPeriodicExportsRefreshAction(intervalId, milliseconds));
  }
};
