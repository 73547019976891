import React from "react";
import { Button, Spinner } from "reactstrap";
import { LoadingState } from "../../utils/LoadingState";

interface Props {
  previewLoadingState: LoadingState;
  exportLoadingState: LoadingState;
}

export const ExportButtonRow = ({
  previewLoadingState,
  exportLoadingState
}: Props) => (
  <Button color="primary" type="submit">
    Export{" "}
    {previewLoadingState === LoadingState.FETCHING && (
      <Spinner size="sm" color="light" />
    )}
    {exportLoadingState === LoadingState.FETCHING && (
      <Spinner size="sm" color="light" />
    )}
  </Button>
);
