import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { MetricsComponent } from "./Metrics.component";
import { fetchMetricsEffect } from "./Metrics.effects";

const mapStateToProps = ({ metrics }: AppState) => ({
  data: metrics.data || {},
  errorMsg: metrics.errorMsg,
  loadingState: metrics.loadingState
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  fetchMetrics: () => dispatch(fetchMetricsEffect())
});

export const MetricsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MetricsComponent);

export type MetricsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;
