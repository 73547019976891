import moment from "moment";
import { ExportStatus, Organization } from "../types";

interface ReferenceIdType {
  fullReferenceId: string;
  isDSPId: boolean;
  referenceId: string;
  CMO: string | null;
}

export const decomposeReferenceId = (id: string): ReferenceIdType => {
  if (id == null) {
    return {
      fullReferenceId: "",
      isDSPId: false,
      referenceId: "",
      CMO: ""
    };
  }
  const idChunks = id.split("::");
  const CMO =
    (id.startsWith("CMO:") && "CMO:" + (id.split(":") || [])[1]) || null;
  const referenceIdRaw =
    (idChunks.length && idChunks[idChunks.length - 1]) || "";
  const idSegments = referenceIdRaw.split(":");
  const optionalSegment = (idSegments.length > 2 && idSegments[0]) || null;
  const isDSPId = optionalSegment === "TRACK";
  const referenceId = `${isDSPId ? "" : idSegments[idSegments.length - 1]}`;
  const fullReferenceId = `${optionalSegment ? optionalSegment + ":" : ""}${
    idSegments[idSegments.length - 2]
  }:${idSegments[idSegments.length - 1]}`;
  return { fullReferenceId, isDSPId, referenceId, CMO };
};

export const resolveOrganizationName = (
  organizations: Organization[],
  cmo: string | null
): string => {
  if (cmo == null) {
    return "";
  }
  const organization = organizations.find(
    (org: Organization) => org.id === cmo
  );
  return (organization && organization.name) || cmo;
};

export const displayItemsWithPipe = (...items: string[]): string =>
  items.filter((n: string) => n).join(" | ");

export const isExportStatusLoading = (status: ExportStatus): boolean =>
  [
    ExportStatus.REQUESTED,
    ExportStatus.PROCESSING,
    ExportStatus.PREPARING,
    ExportStatus.TRANSFORMING
  ].includes(status);

export const convertMsToDuration = (ms: number) => {
  const duration = moment.duration(ms);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days > 0) {
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
};
