import * as React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

interface Props {
  id: string;
  children: React.ReactNode;
  icon: React.ReactNode;
}

export const IconCard = ({ id, children, icon }: Props) => (
  <Card key={id} className="mb-4">
    <CardBody>
      <Row noGutters className="pb-1 pt-1">
        <Col xs="auto pr-3 pl-1">{icon}</Col>
        <Col className="pr-1">{children}</Col>
      </Row>
    </CardBody>
  </Card>
);
