import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";

interface Props {
  id: string;
  infoText: string | React.ReactNode;
}

export const InfoIconComponent = ({ id, infoText }: Props) => (
  <Fragment>
    <span className="ml-1" id={id.replace(/\W/g, "")}>
      <FontAwesomeIcon color="#ababab" icon={faInfoCircle} size="sm" />
      <UncontrolledPopover
        placement="bottom"
        trigger="hover"
        target={id.replace(/\W/g, "")}
      >
        <PopoverBody>{infoText}</PopoverBody>
      </UncontrolledPopover>
    </span>
  </Fragment>
);
