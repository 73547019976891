import * as React from "react";

interface Props {
  meta?: string;
  children?: React.ReactNode;
}

export const Page404 = ({ meta, children }: Props) => (
  <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
    <h3 className="text-muted">404</h3>
    {meta && (
      <div className="text-muted small" style={{ opacity: 0.5 }}>
        {meta}
      </div>
    )}
    {children && <h4 className="text-muted mt-3">{children}</h4>}
  </div>
);
