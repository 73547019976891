import axios from "axios";
import { replace } from "connected-react-router";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { apiUrls } from "../../api.config";
import { SearchResponse } from "../../api.types";
import { AppState } from "../../App.reducer";
import { Error } from "../../types";
import { decomposeReferenceId } from "../../utils/helpers";
import { Errors } from "../../utils/UnexpectedErrors";
import { addAlertAction } from "../Alerts/Alert.actions";
import {
  fetchSearchAction,
  fetchSearchFailAction,
  fetchSearchSuccessAction
} from "./Search.actions";

const getType = (type: string): string => {
  switch (type) {
    case "works":
      return "workidref";
    case "recordings":
      return "recordingidref";
    case "batch":
      return "batch";
    case "import":
      return "import";
    default:
      return "";
  }
};

const queryParam = (type: string, id: string): string => {
  switch (type) {
    case "work":
      return `?workId=${id}`;
    case "batch":
      return ``;
    default:
      return `?recordingId=${id}`;
  }
};

export const fetchSearchEffect = (
  type: string,
  id: string,
  limit: number,
  offset: number,
  nextPage: boolean
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => {
  dispatch(fetchSearchAction(type, id, nextPage));
  let url = `${apiUrls.workIds}?${`type=${getType(type)}&id=${id}`}`;
  if (limit) {
    url += `&limit=${limit}`;
  }
  if (offset) {
    url += `&offset=${offset}`;
  }
  return axios
    .get<SearchResponse>(url)
    .then(response => {
      if (response.status === 200) {
        const { works, _links } = response.data.response;
        if (works.length === 1) {
          const fullReferenceId = decomposeReferenceId(works[0].id)
            .fullReferenceId;
          dispatch(
            replace(
              `/aggregated-works/${fullReferenceId}${
                id ? queryParam(type, id) : ""
              }`,
              history.state
            )
          );
        }
        dispatch(
          fetchSearchSuccessAction(works, _links.next ? _links.next.href : "")
        );
      } else {
        dispatch(fetchSearchFailAction(response.data.error.message));
        dispatch(addAlertAction("danger", response.data.error.message));
      }
    })
    .catch((e: { response: { data: Error } }) => {
      if (e.response) {
        dispatch(fetchSearchFailAction(e.response.data.error.message));
        dispatch(addAlertAction("danger", e.response.data.error.message));
      } else {
        dispatch(fetchSearchFailAction(Errors.uncatchError));
        dispatch(addAlertAction("danger", Errors.uncatchError));
      }
    });
};
