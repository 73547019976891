import * as React from "react";
import { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import { FixedAlert } from "../../components/FixedAlert/FixedAlert.component";
import { Page404 } from "../../components/Page404/Page404.component";
import { SourceWorks } from "../../components/SourceWorks/SourceWorks.component";
import { SpinnerBox } from "../../components/SpinnerBox/SpinnerBox.component";
import { WorkCard } from "../../components/WorkCard/WorkCard.component";
import { LoadingState } from "../../utils/LoadingState";
import { DetailsProps } from "./Detail";

const DetailComponent = ({
  data,
  aggregatedData,
  errorMsg,
  statusCode,
  fetchOrganizations,
  fetchDetail,
  fetchRecordingGroup,
  organizations = [],
  setRecordingGroupIsOpen,
  loadingState,
  aggregatedLoadingState,
  fetchAggregated,
  match: {
    params: { id }
  },
  location: { search }
}: DetailsProps) => {
  const params = new URLSearchParams(search);
  const workId = params.get("workId") || undefined;

  const sourceWorkId = params.get("sourceWorkId") || undefined;
  const recordingId = params.get("recordingId") || undefined;

  const { Identifier = [] } = { ...data };
  const iswcIdItem = Identifier.filter(
    item => item && item.id.startsWith("ISWC")
  );
  const iswcId = (iswcIdItem[0] || []).id;
  const splitId = data && data.id && data.id.split("::");
  const localId = splitId && splitId.length > 1 && splitId[1];

  useEffect(() => {
    if (!organizations.length) {
      fetchOrganizations();
    }
  }, [organizations]);

  useEffect(() => {
    if (id && (!data || data.id !== id)) {
      fetchDetail(id);
    }
  }, [data, id]);

  useEffect(() => {
    if (sourceWorkId) {
      fetchDetail(sourceWorkId);
    }
  }, [sourceWorkId]);

  useEffect(() => {
    if (!aggregatedData) {
      fetchAggregated(iswcId || localId || "");
    }
  }, [iswcId, localId]);

  const isDataLoading =
    loadingState === LoadingState.FETCHING ||
    aggregatedLoadingState === LoadingState.FETCHING;

  return (
    <Row noGutters className="justify-content-center h-100 w-100">
      <Col>
        <div className="h-100 d-flex flex-column pt-5">
          <Container className="mb-3">
            <Breadcrumb>
              <BreadcrumbItem>Works</BreadcrumbItem>
              <BreadcrumbItem active>{data && data.title}</BreadcrumbItem>
            </Breadcrumb>
          </Container>
          <Row noGutters className="justify-content-center flex-grow-1">
            <Container>
              <Row className="h-100">
                {loadingState === LoadingState.FETCHED && data && (
                  <WorkCard
                    recordingId={recordingId}
                    id={id}
                    workId={workId}
                    data={{ ...data, Link: undefined }}
                    isDetailView={true}
                    onFetchRecordingGroup={(recordingGroup, offset) =>
                      fetchRecordingGroup(recordingGroup, offset)
                    }
                    onSetIsOpen={(recordingGroup, isOpen) =>
                      setRecordingGroupIsOpen(recordingGroup, isOpen)
                    }
                    organizations={organizations}
                  />
                )}
                {isDataLoading && <SpinnerBox className="h-100" />}
                {aggregatedLoadingState === LoadingState.FETCHED && (
                  <SourceWorks
                    works={aggregatedData && aggregatedData.works}
                    organizations={organizations}
                    aggregatedLink={`/aggregated-works/${iswcId || localId}`}
                    sourceWorkId={sourceWorkId}
                  />
                )}
                {loadingState === LoadingState.FAILED && (
                  <Fragment>
                    {statusCode === 404 ? (
                      <Page404 meta={id}>
                        The Requested Work Does Not Exist
                      </Page404>
                    ) : (
                      <FixedAlert color="danger">{errorMsg}</FixedAlert>
                    )}
                  </Fragment>
                )}
              </Row>
            </Container>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export const Detail = withRouter(DetailComponent);
