import * as React from "react";
import { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  ListGroupItem,
  Row,
  Spinner
} from "reactstrap";
import { RecordingComponent } from "../../components/Recording/Recording.component";
import { SpinnerBox } from "../../components/SpinnerBox/SpinnerBox.component";
import { LoadingState } from "../../utils/LoadingState";
import { RecordingsProps } from "./Recordings";

const RecordingsViewComponent = ({
  data,
  fetchDetail,
  fetchRecordingGroup,
  fetchOrganizations,
  organizations,
  loadingState,
  match: {
    params: { id, recordingGroup }
  }
}: RecordingsProps) => {
  const recordings =
    data &&
    data.RecordingGroups.recordings &&
    data.RecordingGroups.recordings[recordingGroup];
  const total =
    (data &&
      data.RecordingGroups.groups &&
      data.RecordingGroups.groups[recordingGroup]) ||
    0;
  const recordingsLoadingState =
    data &&
    data.RecordingGroups.loadingState &&
    data.RecordingGroups.loadingState[recordingGroup];
  const offset = (recordings && recordings.length) || 0;
  const loadMore = total - offset;
  useEffect(() => {
    if (!organizations.length) {
      fetchOrganizations();
    }
  }, [organizations]);
  useEffect(() => {
    if (!data || data.id !== id) {
      fetchDetail(id, recordingGroup, 0, 100);
    } else if (
      !recordingsLoadingState ||
      recordingsLoadingState === LoadingState.PRISTINE
    ) {
      fetchRecordingGroup(recordingGroup, 0, 100);
    } else if (
      recordings &&
      recordings.length <= 100 &&
      recordings.length < total
    ) {
      fetchRecordingGroup(recordingGroup, offset, 100 - recordings.length);
    }
  }, []);

  return (
    <Row noGutters className="justify-content-center h-100 w-100">
      <Col>
        <div className="h-100 d-flex flex-column pt-5">
          <Container className="mb-3">
            <Breadcrumb>
              <BreadcrumbItem>Recordings</BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/works/${id}`}>{data && data.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {recordingGroup.replace(/TRACK:/g, "").replace(/_/g, " ")}
              </BreadcrumbItem>
            </Breadcrumb>
          </Container>
          <Row noGutters className="justify-content-center flex-grow-1">
            <Container>
              <Row>
                <Col sm="12" xs="12" md="12" lg="9" xl="7">
                  {recordingGroup &&
                    data &&
                    data.RecordingGroups.loadingState &&
                    (recordings &&
                      recordings.length >= 0 &&
                      recordings.map((recording, index) => (
                        <RecordingComponent
                          organizations={organizations}
                          recording={recording}
                          key={index}
                          copyId={recording.identifier.id}
                        />
                      )))}

                  {(data && loadMore && data.RecordingGroups.recordings && (
                    <ListGroupItem className="w-100 card-header d-flex justify-content-end">
                      {data.RecordingGroups.loadingState &&
                      data.RecordingGroups.loadingState[recordingGroup] ===
                        LoadingState.FETCHING &&
                      offset > 0 ? (
                        <Spinner size="sm" color="secondary" />
                      ) : (
                        <a
                          href="#"
                          className="small"
                          onClick={e => {
                            e.preventDefault();
                            fetchRecordingGroup(recordingGroup, offset, 100);
                          }}
                        >
                          + {loadMore} more...
                        </a>
                      )}
                    </ListGroupItem>
                  )) ||
                    null}
                </Col>
              </Row>
              {(loadingState === LoadingState.FETCHING ||
                recordingsLoadingState === LoadingState.FETCHING) && (
                <SpinnerBox className="h-100" />
              )}
            </Container>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export const Recordings = withRouter(RecordingsViewComponent);
