import moment from "moment";
import { useState } from "react";
import * as React from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Col,
  ListGroup,
  ListGroupItem,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap";
import { Organization, Work } from "../../types";
import {
  decomposeReferenceId,
  resolveOrganizationName
} from "../../utils/helpers";
import { CollapsableList } from "../CollapsableList/CollapsableList.component";
import { InterestedPartyComponent } from "../InterestedParty/InterestedParty.component";
import { RecordingComponent } from "../Recording/Recording.component";
import { RecordingGroup } from "../RecordingGroup/RecordingGroup.component";
import { WorkComponent } from "../Work/Work.component";

interface WorkCardProps {
  data: Work;
  isDetailView: boolean;
  recordingId?: string;
  workId?: string;
  id: string;
  recordingsUrlSegment?: string;
  onFetchRecordingGroup?: (recordingId: string, offset: number) => void;
  onSetIsOpen?: (recordingGroup: string, isOpen: boolean) => void;
  organizations?: Organization[];
}

export const WorkCard = ({
  data,
  isDetailView,
  recordingId,
  workId,
  id,
  onFetchRecordingGroup,
  recordingsUrlSegment = "recordings",
  onSetIsOpen,
  organizations = []
}: WorkCardProps) => {
  const {
    altTitles = [],
    Created = [],
    featuredArtists = [],
    Link: Recordings,
    RecordingGroups,
    Identifier = [],
    title,
    timestamp
  } = data;
  const [isOpen, setIsOpen] = useState({
    altTitles: false,
    Created: false,
    Identifier: workId != null,
    Link: recordingId != null,
    RecordingGroups: recordingId != null,
    sourceWorks: false
  });
  const iswcIdItem = Identifier.filter(
    item => item && item.id.startsWith("ISWC")
  );
  const CMO = decomposeReferenceId(id).CMO;
  const organization = organizations.find(org => org.id === CMO);
  const organizationName = organization ? organization.name : "";
  const iswcId = (iswcIdItem[0] || []).id;
  return (
    <Col sm="12" xs="12" md="7" lg="7" xl="7">
      <ListGroup>
        <ListGroupItem>
          <div className="d-flex w-100 mb-4 justify-content-between">
            {iswcId ? (
              <span className="small font-weight-bold">
                ISWC:
                <span className="text-muted">
                  {iswcId && iswcId.replace("ISWC:", "")}
                </span>
              </span>
            ) : (
              <span />
            )}
            {data.dataAuthority ? (
              <span>
                <Badge
                  color="secondary"
                  style={{ opacity: 0.6 }}
                  className="ml-1"
                >
                  {organizationName}
                </Badge>
                <Badge
                  color="secondary"
                  style={{ opacity: 0.6 }}
                  className="ml-1"
                >
                  Authority {data.dataAuthority}
                </Badge>
              </span>
            ) : (
              ""
            )}
          </div>
          <h5 className="text-muted mb-0">{featuredArtists}</h5>
          <h3 className="mt-0">{title}</h3>
        </ListGroupItem>
        <ListGroupItem className="p-0">
          <CollapsableList
            title={"Further Titles"}
            isOpen={isOpen.altTitles}
            setIsOpen={newValue =>
              setIsOpen({ ...isOpen, altTitles: newValue })
            }
          >
            {altTitles.map((altTitle, index) => (
              <ListGroupItem key={"title" + index}>
                <div className="d-flex justify-content-between">
                  {altTitle.title}
                  {altTitle.in && (
                    <React.Fragment>
                      <span style={{ opacity: 0.6 }}>
                        <Badge
                          color="secondary"
                          id={"party" + "altTitle" + index}
                        >
                          {altTitle.in.length} Work
                          {altTitle.in.length > 1 && "s"}
                        </Badge>
                      </span>
                      <UncontrolledPopover
                        placement="right"
                        trigger="hover"
                        target={"party" + "altTitle" + index}
                        className="popOver"
                      >
                        <PopoverBody>
                          {altTitle.in
                            .map(
                              partyId =>
                                data.works &&
                                data.works.find(
                                  // tslint:disable-next-line: no-shadowed-variable
                                  ({ id }) => id === partyId.work
                                )
                            )
                            .map(
                              (ref?) =>
                                ref && (
                                  <span key={ref.id} className="d-block">
                                    <Link
                                      to={`/works/${ref.id}`}
                                      className="mr-2"
                                    >
                                      {ref.title
                                        ? `${ref.title}`
                                        : decomposeReferenceId(ref.id)
                                            .referenceId}
                                    </Link>
                                    <Badge
                                      className="ml-1"
                                      style={{ opacity: 0.6 }}
                                    >
                                      {resolveOrganizationName(
                                        organizations,
                                        decomposeReferenceId(ref.id).CMO || ""
                                      )}
                                    </Badge>
                                    <Badge
                                      className="ml-1"
                                      style={{ opacity: 0.6 }}
                                    >
                                      {ref.dataAuthority
                                        ? `Authority ${ref.dataAuthority}`
                                        : ``}
                                    </Badge>
                                  </span>
                                )
                            )}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </React.Fragment>
                  )}
                </div>
              </ListGroupItem>
            ))}
          </CollapsableList>
          <CollapsableList
            title={"Work IDs"}
            isOpen={isOpen.Identifier}
            setIsOpen={newValue =>
              setIsOpen({ ...isOpen, Identifier: newValue })
            }
          >
            {Identifier.map((value, index) => (
              <WorkComponent
                index={index}
                workIdQuery={workId}
                organizations={organizations}
                work={value}
                relationship={value.RelationshipProperties}
                works={value.works}
                workRefs={data.works}
                key={"work" + index}
                copyId={value.sourceId}
              />
            ))}
          </CollapsableList>
          <CollapsableList
            title={"Interested Parties"}
            isOpen={isOpen.Created}
            setIsOpen={newValue => setIsOpen({ ...isOpen, Created: newValue })}
          >
            {Created.map((value, index) => (
              <InterestedPartyComponent
                party={value}
                works={data.works}
                organizations={organizations}
                key={index}
                index={index}
                copyId={value.ipiNameNumber}
              />
            ))}
          </CollapsableList>
          {Recordings ? (
            <CollapsableList
              title={"Recordings"}
              isOpen={isOpen.Link}
              setIsOpen={newValue => setIsOpen({ ...isOpen, Link: newValue })}
            >
              {Recordings.map((value, index) => (
                <RecordingComponent
                  organizations={organizations}
                  recording={value}
                  recordingId={recordingId}
                  key={index}
                  works={data.works}
                  copyId={value.identifier.id}
                />
              ))}
            </CollapsableList>
          ) : null}
          {RecordingGroups ? (
            <CollapsableList
              title={"Recordings"}
              zIndex={3}
              isOpen={isOpen.RecordingGroups}
              setIsOpen={newValue =>
                setIsOpen({ ...isOpen, RecordingGroups: newValue })
              }
              customBadge={
                isDetailView ? (
                  <Badge pill>{RecordingGroups.totalCount}</Badge>
                ) : (
                  <Badge pill>{`~ ${RecordingGroups.totalCount}`}</Badge>
                )
              }
            >
              {Object.keys(RecordingGroups.groups).map(
                (recordingGroup, index) => (
                  <RecordingGroup
                    workId={id}
                    organizations={organizations}
                    works={data.works}
                    recordingGroup={{
                      name: recordingGroup,
                      totalCount: RecordingGroups.groups[recordingGroup],
                      value: RecordingGroups.pagination
                        ? RecordingGroups.pagination[recordingGroup]
                        : RecordingGroups.groups[recordingGroup],
                      isOpen:
                        (RecordingGroups.isOpen &&
                          RecordingGroups.isOpen[recordingGroup]) ||
                        false,
                      recordings:
                        RecordingGroups.recordings &&
                        RecordingGroups.recordings[recordingGroup],
                      loadingState:
                        RecordingGroups.loadingState &&
                        RecordingGroups.loadingState[recordingGroup]
                    }}
                    activeRecordingId={recordingId}
                    recordingsUrlSegment={recordingsUrlSegment}
                    onLoadGroup={offset => {
                      if (onFetchRecordingGroup) {
                        onFetchRecordingGroup(recordingGroup, offset);
                      }
                    }}
                    onSetIsOpen={(
                      recordingGroupName: string,
                      groupIsOpen: boolean
                    ) => {
                      if (onSetIsOpen) {
                        onSetIsOpen(recordingGroupName, groupIsOpen);
                      }
                    }}
                    key={index}
                    isDetailView={isDetailView}
                  />
                )
              )}
            </CollapsableList>
          ) : null}
        </ListGroupItem>
        <ListGroupItem>
          <div className="justify-content-between w-100 d-flex">
            {timestamp ? (
              <small color="light" className="text-muted">
                Last Updated: {moment.unix(timestamp).format("MM/DD/YYYY")}
              </small>
            ) : null}
          </div>
        </ListGroupItem>
      </ListGroup>
    </Col>
  );
};
