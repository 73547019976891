import { User } from "../../Auth.actions";

export interface Tokens {
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  authToken?: string;
}

export interface Challenge {
  name: string;
  username: string;
  headers: {};
  href: string;
  method: string;
}

export interface ChallengeResponse {
  username: string;
  name: string;
  _links: {
    challenge: Challenge;
  };
}

export enum LoginStates {
  signin = "signin",
  reset = "reset-password",
  confirm = "confirm-reset",
  challengePassword = "password-challenge"
}

type SigninState = LoginStates.signin;
type ResetPasswordType = LoginStates.reset;
type ConfirmResetType = LoginStates.confirm;
type ChallengePasswordType = LoginStates.challengePassword;

export type LoginStateTypes =
  | SigninState
  | ResetPasswordType
  | ConfirmResetType
  | ChallengePasswordType;

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT = "USER_LOGOUT";
export const LOGIN_STATE = "LOGIN_STATE";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const PASSWORD_RESET_CONFIRM_FAILURE = "PASSWORD_RESET_CONFIRM_FAILURE";
export const SET_EMAIL = "SET_EMAIL";
export const SET_REDIRECT = "SET_REDIRECT";
export const HANDLE_CHALLENGE = "HANDLE_CHALLENGE";
export const SET_NEW_PASSWORD_FAILURE = "SET_NEW_PASSWORD_FAILURE";

export interface SigninSuccessAction {
  type: typeof USER_LOGIN_SUCCESS;
  user: User;
  tokens: Tokens;
}

export interface RefreshSuccessAction {
  type: typeof REFRESH_SUCCESS;
  tokens: Tokens;
}

export interface SigninFailureAction {
  type: typeof USER_LOGIN_FAILURE;
  error: string;
}

export interface SigninStateManageAction {
  type: typeof LOGIN_STATE;
  signinState: LoginStates;
}

export interface ResetFailureAction {
  type: typeof PASSWORD_RESET_FAILURE;
  error: string;
}

export interface ResetConfirmFailureAction {
  type: typeof PASSWORD_RESET_CONFIRM_FAILURE;
  error: string;
}

export interface SetEmailAction {
  type: typeof SET_EMAIL;
  email: string;
}

export interface SetRedirectAction {
  type: typeof SET_REDIRECT;
  path: string;
}

export interface HandleChallengeAction {
  type: typeof HANDLE_CHALLENGE;
  challenge: ChallengeResponse;
}

export interface SetNewPasswordFailureAction {
  type: typeof SET_NEW_PASSWORD_FAILURE;
  error: string;
}

export interface UserLogoutAction {
  type: typeof USER_LOGOUT;
}

export type SigninActionTypes =
  | SigninSuccessAction
  | RefreshSuccessAction
  | SigninFailureAction
  | SigninStateManageAction
  | ResetFailureAction
  | ResetConfirmFailureAction
  | SetEmailAction
  | SetRedirectAction
  | HandleChallengeAction
  | SetNewPasswordFailureAction
  | UserLogoutAction;
