import * as React from "react";
import { FormFeedback, Input } from "reactstrap";

const dayRange = (size: number, startAt: number = 1): number[] =>
  // @ts-ignore
  [...Array(size).keys()].map((i: number) => i + startAt);

const monthRange = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const yearRange = (
  size: number = 4,
  startAt: number = new Date().getFullYear()
): number[] =>
  // @ts-ignore
  [...Array(size).keys()].map(i => startAt - i);

interface Props {
  activeValues: {
    day?: string;
    month?: string;
    year?: string;
  };
  disabled?: boolean;
  onChange?: (e: React.FormEvent) => void;
  name?: string;
  errors?: {
    day?: string;
    month?: string;
    year?: string;
  };
  touched?: boolean;
  submitCount: number;
}

export const SimpleDatePicker = ({
  activeValues,
  disabled,
  onChange,
  name,
  errors,
  submitCount
}: Props) => {
  return (
    <div className="form-inline">
      <Input
        invalid={!!(submitCount && errors && errors.day)}
        type="select"
        name={`${name}.day`}
        id="day"
        onChange={onChange}
        value={activeValues.day}
        disabled={disabled}
        className="mr-2 mb-1"
      >
        <option value="">Day</option>
        {dayRange(31).map(day => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </Input>

      <Input
        invalid={!!(submitCount && errors && errors.month)}
        type="select"
        name={`${name}.month`}
        id="month"
        onChange={onChange}
        value={activeValues.month}
        disabled={disabled}
        className="mr-2 mb-1"
      >
        <option value="">Month</option>
        {monthRange.map(month => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </Input>

      <Input
        invalid={!!(submitCount && errors && errors.year)}
        type="select"
        name={`${name}.year`}
        id="year"
        onChange={onChange}
        value={activeValues.year}
        disabled={disabled}
        className="mr-2 mb-1"
      >
        <option value="">Year</option>
        {yearRange().map((year: number) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Input>
      <FormFeedback data-test-id={"validation-message"}>
        {errors && (errors.day || errors.month || errors.year)}
      </FormFeedback>
    </div>
  );
};
