import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { AnyAction, applyMiddleware, compose, createStore, Store } from "redux";
import ReduxThunk from "redux-thunk";
import { createRootReducer } from "./App.reducer";

export const history = createBrowserHistory();

export const configureStore = (
  preloadedState: undefined
): Store<any, AnyAction> => {
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ReduxThunk
        // ... other middlewares ...
      )
    )
  );

  return store;
};
