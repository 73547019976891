import * as React from "react";
import { ReactNode } from "react";
import { Col } from "reactstrap";

interface Props {
  className?: string;
  children: ReactNode;
}

export const ColFillHeight = ({ className, children }: Props) => (
  <Col className={className || ""}>
    <div className="h-100 d-flex flex-column">{children}</div>
  </Col>
);
