import { push } from "react-router-redux";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import {
  LoginData,
  passwordReset,
  resetConfirm,
  ResetConfirm,
  respondToChallenge,
  signin
} from "../../auth";
import { Error } from "../../types";
import { addAlertAction } from "../Alerts/Alert.actions";
import { LoginStates, NEW_PASSWORD_REQUIRED } from "./Signin.action.types";
import {
  handleChallengeAction,
  resetConfirmFailureAction,
  resetFailureAction,
  setEmailAction,
  setNewPasswordFailureAction,
  signinFailureAction,
  signinStateManageAction,
  signinSuccessAction
} from "./Signin.actions";

export const signinEffect = (
  credentials: LoginData
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  signin(credentials)
    .then(response => {
      if (response.status === 200) {
        const { tokens, user, challenge } = response.data.response;

        if (challenge && challenge.name === NEW_PASSWORD_REQUIRED) {
          dispatch(signinStateManageAction(LoginStates.challengePassword));
          dispatch(handleChallengeAction(challenge));
          return;
        }

        const redirect = getState().signin.redirect;
        dispatch(signinSuccessAction(user, tokens));
        dispatch(push(redirect));
      } else {
        dispatch(signinFailureAction(response.data.error.message));
      }
    })
    .catch((e: { response: { data: { error: { message: string } } } }) => {
      dispatch(signinFailureAction(e.response.data.error.message));
    });
};

export const resetPasswordEffect = (
  email: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => {
  passwordReset(email)
    .then(response => {
      if (response.status === 200) {
        dispatch(signinStateManageAction(LoginStates.confirm));
        dispatch(setEmailAction(email));
      } else {
        dispatch(addAlertAction("danger", response.data.error.message));
        dispatch(resetFailureAction(response.data.error.message));
      }
    })
    .catch((e: { response: { data: Error } }) => {
      dispatch(addAlertAction("danger", e.response.data.error.message));
      dispatch(resetFailureAction(e.response.data.error.message));
    });
};

export const confirmResetEffect = (
  confirmData: ResetConfirm
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  const email = getState().signin.email;
  resetConfirm(confirmData, email)
    .then(response => {
      if (response.status === 200) {
        dispatch(signinStateManageAction(LoginStates.signin));
      } else {
        dispatch(resetConfirmFailureAction(response.data.error.message));
      }
    })
    .catch((e: { response: { data: Error } }) =>
      resetConfirmFailureAction(e.response.data.error.message)
    );
};

export const setNewPasswordEffect = (
  password: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  const { challenge } = getState().signin;
  respondToChallenge(challenge, password)
    .then(response => {
      if (response.status === 200) {
        dispatch(signinStateManageAction(LoginStates.signin));
      } else {
        dispatch(addAlertAction("danger", response.data.error.message));
        dispatch(setNewPasswordFailureAction(response.data.error.message));
      }
    })
    .catch((e: { message: string }) => {
      dispatch(addAlertAction("danger", e.message));
      dispatch(setNewPasswordFailureAction(e.message));
    });
};
