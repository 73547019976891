import { ExportLinksResponse } from "../../api.types";
import { ExportData } from "../../types";
import { Export } from "../../types";

export const FETCH_EXPORTS_SUCCESS = "FETCH_EXPORTS_SUCCESS";
export const FETCH_EXPORTS_FAIL = "FETCH_EXPORTS_FAIL";
export const FETCH_EXPORTS = "FETCH_EXPORTS";
export const FETCH_EXPORT_DOWNLOAD_LINK_FAIL =
  "FETCH_EXPORT_DOWNLOAD_LINK_FAIL";
export const FETCH_EXPORT_DETAILS_SUCCESS = "FETCH_EXPORT_DETAILS_SUCCESS";
export const SET_PERIODIC_EXPORTS_REFRESH = "SET_PERIODIC_EXPORTS_REFRESH";
export const POST_EXPORT = "POST_EXPORT";
export const POST_EXPORT_SUCCESS = "POST_EXPORT_SUCCESS";
export const POST_EXPORT_FAIL = "POST_EXPORT_FAIL";
export const SET_ACTIVE_FILTER_TAB = "SET_ACTIVE_FILTER_TAB";

interface FetchExportAction {
  type: typeof FETCH_EXPORTS;
}

interface FetchExportSuccessAction {
  type: typeof FETCH_EXPORTS_SUCCESS;
  exportData: Export[];
  links: ExportLinksResponse;
}

interface FetchExportFailAction {
  type: typeof FETCH_EXPORTS_FAIL;
  error: string;
}

interface FetchExportDownloadFailAction {
  type: typeof FETCH_EXPORT_DOWNLOAD_LINK_FAIL;
  error: string;
}

interface FetchExportDetailsSuccessAction {
  type: typeof FETCH_EXPORT_DETAILS_SUCCESS;
  exportId: string;
  exportData: Export;
}

interface SetPeriodicExportsRefreshAction {
  type: typeof SET_PERIODIC_EXPORTS_REFRESH;
  intervalId: number | undefined;
  interval?: number;
}

interface PostExportAction {
  type: typeof POST_EXPORT;
}

interface PostExportSuccessAction {
  type: typeof POST_EXPORT_SUCCESS;
  data: ExportData;
}

interface PostExportFailAction {
  type: typeof POST_EXPORT_FAIL;
  error: string;
}

interface SetActiveFilterTabAction {
  type: typeof SET_ACTIVE_FILTER_TAB;
  tabId: string;
}

export type ExportActionTypes =
  | PostExportSuccessAction
  | PostExportFailAction
  | PostExportAction
  | FetchExportAction
  | FetchExportSuccessAction
  | FetchExportFailAction
  | FetchExportDownloadFailAction
  | SetPeriodicExportsRefreshAction
  | FetchExportDetailsSuccessAction
  | SetActiveFilterTabAction;
