import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { fetchOrganizationsEffect } from "../Import/Import.effects";
import { Search } from "./Search.component";
import { fetchSearchEffect } from "./Search.effects";

const mapStateToProps = ({ search, imports: { organizations } }: AppState) => ({
  searchResult: search.searchResult,
  errorMsg: search.errorMsg,
  searchHistory: search.searchHistory,
  loadingState: search.loadingState,
  nextLink: search.nextLink,
  organizations
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  fetchSearch: (
    type: string,
    id: string,
    limit: number,
    offset: number,
    nextPage: boolean
  ) => dispatch(fetchSearchEffect(type, id, limit, offset, nextPage)),
  fetchOrganizations: () => dispatch(fetchOrganizationsEffect())
});

export const SearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);

export type SearchProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{
    type: "works" | "recordings";
    id: string;
  }>;
