import { Import, Organization } from "../../types";

export const FETCH_IMPORTS = "FETCH_IMPORTS";
export const FETCH_IMPORTS_SUCCESS = "FETCH_IMPORTS_SUCCESS";
export const FETCH_IMPORTS_FAIL = "FETCH_IMPORTS_FAIL";
export const FETCH_IMPORT_DETAILS = "FETCH_IMPORT_DETAILS";
export const FETCH_IMPORT_DETAILS_SUCCESS = "FETCH_IMPORT_DETAILS_SUCCESS";
export const FETCH_IMPORT_DETAILS_FAIL = "FETCH_IMPORT_DETAILS_FAIL";
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAIL = "FETCH_ORGANIZATIONS_FAIL";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";
export const SET_PERIODIC_REFRESH = "SET_PERIODIC_REFRESH";
export const DOWNLOAD_IMPORT_FAIL = "DOWNLOAD_IMPORT_FAIL";

interface FetchImportsAction {
  type: typeof FETCH_IMPORTS;
  nextPage?: boolean;
}

interface FetchImportsSuccessAction {
  type: typeof FETCH_IMPORTS_SUCCESS;
  imports: Import[];
  append: boolean;
  nextToken?: string;
  cancelImportUrl: string;
  fetchLogFileUrl: string;
  sourceFileUrl: string;
}

interface FetchImportsFailAction {
  type: typeof FETCH_IMPORTS_FAIL;
  error: string;
}

interface FetchDetailsAction {
  type: typeof FETCH_IMPORT_DETAILS;
  importId: string;
}

interface FetchDetailsSuccessAction {
  type: typeof FETCH_IMPORT_DETAILS_SUCCESS;
  importDetail: Import;
  importId: string;
}

interface FetchDetailsFailAction {
  type: typeof FETCH_IMPORT_DETAILS_FAIL;
  error: string;
  importId: string;
}

interface FetchOrganizationsAction {
  type: typeof FETCH_ORGANIZATIONS;
}

interface FetchOrganizationsSuccessAction {
  type: typeof FETCH_ORGANIZATIONS_SUCCESS;
  organizations: Organization[];
}

interface FetchOrganizationsFailAction {
  type: typeof FETCH_ORGANIZATIONS_FAIL;
  error: string;
}

interface UploadFileAction {
  type: typeof UPLOAD_FILE;
  placeholder: Import;
}

interface UploadFileSuccessAction {
  type: typeof UPLOAD_FILE_SUCCESS;
  importId: string;
}

interface UploadFileFailAction {
  type: typeof UPLOAD_FILE_FAIL;
  error: string;
}

interface UploadFileProgressAction {
  type: typeof UPLOAD_FILE_PROGRESS;
  importId: string;
  progress: number;
}

interface UploadFileSuccessAction {
  type: typeof UPLOAD_FILE_SUCCESS;
  importId: string;
}

interface SetPeriodicRefreshAction {
  type: typeof SET_PERIODIC_REFRESH;
  intervalId: number | undefined;
  interval: number;
}

interface DownloadImportFailAction {
  type: typeof DOWNLOAD_IMPORT_FAIL;
  error: string;
}

export type ImportsActionTypes =
  | FetchImportsAction
  | FetchImportsSuccessAction
  | FetchImportsFailAction
  | FetchDetailsAction
  | FetchDetailsSuccessAction
  | FetchDetailsFailAction
  | FetchOrganizationsAction
  | FetchOrganizationsSuccessAction
  | FetchOrganizationsFailAction
  | UploadFileAction
  | UploadFileSuccessAction
  | UploadFileFailAction
  | UploadFileProgressAction
  | SetPeriodicRefreshAction
  | DownloadImportFailAction;
