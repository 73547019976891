import * as React from "react";
import { Progress } from "reactstrap";
import { Batch, BatchStep, ImportProgress, ImportState } from "../../types";

interface Props {
  batches?: Batch[];
  progress?: ImportProgress;
  state: string;
}

export const ImportProgressComponent = ({
  batches,
  progress,
  state
}: Props) => {
  const batch =
    (progress &&
      progress.currentBatch &&
      batches &&
      batches.find(
        ({ batchNumber }) => batchNumber === progress.currentBatch
      )) ||
    null;
  const step = (batch && batch.step) || null;
  let stepNumber;
  let stepLabel;
  switch (step) {
    case BatchStep.PENDING:
      stepNumber = 0;
      stepLabel = "Pending";
      break;
    case BatchStep.TRANSFORMING:
      stepNumber = 1;
      stepLabel = "Transforming";
      break;
    case BatchStep.LOADING_VERTICES:
      stepNumber = 2;
      stepLabel = "Loading Vertices";
      break;
    case BatchStep.LOADING_EDGES:
      stepNumber = 3;
      stepLabel = "Loading Edges";
      break;
    case BatchStep.CLEAN_UP:
      stepNumber = 4;
      stepLabel = "Clean Up";
      break;
    case BatchStep.COMPUTING_EDGES:
      stepNumber = 5;
      stepLabel = "Computing Edges";
      break;
    case BatchStep.COUNTING_STATISTICS:
      stepNumber = 5;
      stepLabel = "Counting Statistics";
      break;
    case BatchStep.EXPORTING:
      stepNumber = 6;
      stepLabel = "Exporting";
      break;
    default:
      stepNumber = 0;
      stepLabel = "Pending";
  }
  const totalProgress =
    (progress &&
      100 *
        (progress.processedBatches + stepNumber / 6) *
        (1 / progress.totalBatches)) ||
    1;
  return (
    <div>
      <Progress value={totalProgress} />

      <span className="small text-muted">
        {state === ImportState.COLLECTING_STATISTICS
          ? "Updating Metrics..."
          : progress && progress.totalBatches
          ? `Processing Batch ${progress.currentBatch} of ${
              progress.totalBatches
            }: ${stepLabel}...`
          : `Waiting...`}
      </span>
    </div>
  );
};
