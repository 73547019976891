import { Recording, Work } from "../../types";
import {
  DetailActionTypes,
  FETCH_DETAIL,
  FETCH_DETAIL_FAIL,
  FETCH_DETAIL_SUCCESS,
  FETCH_RECORDING_GROUP,
  FETCH_RECORDING_GROUP_FAIL,
  FETCH_RECORDING_GROUP_SUCCESS,
  SET_RECORDING_GROUP_IS_OPEN
} from "./Detail.action.types";

export function fetchDetailAction(id: string): DetailActionTypes {
  return {
    type: FETCH_DETAIL,
    id
  };
}

export function fetchDetailSuccessAction(work: Work): DetailActionTypes {
  return {
    type: FETCH_DETAIL_SUCCESS,
    work
  };
}

export function fetchDetailFailAction(
  error: string,
  statusCode?: number
): DetailActionTypes {
  return {
    type: FETCH_DETAIL_FAIL,
    error,
    statusCode
  };
}

export function fetchRecordingGroupAction(
  recordingGroup: string
): DetailActionTypes {
  return {
    type: FETCH_RECORDING_GROUP,
    recordingGroup
  };
}

export function fetchRecordingGroupSuccessAction(
  recordingGroup: string,
  recordings: Recording[]
): DetailActionTypes {
  return {
    type: FETCH_RECORDING_GROUP_SUCCESS,
    recordingGroup,
    recordings
  };
}

export function fetchRecordingGroupFailAction(
  recordingGroup: string,
  error: string
): DetailActionTypes {
  return {
    type: FETCH_RECORDING_GROUP_FAIL,
    recordingGroup,
    error
  };
}

export function setRecordingGroupIsOpenAction(
  recordingGroup: string,
  isOpen: boolean
): DetailActionTypes {
  return {
    type: SET_RECORDING_GROUP_IS_OPEN,
    recordingGroup,
    isOpen
  };
}
