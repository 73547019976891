import axios from "axios";
import moment from "moment";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { apiUrls } from "../../api.config";
import {
  DownloadFileResponse,
  ImportDetailsResponse,
  ImportsResponse,
  OrganizationsResponse,
  UploadFileResponse
} from "../../api.types";
import { AppState } from "../../App.reducer";
import { Import, ImportState } from "../../types";
import { fileExtention } from "../../utils/FileExtention";
import { Errors } from "../../utils/UnexpectedErrors";
import { addAlertAction } from "../Alerts/Alert.actions";
import { ErrorResponse } from "./../../api.types";
import {
  downloadImportFailAction,
  fetchImportDetailsAction,
  fetchImportDetailsFailAction,
  fetchImportDetailsSuccessAction,
  fetchImportsAction,
  fetchImportsFailAction,
  fetchImportsSuccessAction,
  fetchOrganizationsAction,
  fetchOrganizationsFailAction,
  fetchOrganizationsSuccessAction,
  setPeriodicRefreshAction,
  uploadFileAction,
  uploadFileFailAction,
  uploadFileProgressAction
} from "./Import.actions";

export const fetchImportsEffect = (
  nextPage?: boolean
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  const importState = getState().imports;
  // @ts-ignore
  const filters = new URLSearchParams(getState().router.location.search);
  dispatch(fetchImportsAction(nextPage));
  const params = {};
  if (nextPage) {
    Object.assign(params, {
      nextToken: importState.nextToken
    });
  }
  if (filters.has("status") && filters.get("status") != null) {
    Object.assign(params, { status: filters.get("status") });
  }
  if (filters.has("namespace") && filters.get("namespace") != null) {
    Object.assign(params, { organizationId: filters.get("namespace") });
  }
  axios
    .get<ImportsResponse>(`${apiUrls.status}`, {
      params
    })
    .then(response => {
      const {
        _links: { next, cancelImport, logFile, sourceFile },
        imports
      } = response.data.response;
      const url = next && next.href;
      const nextToken =
        (url && new URL(url).searchParams.get("nextToken")) || undefined;

      dispatch(
        fetchImportsSuccessAction(
          imports,
          nextPage || false,
          cancelImport.href,
          logFile.href,
          sourceFile.href,
          nextToken
        )
      );
      const importsInProgress = imports.filter(({ state }: Import) =>
        [
          ImportState._UPLOADING,
          ImportState.INITIATED,
          ImportState.QUEUED,
          ImportState.WAITING,
          ImportState.PROCESSING,
          ImportState.COLLECTING_STATISTICS
        ].includes(state)
      );
      const intervalId = getState().imports.intervalId;
      if (!intervalId || !importsInProgress.length) {
        dispatch(setPeriodicRefreshEffect(60000));
      }
      importsInProgress.forEach(({ importId }) => {
        dispatch(fetchImportDetailsEffect(importId));
      });
    })
    .catch((e: any) => {
      if (e.response) {
        const message =
          (e.response.data.error && e.response.data.error.message) ||
          ((e.response.data && e.response.data.message) || e.response.data);
        dispatch(addAlertAction("danger", message));
        dispatch(fetchImportsFailAction(message));
      }
    });
};

export const fetchImportDetailsEffect = (
  importId: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => {
  dispatch(fetchImportDetailsAction(importId));
  return axios
    .get<ImportDetailsResponse>(`${apiUrls.status}/import/${importId}`)
    .then(response => {
      const { import: importDetail } = response.data.response;
      if (response.status === 200) {
        dispatch(fetchImportDetailsSuccessAction(importId, importDetail));
      }
    })
    .catch((e: any) => {
      if (e.response) {
        dispatch(
          fetchImportDetailsFailAction(
            (e.response.data.error && e.response.data.error.message) ||
              e.response.data,
            importId
          )
        );
      } else {
        dispatch(fetchImportDetailsFailAction(Errors.uncatchError, importId));
      }
    });
};

export const fetchOrganizationsEffect = (): ThunkAction<
  void,
  AppState,
  void,
  Action<string>
> => (dispatch: ThunkDispatch<AppState, void, Action<string>>) => {
  dispatch(fetchOrganizationsAction());
  axios
    .get<OrganizationsResponse>(apiUrls.organizations)
    .then(response => {
      dispatch(
        fetchOrganizationsSuccessAction(response.data.response.organizations)
      );
    })
    .catch((e: ErrorResponse) => {
      if (e.response) {
        dispatch(
          fetchOrganizationsFailAction(
            (e.response.data.error && e.response.data.error.message) ||
              JSON.stringify(e.response.data)
          )
        );
      } else {
        dispatch(fetchOrganizationsFailAction(Errors.uncatchError));
      }
    });
};

export const uploadFilesEffect = (
  files: File[]
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  return Promise.all(
    files.map(file => {
      const handleError = (e: ErrorResponse) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.details
        ) {
          const message = e.response.data.error.details[0].message;
          dispatch(addAlertAction("danger", message));
          dispatch(uploadFileFailAction(message));
        }
      };
      return axios
        .post<UploadFileResponse>(`${apiUrls.initUpload}/`, {
          type: fileExtention(file.name),
          filename: file.name
        })
        .then(response => {
          const { url, importId } = response.data.response;
          dispatch(
            uploadFileAction({
              sourceFile: {
                name: file.name
              },
              attachment: "",
              state: ImportState._UPLOADING,
              created: moment().unix(),
              submitter: {
                username: getState().signin.user.username,
                organizationId: ""
              },
              importId,
              cancellable: true
            })
          );

          return axios
            .put<{ loaded: number; total: number }>(url, file, {
              onUploadProgress: e => {
                const progress = (e.loaded / e.total) * 100;
                dispatch(uploadFileProgressAction(progress, importId));
              }
            })
            .then(progressResponse => {
              if (progressResponse.status === 200) {
                return dispatch(setPeriodicRefreshEffect(10000));
              }
            })
            .catch((e: ErrorResponse) => handleError(e));
        })
        .catch((e: ErrorResponse) => handleError(e));
    })
  );
};

export const cancelImportEffect = (
  importId: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  const cancelImportUrl = getState().imports.cancelImportUrl;
  const cancelUrl = cancelImportUrl.replace("{import.id}", importId);
  axios.delete(cancelUrl).then(response => {
    if (response.status === 200) {
      dispatch(fetchImportDetailsEffect(importId));
    }
  });
};

export const downloadLogFileEffect = (
  importId: string,
  file: string
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  const url = getState()
    .imports.fetchLogFileUrl.replace("{import.id}", importId)
    .replace("{logFile.file}", file);

  return axios.get<DownloadFileResponse>(url).then(response => {
    if (response.status === 200) {
      return response.data.response._links.download.href;
    }
  });
};

export const downloadImportEffect = (
  importId: string,
  downloadToken: string
): ThunkAction<Promise<string | void>, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  const url = getState()
    .imports.sourceFileUrl.replace("{import.id}", importId)
    .replace("{import.sourceFile.downloadToken}", downloadToken);

  return axios
    .get<DownloadFileResponse>(url)
    .then(response => {
      return response.data.response._links.download.href;
    })
    .catch(e => {
      dispatch(addAlertAction("danger", e.message));
      dispatch(downloadImportFailAction(e.message));
    });
};

export const setPeriodicRefreshEffect = (
  milliseconds: number
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  if (milliseconds !== getState().imports.interval) {
    clearInterval(getState().imports.intervalId);
    const intervalId = milliseconds
      ? window.setInterval(() => {
          dispatch(fetchImportsEffect());
        }, milliseconds)
      : undefined;
    dispatch(setPeriodicRefreshAction(intervalId, milliseconds));
  }
};
