import * as yup from "yup";
import { ExportType } from "../types";

const nameIPIRegex = /^[0-9]{3,15}$/;
const importIdRegex = /^CMO:[0-9]{3}:[-a-z0-9]+$/;

const organizationIdValidation = yup
  .string()
  .trim()
  .when(["exportType"], {
    is: (exportType: ExportType): boolean =>
      [ExportType.SUPPLIER_ID, ExportType.SUPPLIED].includes(exportType),
    then: yup.string().required("Organization is required field")
  });
const dateValidation = yup.object().shape({
  day: yup.string().required("Day is required field"),
  month: yup.string().required("Month is required field"),
  year: yup.string().required("Year is required field")
});
const nameIPIValidation = yup
  .string()
  .trim()
  .matches(nameIPIRegex, "IPI format is not valid")
  .required("IPI is required field");
const importIdValidation = yup
  .string()
  .trim()
  .matches(importIdRegex, "Import Id format is not valid")
  .required("Import Id is required field");

export const ExportFiltersSchema = (activeFilterTab: string) => {
  switch (activeFilterTab) {
    case "1": {
      return yup.object().shape({
        fromDate: dateValidation,
        toDate: dateValidation,
        organizationId: organizationIdValidation
      });
    }
    case "2": {
      return yup.object().shape({
        nameIPI: nameIPIValidation,
        organizationId: organizationIdValidation
      });
    }
    case "3": {
      return yup.object().shape({
        importId: importIdValidation,
        organizationId: organizationIdValidation
      });
    }
  }
};
