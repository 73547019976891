import { Export, ExportData } from "../../types";
import { ListLoadingState, LoadingState } from "../../utils/LoadingState";
import {
  ExportActionTypes,
  FETCH_EXPORT_DETAILS_SUCCESS,
  FETCH_EXPORTS,
  FETCH_EXPORTS_FAIL,
  FETCH_EXPORTS_SUCCESS,
  POST_EXPORT,
  POST_EXPORT_FAIL,
  POST_EXPORT_SUCCESS,
  SET_ACTIVE_FILTER_TAB,
  SET_PERIODIC_EXPORTS_REFRESH
} from "./Export.action.types";

export interface ExportState {
  loadingState: ListLoadingState;
  exports: Export[];
  error?: string;
  id?: string;
  link?: string;
  intervalId?: number;
  interval?: number;
  data: ExportData | undefined;
  errorMsg: string;
  exportCount: number;
  previewLoadingState: LoadingState;
  exportLoadingState: LoadingState;
  filterProps?: any;
  cancellable: boolean;
  cancelExportUrl?: string;
  downloadCsvUrl: string;
  downloadJsonUrl: string;
  activeFilterTab: string;
}

export const initialState: ExportState = {
  loadingState: ListLoadingState.PRISTINE,
  exports: [],
  data: undefined,
  errorMsg: "",
  exportCount: 0,
  previewLoadingState: LoadingState.PRISTINE,
  exportLoadingState: LoadingState.PRISTINE,
  cancellable: false,
  cancelExportUrl: "",
  downloadCsvUrl: "",
  downloadJsonUrl: "",
  activeFilterTab: "1"
};

export const exportReducer = (
  state: ExportState = initialState,
  action: ExportActionTypes
): ExportState => {
  switch (action.type) {
    case POST_EXPORT:
      return {
        ...state,
        exportLoadingState: LoadingState.FETCHING
      };
    case POST_EXPORT_FAIL:
      return {
        ...state,
        exportLoadingState: LoadingState.FAILED,
        errorMsg: action.error
      };
    case POST_EXPORT_SUCCESS:
      return {
        ...state,
        data: action.data,
        exportLoadingState: LoadingState.FETCHED,
        exportCount: state.exportCount + 1
      };
    case FETCH_EXPORTS: {
      const loadingState =
        state.loadingState === ListLoadingState.PRISTINE
          ? ListLoadingState.FETCHING
          : ListLoadingState.REFRESHING;

      return {
        ...state,
        exports:
          loadingState === ListLoadingState.FETCHING ? [] : state.exports,
        error: undefined,
        loadingState
      };
    }
    case FETCH_EXPORTS_FAIL:
      return {
        ...state,
        exports: [],
        loadingState: ListLoadingState.FAILED,
        error: action.error
      };
    case FETCH_EXPORTS_SUCCESS:
      return {
        ...state,
        exports: action.exportData,
        loadingState: ListLoadingState.FETCHED,
        cancelExportUrl:
          action.links.cancelExport && action.links.cancelExport.href,
        downloadCsvUrl: action.links.downloadCsv.href,
        downloadJsonUrl: action.links.downloadJson.href
      };

    case SET_PERIODIC_EXPORTS_REFRESH: {
      return {
        ...state,
        intervalId: action.intervalId,
        interval: action.interval
      };
    }
    case FETCH_EXPORT_DETAILS_SUCCESS: {
      const index = state.exports.findIndex(
        (exportItem: Export) => exportItem.exportId === action.exportId
      );
      return index !== -1
        ? {
            ...state,
            exports: [
              ...state.exports.slice(0, index),
              { ...action.exportData },
              ...state.exports.slice(index + 1)
            ]
          }
        : state;
    }
    case SET_ACTIVE_FILTER_TAB: {
      return {
        ...state,
        activeFilterTab: action.tabId
      };
    }
    default:
      return state;
  }
};
