import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../App.reducer";
import { removeAlertAction } from "./Alert.actions";
import { AlertsComponent } from "./Alerts.component";

const mapStateToProps = ({ alerts: { alerts } }: AppState) => ({ alerts });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeAlert: (id: number) => dispatch(removeAlertAction(id))
});

export const AlertsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsComponent);

export type AlertProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
