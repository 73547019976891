import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, ListGroupItem, Spinner } from "reactstrap";
import { Organization, Recording, WorkRef } from "../../types";
import { LoadingState } from "../../utils/LoadingState";
import { CollapsableList } from "../CollapsableList/CollapsableList.component";
import { RecordingComponent } from "../Recording/Recording.component";

interface RecordingGroupProps {
  recordingGroup: {
    name: string;
    value: number;
    recordings?: Recording[];
    loadingState: LoadingState | undefined;
    isOpen: boolean;
    totalCount: number;
  };
  workId: string;
  organizations: Organization[];
  recordingsUrlSegment: string;
  isDetailView?: boolean;
  activeRecordingId?: string;
  onLoadGroup: (offset: number) => void;
  onSetIsOpen?: (recordingGroup: string, isOpen: boolean) => void;
  works?: WorkRef[];
}

export const RecordingGroup = ({
  recordingGroup,
  workId,
  activeRecordingId,
  isDetailView,
  onLoadGroup,
  organizations,
  recordingsUrlSegment = "recordings",
  onSetIsOpen,
  works
}: RecordingGroupProps) => {
  // const [isOpen, setIsOpen] = useState(false);
  const offset =
    (recordingGroup.recordings && recordingGroup.recordings.length) || 0;
  useEffect(() => {
    // has name matching id param
    const isActiveRecordingGroup =
      activeRecordingId &&
      activeRecordingId.split(":")[0] ===
        recordingGroup.name.replace(/TRACK:/g, "");
    if (
      typeof recordingGroup.loadingState === "undefined" &&
      isActiveRecordingGroup
    ) {
      onLoadGroup(0);
    }
  }, [recordingGroup, activeRecordingId]);

  const loadMore = recordingGroup.value - offset;
  return (
    <CollapsableList
      title={recordingGroup.name.replace(/TRACK:/g, "").replace(/_/g, " ")}
      isOpen={recordingGroup.isOpen === true}
      zIndex={2}
      isSub={true}
      onClick={e => {
        e.preventDefault();
        if (recordingGroup.recordings == null) {
          onLoadGroup(0);
        } else {
          if (onSetIsOpen) {
            onSetIsOpen(recordingGroup.name, !recordingGroup.isOpen);
          }
        }
      }}
      disabled={false}
      customBadge={
        recordingGroup.loadingState === LoadingState.FETCHING &&
        offset === 0 ? (
          <Spinner size="sm" color="secondary" />
        ) : isDetailView ? (
          <Badge pill>{recordingGroup.value}</Badge>
        ) : (
          <Badge pill>{`~ ${recordingGroup.totalCount}`}</Badge>
        )
      }
    >
      {recordingGroup.recordings &&
        recordingGroup.recordings.length >= 0 &&
        recordingGroup.recordings.map((recording, index) => (
          <RecordingComponent
            zIndex={1}
            isSub={true}
            organizations={organizations}
            recording={recording}
            key={index}
            recordingId={activeRecordingId}
            works={works}
            copyId={recording.identifier.id}
          />
        ))}
      {loadMore > 0 && (
        <ListGroupItem className="w-100 card-header d-flex justify-content-end">
          <span>
            {recordingGroup.loadingState === LoadingState.FETCHING &&
            offset > 0 ? (
              <Spinner size="sm" className="mr-3" color="secondary" />
            ) : (
              <a
                href="#"
                className="small mr-3"
                onClick={e => {
                  e.preventDefault();
                  onLoadGroup(offset);
                }}
              >
                + {isDetailView && loadMore} more...
              </a>
            )}
            <Link
              to={`/${recordingsUrlSegment}/${workId}/${recordingGroup.name}`}
              className="small"
            >
              open all
            </Link>
          </span>
        </ListGroupItem>
      )}
    </CollapsableList>
  );
};
