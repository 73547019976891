import { ExportFilterType } from "./containers/Export/types";
import { LoadingState } from "./utils/LoadingState";

export interface WorkRef {
  id: string;
  label: string;
  sourceId: string;
  sourceIdType: string;
  timestamp: number;
  title: string;
  dataAuthority?: number;
}

export interface Recording {
  identifier: {
    fullId: string;
    namespace: string;
    id: string;
  };
  label: string;
  title: string;
  dataAuthority?: number;
  linkDataAuthority?: number;
  linkClassification?: number;
  in: AggregatedSource[];
}

export interface RecordingGroups {
  totalCount: number;
  groups: {
    [x: string]: number;
  };
  pagination?: {
    [x: string]: number;
  };
  recordings?: {
    [x: string]: Recording[];
  };
  isOpen?: {
    [x: string]: boolean;
  };
  loadingState?: { [x: string]: LoadingState };
}

export interface InterestedParty {
  id: string;
  ipiBaseNumber: string;
  ipiNameNumber: string;
  name: string;
  timestamp: number;
  works?: string[];
  in?: AggregatedSource[];
}

export interface Identifier {
  id: string;
  label: string;
  sourceId: string;
  sourceIdType: string;
  RelationshipProperties: {
    source: string;
  };
  works?: string[];
  in?: AggregatedSource[];
}

export interface RelatedWork {
  ipiBaseNumber?: string;
  ipiNameNumber?: string;
  name?: string;
  timestamp?: string;
  title: string;
  id?: string;
}

export interface AggregatedSource {
  dataAuthority: string;
  id: string;
  supplierId: string;
  work: string;
  timestamp: number;
}
export interface WorkTitle {
  title: string;
  in?: AggregatedSource[];
}

export interface Work {
  id: string;
  Created: InterestedParty[];
  Identifier: Identifier[];
  RecordingGroups: RecordingGroups;
  RelatedWorks: RelatedWork[];
  title: string;
  altTitles: WorkTitle[];
  featuredArtists: string[];
  timestamp: number;
  dataAuthority?: number;
  Link?: Recording[];
  works?: WorkRef[];
  collaborators?: string[];
}

export enum ExportStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  TRANSFORMING = "TRANSFORMING",
  PROCESSING = "PROCESSING",
  PREPARING = "PREPARING",
  REQUESTED = "REQUESTED",
  CANCELLED = "CANCELLED"
}

export enum ExportType {
  ISWC = "byIswc",
  SUPPLIER_ID = "bySupplierId",
  SUPPLIED = "asSupplied"
}

export enum ConflictType {
  EXCLUDE = "exclude",
  ONLY = "only",
  INCLUDE = "include"
}

export enum DownloadType {
  JSON = "json",
  CSV = "csv"
}

export interface Export {
  filters: {
    nameIPI: string;
    linkClassification: string[];
    toDate: string;
    fromDate: string;
    importId?: string;
    conflicts: string;
    exportType: string;
    organizationId?: string;
  };
  created: number;
  statusReason: string;
  status: ExportStatus;
  exportId: string;
  downloadable: boolean;
  _links: {
    download: Href[];
  };
}

export interface Date {
  day: string;
  month: string;
  year: string;
}

export interface ExportData {
  count: number;
  exportId: string;
  filters: ExportFilterType;
}

export enum ImportStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  COMPLETED_WITH_ERRORS = "COMPLETED_WITH_ERRORS",
  FAILED = "FAILED"
}

export enum ImportState {
  _UPLOADING = "_UPLOADING",
  INITIATED = "INITIATED",
  QUEUED = "QUEUED",
  WAITING = "WAITING",
  PROCESSING = "PROCESSING",
  COLLECTING_STATISTICS = "COLLECTING_STATISTICS",
  COMPLETED = "COMPLETED",
  COMPLETED_WITH_ERRORS = "COMPLETED_WITH_ERRORS",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED"
}

export interface Submitter {
  organizationId: string;
  username: string;
}

export enum BatchStatus {
  CREATED = "CREATED",
  BLOCKCHAIN = "BLOCKCHAIN",
  QUEUED = "QUEUED",
  PROCESSING_BATCH = "PROCESSING_BATCH",
  COMPELTED = "COMPELTED",
  FAILED = "FAILED"
}

export enum BatchStep {
  PENDING = "PENDING",
  TRANSFORMING = "TRANSFORMING",
  LOADING_VERTICES = "LOADING_VERTICES",
  LOADING_EDGES = "LOADING_EDGES",
  CLEAN_UP = "CLEAN_UP",
  COMPUTING_EDGES = "COMPUTING_EDGES",
  COUNTING_STATISTICS = "COUNTING_STATISTICS",
  EXPORTING = "EXPORTING"
}

export interface Batch {
  batchIdentifier: string;
  batchNumber: number;
  importId: string;
  updated: number;
  status: BatchStatus;
  created: number;
  step: BatchStep;
  error: {
    message?: string;
    catchError: {
      Error: string;
      Cause: string;
    };
    importId: string;
    organizationIdentifier: string;
    batch: {
      number: number;
      signature: string;
    };
    executionState: {
      ready: boolean;
    };
    files: {
      edges: {
        checksum: string;
        url: string;
      };
      json: {
        url: string;
      };
      vertices: {
        checksum: string;
        url: string;
      };
    };
    status: {
      vertices: {
        loadId: string;
        status: string;
      };
    };
  };
  attachment: string;
}

export interface ImportProgress {
  processedBatches: number;
  completedBatches: number;
  failedBatches: number;
  totalBatches: number;
  currentSequence: number;
  currentBatch?: number;
}

export const IMPORT_ERROR_DATA_VALIDATION = "DATA_VALIDATION";
export const IMPORT_ERROR_DATA_FORMAT = "DATA_FORMAT";

export interface ImportErrorStack {
  type: typeof IMPORT_ERROR_DATA_VALIDATION;
  message: string;
  source: string;
  providedValue: string;
}

export interface ImportErrorLine {
  type: typeof IMPORT_ERROR_DATA_FORMAT;
  message: string;
  source: string;
  providedValue: string;
}

export type ImportError = ImportErrorStack | ImportErrorLine;

export interface ImportErrorSummary {
  message: string;
  numberOfValidationErrors: number;
  details?: ImportError[];
}

export enum LogFileType {
  LONG_CSV_LINES = "LONG_CSV_LINES",
  FILTERED_RECORDINGS = "FILTERED_RECORDINGS",
  BAD_CSV_RECORDS = "BAD_CSV_RECORDS",
  BAD_JSON_RECORDS = "BAD_JSON_RECORDS",
  FILTERED_WORK_IDS = "FILTERED_WORK_IDS"
}

export interface LogFile {
  dataFile: string;
  errorLog?: string;
  logCount: number;
  type: LogFileType;
}

export interface ImportRaw {
  importId: string;
  created: number;
  submitter: Submitter;
  state: ImportState;
  sourceFile: {
    name: string;
    downloadToken?: string;
  };
  attachment: string;
  progress?: ImportProgress;
  updated?: number;
  elapsed?: number;
  batches?: Batch[];
  result?: {
    statistics: {
      linksInvalid: number;
      linksTotal: number;
      linksValid: number;
      worksTotal: number;
    };
  };
  logFiles?: LogFile[];
  error?: ImportErrorSummary;
  cancellable: boolean;
}

export interface Import extends ImportRaw {
  _loadingStateDetails?: LoadingState;
  _loadingStateFullErrorLogUrl?: LoadingState;
  _uploadProgress?: number;
  _fetchFullErrorLogUrl?: string;
  _fullErrorLogUrl?: string;
}

export interface Organization {
  id: string;
  name: string;
}

export interface Metrics {
  global?: {
    id?: string;
    label?: string;
    worksTotal?: number;
    linksTotal?: number;
    linksConfident?: number;
    linksDerived?: number;
    isrcsUnique?: number;
  };
}

export interface SearchLogEntry {
  id: string;
  link: string;
  type: string;
}

export interface Error {
  error: {
    message: string;
  };
}

export interface Href {
  href: string;
}
