import React, { Fragment } from "react";

export const Definitions = {
  valid: (
    <Fragment>
      <p>A link between an ISRC and one or more Works where:</p>
      <ul>
        <li>
          Either <strong>all</strong> Works are connected to a single ISWC
        </li>
        <li>
          Or <strong>some</strong> Works are connected to a single ISWC and
          Titles & Creators match between all works.
        </li>
      </ul>
    </Fragment>
  ),
  invalid: (
    <Fragment>
      <p>A link between an ISRC and multiple Works where:</p>
      <ul>
        <li>Where the Works are connected to two or more distinct ISWCs</li>
      </ul>
    </Fragment>
  ),
  probable: (
    <Fragment>
      <p>A link between an ISRC and one or more Works where:</p>
      <ul>
        <li>There is no ISWC</li>
        <li>Creators and Titles match between all works.</li>
      </ul>
    </Fragment>
  ),
  possible: (
    <Fragment>
      <p>A link between an ISRC and one or more Works where:</p>
      <ul>
        <li>There is zero or one ISWC but not all works are connected to it</li>
        <li>Creators and Titles do not match between all works.</li>
      </ul>
    </Fragment>
  ),
  totalWorks: (
    <Fragment>
      <p>The total number of unique works supplied by each submitter</p>
      <p>
        <i>
          Example: For each submitter, the total number of unique works
          identified by an ISWC, or just a submitter work ID and no ISWC, that
          validation and was ingested into the Elixir application whether linked
          linked to a recording or not, regardless of their Link Classification.
        </i>
      </p>
    </Fragment>
  ),
  totalLinks: (
    <Fragment>
      <p>
        The total number of links between works and recordings in the Elixir
        Application supplied by the three submitters
      </p>
      <p>
        <i>
          Example: The total number of unique works submitted by all 3 parties
          as defined in 2. above, linked to an ISRC with a Link Classification
          of "Valid" and "High Confidence"*
        </i>
      </p>
    </Fragment>
  ),
  totalSharedLinks: (
    <Fragment>
      <p>
        The total number of additional links between works and recordings that
        become available to the three submitters by Elixir
      </p>
      <p>
        <i>
          Example: The number of works (identified by an ISWC, or just a
          submitter work ID and no ISWC) linked to an ISRC with a Link
          Classification of "Valid" and "High Confidence"* that are "new" to a
          submitter, as they were not included in their initial load of data.
        </i>
      </p>
    </Fragment>
  ),
  exclude: <p>Exclude any work aggregations that are in direct conflict.</p>,
  only: <p>Only include work aggregations that are in direct conflict.</p>,
  include: (
    <p>Give all, this is the default if the parameter is not provided.</p>
  ),
  LONG_CSV_LINES: (
    <Fragment>
      <p>
        CSV lines has a limit to how long they can be, or how much data they
        contain, to be imported. The data file contains the raw CSV records that
        were filtered from the import due to this size restriction.
      </p>
      <p>
        To import these records, please remove data that is not required from
        each records in the data file and re-import it.
      </p>
    </Fragment>
  ),
  FILTERED_RECORDINGS: (
    <p>
      Recording Ids that do not conform to the validation rules are removed from
      the respective Works during the import. The data file contains a detailed
      list of these ids, which Work they belong to and why they were not
      accepted.
    </p>
  ),
  BAD_CSV_RECORDS: (
    <Fragment>
      <p>
        The data file contains CSV records that could not be parsed and/or
        validated. The error log contains detailed error information for each
        line. The line numbers in the two files correspond so the error for CSV
        line 1 is on line 1 in the error log, etc.
      </p>
      <p>
        To import these Works, please address the issues in the data file and
        re-import it.
      </p>
    </Fragment>
  ),
  BAD_JSON_RECORDS: (
    <Fragment>
      <p>
        The data file contains JSON records that could not be validated against
        to the Works schema. The error log contains detailed error information
        for each record. The record numbers in the two files correspond so the
        error for JSON record 1 is the first error record in the error log.
      </p>
      <p>
        To import these Works, please address the issues in the data file and
        re-import it.
      </p>
      <p>
        Note: If the file originally uploaded was a CSV file you need to extract
        those records from the raw CSV file and amend them to a new CSV delta
        file and upload that file instead.
      </p>
    </Fragment>
  ),
  FILTERED_WORK_IDS: (
    <p>
      Work Ids (Xrefs) that do not conform to the validation rules are removed
      from the respective Works during the import. The data file contains a
      detailed list of these ids, which Work they belong to and why they were
      not accepted.
    </p>
  )
};
