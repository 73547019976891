export enum LoadingState {
  PRISTINE = "PRISTINE",
  FETCHING = "FETCHING",
  FETCHED = "FETCHED",
  FAILED = "FAILED"
}

export enum ListLoadingState {
  PRISTINE = "PRISTINE",
  FETCHING = "FETCHING",
  FETCHING_MORE = "FETCHING_MORE",
  REFRESHING = "REFRESHING",
  FETCHED = "FETCHED",
  FAILED = "FAILED"
}
