import {
  faBan,
  faCheck,
  faExclamationTriangle,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Progress,
  Row,
  Spinner
} from "reactstrap";
import {
  Batch,
  Import,
  ImportState,
  LogFile,
  LogFileType,
  Organization
} from "../../types";
import { Definitions } from "../../utils/Definitions";
import { convertMsToDuration, displayItemsWithPipe } from "../../utils/helpers";
import { LoadingState } from "../../utils/LoadingState";
import { IconCard } from "../IconCard/IconCard.component";
import { ImportListButton } from "../ImportListButton/ImportListButton.component";
import { ImportProgressComponent } from "../ImportProgress/ImportProgress.component";
import { InfoIconComponent } from "../InfoIcon/InfoIcon.component";
import { Modal } from "../Modal/Modal.component";

interface ImportCardProps {
  importItem: Import;
  loadDetails: () => void;
  fetchExportByImportId: (importId: string) => void;
  cancelImport: (importId: string) => void;
  downloadLogFile: (importId: string, file: string) => any;
  downloadImport: (
    importId: string,
    downloadToken: string
  ) => Promise<string | void>;
  history: {
    push: (path: string) => void;
  };
  organizations: Organization[];
}

export const ImportCard = ({
  importItem,
  loadDetails,
  fetchExportByImportId,
  cancelImport,
  history,
  organizations,
  downloadLogFile,
  downloadImport
}: ImportCardProps) => {
  const [isOpen, setIsOpen] = useState({
    showLogs: false,
    showDetails: false
  });

  const statusIcon = (status: ImportState) => {
    switch (status) {
      case ImportState._UPLOADING:
      case ImportState.QUEUED:
      case ImportState.WAITING:
      case ImportState.PROCESSING:
      case ImportState.COLLECTING_STATISTICS:
      case ImportState.INITIATED:
        return <Spinner size="sm" color="primary" />;
      case ImportState.FAILED:
      case ImportState.COMPLETED_WITH_ERRORS:
        return <FontAwesomeIcon icon={faTimes} color="#D0021B" />;
      case ImportState.COMPLETED:
        return <FontAwesomeIcon icon={faCheck} className="text-primary" />;
      case ImportState.CANCELLED:
        return <FontAwesomeIcon icon={faBan} className="text-warning" />;
      default:
        return <FontAwesomeIcon icon={faSpinner} className="text-primary" />;
    }
  };

  const sourceFileSplit = importItem.sourceFile.name.split(".");

  const sourceFileType =
    (sourceFileSplit.length > 1 &&
      sourceFileSplit[sourceFileSplit.length - 1].toUpperCase()) ||
    "Source";

  const logFileType = (type: LogFileType) => {
    switch (type) {
      case LogFileType.LONG_CSV_LINES:
        return "Long CSV Lines";
      case LogFileType.FILTERED_RECORDINGS:
        return "Filtered Recordings";
      case LogFileType.BAD_CSV_RECORDS:
        return "CSV Validation Issues";
      case LogFileType.BAD_JSON_RECORDS:
        return "JSON Validation Issues";
      case LogFileType.FILTERED_WORK_IDS:
        return "Filtered Work Ids";
      default:
        return type;
    }
  };

  const viewBatchList = (batch?: Batch) => {
    if (batch && batch.batchIdentifier) {
      return history.push(
        `/search/${encodeURIComponent(batch.batchIdentifier)}?type=batch`
      );
    }
  };
  const batchesLabel =
    importItem.progress && importItem.progress.processedBatches === 1
      ? "Batch"
      : "Batches";
  const infoSelector = importItem.importId.split("-").pop();
  const organization = organizations.find(
    org => org.id === importItem.submitter.organizationId
  ) || { name: "" };
  const submissionInfo = displayItemsWithPipe(
    organization.name,
    importItem.submitter.username || "N/a",
    `${moment.unix(importItem.created).format("MM/DD/YYYY h:mm:ss a")}
    ${
      importItem.elapsed != null
        ? `(took ${convertMsToDuration(importItem.elapsed * 1000)})`
        : ""
    }`
  );

  const createDownloadFile = (href: string) => {
    if (href) {
      const link = document.createElement("a");
      link.href = href;
      link.download = "download";
      link.target = "_blank";
      link.click();
    }
  };

  const removeOrganizationPrefix = (id: string) => id.substring(8, id.length);

  return (
    <IconCard
      id={importItem.importId}
      icon={
        <React.Fragment>
          {statusIcon(importItem.state)}
          {[ImportState.COMPLETED, ImportState.COMPLETED_WITH_ERRORS].indexOf(
            importItem.state
          ) !== -1 &&
            importItem.logFiles &&
            importItem.logFiles.length > 0 && (
              <FontAwesomeIcon
                style={{
                  position: "absolute",
                  left: "13px",
                  top: "12px",
                  fontSize: "8px",
                  opacity: 0.7
                }}
                icon={faExclamationTriangle}
                className="text-danger"
              />
            )}
        </React.Fragment>
      }
    >
      <Row data-test-id={"import-card"}>
        <Col
          style={{ maxWidth: "initial", flexBasis: "0", minWidth: "100px" }}
          className="overflow-hidden text-nowrap overflow-hidden"
        >
          <h5
            style={{ textOverflow: "ellipsis" }}
            className="overflow-hidden"
            id={importItem.importId.replace(/\W/g, "")}
          >
            <span>{importItem.sourceFile.name}</span>
          </h5>
        </Col>
        <Col sm="auto" className={"text-left text-md-right"}>
          <small className="text-muted">{submissionInfo}</small>
          <div className={"text-left text-md-right"}>
            <small className={"text-muted"} style={{ opacity: 0.5 }}>
              {removeOrganizationPrefix(importItem.importId)}
            </small>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          {importItem.state === ImportState._UPLOADING && (
            <div className={"mt-3"}>
              <Progress value={importItem._uploadProgress} />
              {importItem._uploadProgress &&
              importItem._uploadProgress < 100 ? (
                <small className={"text-muted"}>Uploading...</small>
              ) : (
                <small className={"text-muted"}>Waiting...</small>
              )}
            </div>
          )}
          {importItem.state === ImportState.INITIATED && (
            <small className={"text-muted"}>Initiated...</small>
          )}
          {importItem.state === ImportState.QUEUED && (
            <small className={"text-muted"}>Queued...</small>
          )}
          {[
            ImportState.WAITING,
            ImportState.PROCESSING,
            ImportState.COLLECTING_STATISTICS
          ].includes(importItem.state) && (
            <Row>
              <Col className={"mt-3"}>
                <ImportProgressComponent
                  progress={importItem.progress}
                  batches={importItem.batches}
                  state={importItem.state}
                />
              </Col>
            </Row>
          )}
          {importItem.state === ImportState.CANCELLED && importItem.error && (
            <small className={"text-muted"}>{importItem.error.message}</small>
          )}
          {importItem.state !== ImportState.CANCELLED &&
            importItem.error &&
            importItem.error.message && (
              <div className="mb-2 mt-2 text-danger small">
                Error: {importItem.error.message}
              </div>
            )}
          {importItem.result && (
            <small>
              <strong className="text-primary text-muted">
                {importItem.result.statistics.worksTotal}
              </strong>
              <span className="text-muted">
                {" "}
                {importItem.result.statistics.worksTotal > 1 ? `Works` : `Work`}
                <InfoIconComponent
                  id={`works-${(infoSelector || "").replace(/\W/g, "")}`}
                  infoText={Definitions.totalWorks}
                />
                ,{" "}
              </span>

              <strong className="text-primary text-muted">
                {importItem.result.statistics.linksTotal}
              </strong>
              <span className="text-muted">
                {" "}
                Total Links
                <InfoIconComponent
                  id={`links-${(infoSelector || "").replace(/\W/g, "")}`}
                  infoText={Definitions.totalSharedLinks}
                />
                ,{" "}
              </span>

              <strong className="text-primary text-muted">
                {importItem.result.statistics.linksValid}
              </strong>
              <span className="text-muted">
                {" "}
                Valid Links
                <InfoIconComponent
                  id={`validLinks-${(infoSelector || "").replace(/\W/g, "")}`}
                  infoText={Definitions.totalLinks}
                />
              </span>
            </small>
          )}
          <Row className="mt-3">
            <Col>
              {importItem.state !== ImportState._UPLOADING && (
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  className={"mr-2"}
                  onClick={() => {
                    setIsOpen({
                      ...isOpen,
                      showDetails: !isOpen.showDetails,
                      showLogs: false
                    });
                    if (!importItem.batches) {
                      loadDetails();
                    }
                  }}
                >
                  {isOpen.showDetails
                    ? `Hide ${batchesLabel}`
                    : `Show ${batchesLabel}`}
                </Button>
              )}
              {importItem.logFiles && importItem.logFiles.length > 0 && (
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    setIsOpen({
                      showDetails: false,
                      showLogs: !isOpen.showLogs
                    });
                  }}
                >
                  {isOpen.showLogs ? `Hide Logs` : `Show Logs`}
                </Button>
              )}
            </Col>

            {[ImportState.COMPLETED, ImportState.COMPLETED_WITH_ERRORS].indexOf(
              importItem.state
            ) !== -1 && <Col sm="auto" />}

            <Col sm="auto">
              {importItem.sourceFile.downloadToken != null && (
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    downloadImport(
                      importItem.importId,
                      importItem.sourceFile.downloadToken || ""
                    ).then((link: string | void) =>
                      createDownloadFile(link || "")
                    );
                  }}
                >
                  Download Source {sourceFileType}
                </Button>
              )}
              {[
                ImportState.COMPLETED,
                ImportState.COMPLETED_WITH_ERRORS
              ].indexOf(importItem.state) !== -1 && (
                <Button
                  data-test-id={"export-import"}
                  outline
                  className="ml-2"
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    fetchExportByImportId(importItem.importId);
                    history.push(`/export`);
                  }}
                >
                  Export
                </Button>
              )}
              {[
                ImportState.COMPLETED,
                ImportState.COMPLETED_WITH_ERRORS
              ].indexOf(importItem.state) !== -1 &&
                importItem.progress &&
                // Mock chain sometimes gives totalBatches:1 even if more
                importItem.progress.totalBatches === 1 &&
                (!importItem.progress.processedBatches ||
                  importItem.progress.processedBatches <= 1) && (
                  <ImportListButton
                    importItem={importItem}
                    loadDetails={loadDetails}
                    viewBatchList={viewBatchList}
                  />
                )}
              {importItem.cancellable && (
                <div className="d-inline-block ml-2">
                  <Modal
                    onConfirm={() => cancelImport(importItem.importId)}
                    title="Cancel an Import"
                    message="Cancelling an import will stop current progress. Any changes already made will not be rolled back."
                  >
                    <Button outline color="danger" size="sm">
                      Cancel
                    </Button>
                  </Modal>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Collapse isOpen={isOpen.showLogs}>
                <table className="table table-borderless mt-4 border-top">
                  <thead>
                    <tr className="text-muted small">
                      <th style={{ width: "8%" }}>Records</th>
                      <th>Type</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {importItem.logFiles &&
                      importItem.logFiles.map(
                        (logFile: LogFile, index: number) => (
                          <tr key={index} className="text-muted small">
                            <td>{logFile.logCount}</td>
                            <td>
                              {logFileType(logFile.type)}
                              <InfoIconComponent
                                id={`${importItem.importId}${logFile.type}`}
                                infoText={Definitions[logFile.type]}
                              />
                            </td>
                            <td className="text-right">
                              <div className="justify-content-end">
                                <Button
                                  outline
                                  color="secondary"
                                  size="sm"
                                  onClick={() => {
                                    downloadLogFile(
                                      importItem.importId,
                                      logFile.dataFile
                                    ).then((link: string) =>
                                      createDownloadFile(link)
                                    );
                                  }}
                                >
                                  Download
                                </Button>
                                {logFile.errorLog && (
                                  <Button
                                    outline
                                    color="secondary"
                                    size="sm"
                                    className="ml-2"
                                    onClick={() => {
                                      downloadLogFile(
                                        importItem.importId,
                                        logFile.errorLog || ""
                                      ).then((link: string) =>
                                        createDownloadFile(link)
                                      );
                                    }}
                                  >
                                    Error Log
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </Collapse>
              <Collapse isOpen={isOpen.showDetails}>
                {importItem._loadingStateDetails === LoadingState.FETCHED &&
                importItem.batches &&
                importItem.batches.length > 0 ? (
                  <table className="table table-borderless mt-4 border-top">
                    <thead />
                    <tbody key="1">
                      {importItem.batches.map((batch, index) => (
                        <tr key={index} className="mt-1">
                          <td
                            className="text-muted small"
                            style={{ width: "8.3%" }}
                          >
                            Batch {batch.batchNumber}
                          </td>
                          <td style={{ width: "25%" }}>
                            <Badge
                              style={{
                                top: "-4px",
                                position: "relative"
                              }}
                              color="secondary"
                            >
                              {batch.status}
                            </Badge>
                          </td>
                          <td className="text-muted small">
                            {batch.error && batch.error.message}
                          </td>
                          {importItem.progress &&
                            importItem.progress.totalBatches > 1 && (
                              <td>
                                <Button
                                  className="float-right"
                                  color="secondary"
                                  size="sm"
                                  onClick={() => {
                                    viewBatchList(batch);
                                  }}
                                >
                                  List Imported Works
                                </Button>
                              </td>
                            )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="w-100 d-flex justify-content-center text-muted mt-3 pt-3 pb-2 border-top">
                    {importItem._loadingStateDetails ===
                    LoadingState.FETCHING ? (
                      <Spinner size="sm" />
                    ) : (
                      "No Batches Processed"
                    )}
                  </div>
                )}
              </Collapse>
            </Col>
          </Row>
        </Col>
      </Row>
    </IconCard>
  );
};
