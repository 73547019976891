import { Metrics } from "../../types";
import {
  FETCH_METRICS,
  FETCH_METRICS_FAIL,
  FETCH_METRICS_SUCCESS,
  MetricsActionTypes
} from "./Metrics.action.types";

export const fetchMetricsSuccessAction = (
  data: Metrics
): MetricsActionTypes => ({
  type: FETCH_METRICS_SUCCESS,
  data
});

export const fetchMetricsFailAction = (error: string): MetricsActionTypes => ({
  type: FETCH_METRICS_FAIL,
  error
});

export const fetchMetricsAction = (): MetricsActionTypes => ({
  type: FETCH_METRICS
});
