import { User } from "../../Auth.actions";
import {
  Challenge,
  HANDLE_CHALLENGE,
  LOGIN_STATE,
  LoginStates,
  PASSWORD_RESET_CONFIRM_FAILURE,
  PASSWORD_RESET_FAILURE,
  REFRESH_SUCCESS,
  SET_EMAIL,
  SET_NEW_PASSWORD_FAILURE,
  SET_REDIRECT,
  SigninActionTypes,
  Tokens,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "./Signin.action.types";

export interface SigninState {
  email: string;
  errorMessage: string;
  resetErrorMessage: string;
  confirmErrorMessage: string;
  newPasswordErrorMessage: string;
  authTokens: Tokens;
  user: User;
  signinState: LoginStates;
  redirect: string;
  challenge?: Challenge;
}

export const initialState: SigninState = {
  email: "",
  errorMessage: "",
  resetErrorMessage: "",
  confirmErrorMessage: "",
  newPasswordErrorMessage: "",
  authTokens: { accessToken: "", refreshToken: "", authToken: "" },
  user: {
    username: "",
    email: "",
    organizationIdentifier: "",
    permissions: { upload: false }
  },
  signinState: LoginStates.signin,
  redirect: ""
};

export const signinReducer = (
  state: SigninState = initialState,
  action: SigninActionTypes
): SigninState => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        email: action.user.email,
        user: action.user,
        redirect: "",
        authTokens: {
          accessToken: action.tokens.accessToken,
          refreshToken: action.tokens.refreshToken,
          authToken: action.tokens.idToken
        }
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        authTokens: {
          accessToken: action.tokens.accessToken,
          refreshToken: action.tokens.refreshToken,
          authToken: action.tokens.idToken
        }
      };
    case USER_LOGIN_FAILURE:
      return { ...state, errorMessage: action.error };
    case USER_LOGOUT:
      return {
        ...state,
        errorMessage: "",
        resetErrorMessage: "",
        confirmErrorMessage: "",
        email: "",
        authTokens: { accessToken: "", refreshToken: "", authToken: "" },
        signinState: LoginStates.signin,
        redirect: "",
        user: {
          username: "",
          email: "",
          organizationIdentifier: "",
          permissions: { upload: false }
        }
      };
    case LOGIN_STATE: {
      return {
        ...state,
        signinState: action.signinState,
        errorMessage: "",
        newPasswordErrorMessage: ""
      };
    }
    case PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        resetErrorMessage: action.error
      };
    }
    case PASSWORD_RESET_CONFIRM_FAILURE: {
      return {
        ...state,
        confirmErrorMessage: action.error
      };
    }
    case SET_EMAIL: {
      return { ...state, email: action.email };
    }
    case SET_REDIRECT: {
      return { ...state, redirect: action.path };
    }
    case SET_NEW_PASSWORD_FAILURE: {
      return {
        ...state,
        newPasswordErrorMessage: action.error
      };
    }
    case HANDLE_CHALLENGE:
      const {
        username,
        name,
        _links: {
          challenge: { headers, href, method }
        }
      } = action.challenge;
      return {
        ...state,
        challenge: {
          name,
          username,
          headers,
          href,
          method
        }
      };
    default:
      return state;
  }
};
