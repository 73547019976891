import axios from "axios";
import { Action, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { apiUrls } from "../../api.config";
import { RecordingGroupResponse, WorkResponse } from "../../api.types";
import { AppState } from "../../App.reducer";
import { Error } from "../../types";
import { Errors } from "../../utils/UnexpectedErrors";
import {
  fetchDetailAction,
  fetchDetailFailAction,
  fetchDetailSuccessAction,
  fetchRecordingGroupAction,
  fetchRecordingGroupFailAction,
  fetchRecordingGroupSuccessAction
} from "./Detail.actions";

export const fetchDetailEffect = (
  id: string,
  recordingGroup?: string,
  offset?: number,
  limit?: number
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  getState: () => AppState
) => {
  dispatch(fetchDetailAction(id));
  axios
    .get<WorkResponse>(`${apiUrls.work}/${id}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(fetchDetailSuccessAction(response.data.response.work));
        if (recordingGroup != null && offset != null && limit != null) {
          fetchRecordingGroupEffect(recordingGroup, offset, limit)(
            dispatch,
            getState
          );
        }
      } else {
        const message = response.data.error.message;
        dispatch(fetchDetailFailAction(message, response.status));
      }
    })

    .catch(e => {
      if (e.response) {
        dispatch(
          fetchDetailFailAction(
            (e.response.data.error && e.response.data.error.message) ||
              e.response.data.message,
            e.response.status
          )
        );
      } else {
        dispatch(fetchDetailFailAction(Errors.uncatchError));
      }
      // tslint:disable-next-line: no-console
      console.log(e);
    });
};

export const fetchRecordingGroupEffect = (
  recordingGroup: string,
  offset: number,
  limit: number = 10
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  getState: () => AppState
) => {
  const id = getState().detail.id;
  dispatch(fetchRecordingGroupAction(recordingGroup));
  axios
    .get<RecordingGroupResponse>(
      `${
        apiUrls.work
      }/${id}/recordings/${recordingGroup}?offset=${offset}&limit=${limit}`
    )
    .then(response => {
      if (response.status === 200) {
        dispatch(
          fetchRecordingGroupSuccessAction(
            recordingGroup,
            response.data.response.recordings
          )
        );
      } else {
        dispatch(
          fetchRecordingGroupFailAction(
            recordingGroup,
            response.data.error.message
          )
        );
      }
    })

    .catch((e: { response: { data: Error } }) => {
      if (e.response && e.response.data.error.message) {
        dispatch(
          fetchRecordingGroupFailAction(
            recordingGroup,
            e.response.data.error.message
          )
        );
      } else {
        // @ts-ignore
        dispatch(
          fetchRecordingGroupFailAction(recordingGroup, Errors.uncatchError)
        );
      }
    });
};
