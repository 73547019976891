import * as React from "react";
import { Component, Fragment } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  withRouter
} from "react-router-dom";

import { connect } from "react-redux";
import { FillHeightContainer } from "./components/FillHeightContainer/FillHeightContainer";
import { AggregatedContainer } from "./containers/Aggregated/Aggregated";
import { AlertsContainer } from "./containers/Alerts/Alerts";
import { DetailContainer } from "./containers/Detail/Detail";
import { ExportContainer } from "./containers/Export/Export";
import { HeaderContainer } from "./containers/Header/Header";
import { ImportContainer } from "./containers/Import/Import";
import { MetricsContainer } from "./containers/Metrics/Metrics";
import { RecordingsContainer } from "./containers/Recordings/Recordings";
import { RecordingsAggregatedContainer } from "./containers/RecordingsAggregated/RecordingsAggregated";
import { SearchContainer } from "./containers/Search/Search";
import { SigninContainer } from "./containers/Signin/Signin";
import { SigninState } from "./containers/Signin/Signin.reducer";
import { PrivateRoute } from "./PrivateRoute";
import { ScrollToTop } from "./ScrollToTop";

class AppComponent extends Component<AppProps> {
  public render(): React.ReactNode {
    return (
      <Fragment>
        <AlertsContainer />
        <ScrollToTop>
          <FillHeightContainer>
            {this.props.location.pathname !== "/signin" && <HeaderContainer />}
            <div className="flex-grow-1">
              <Route
                exact
                path="/"
                render={(): React.ReactNode => <Redirect to="/import" />}
              />
              <Route exact path="/signin" component={SigninContainer} />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/import"
                component={ImportContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/works"
                component={DetailContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/works/:id"
                component={DetailContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/aggregated-works/:iswcId"
                component={AggregatedContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/recordings/:id/:recordingGroup"
                component={RecordingsContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/recordings-aggregated/:id/:recordingGroup"
                component={RecordingsAggregatedContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/search/:id"
                component={SearchContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/search"
                component={SearchContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/metrics"
                component={MetricsContainer}
              />
              <PrivateRoute
                auth={this.props.auth}
                exact
                path="/export"
                component={ExportContainer}
              />
            </div>
          </FillHeightContainer>
        </ScrollToTop>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  signin
}: {
  signin: SigninState;
}): MapStateToPropsType => {
  return {
    auth: signin.authTokens.accessToken !== ""
  };
};

export const App = withRouter(connect(mapStateToProps)(AppComponent));

interface AppProps extends RouteComponentProps {
  auth: boolean;
}

interface MapStateToPropsType {
  auth: boolean;
}
