import * as React from "react";
import { FormEvent, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap";

import { ColFillHeight } from "../../components/ColFillHeight/ColFillHeight.component";
import { FillHeightContainer } from "../../components/FillHeightContainer/FillHeightContainer";
import { RowFillHeight } from "../../components/RowFillHeight/RowFillHeight";
import { SearchHit } from "../../components/SearchHit/SearchHit.component";
import { SpinnerBox } from "../../components/SpinnerBox/SpinnerBox.component";
import { decomposeReferenceId } from "../../utils/helpers";
import { ListLoadingState } from "../../utils/LoadingState";
import { SearchProps } from "./Search";

const SearchComponent = ({
  history,
  loadingState,
  location,
  match,
  organizations = [],
  fetchSearch,
  searchHistory = [],
  searchResult = [],
  nextLink,
  fetchOrganizations
}: SearchProps) => {
  const [input, setInput] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [collapseHistory, setCollapseHistory] = useState(false);
  const [pageLimit, updateLimit] = useState(0);
  const [pageOffset, updateOffset] = useState(0);
  const [loadMore, updateLoadMore] = useState(false);

  // @ts-ignore
  const compiledSearchResult = [...searchResult, ...(searchResult.xref || [])];

  useEffect(() => {
    const workRefIdParam = match.params.id
      ? decodeURIComponent(match.params.id)
      : "";
    const params = new URLSearchParams(location.search);
    const typeParam = params.get("type") || "";
    setId(workRefIdParam);
    setType(typeParam);
    setInput(workRefIdParam);
    if (workRefIdParam) {
      fetchSearch(typeParam, workRefIdParam, pageLimit, pageOffset, loadMore);
    }
  }, [match.params.id, location.search, pageLimit, pageOffset, loadMore]);

  useEffect(() => {
    if (!organizations.length) {
      fetchOrganizations();
    }
  }, [organizations]);

  const searchTextChange = (event: FormEvent<HTMLInputElement>) => {
    setInput(event.currentTarget.value);
  };

  const searchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    history.push(`/search/${encodeURIComponent(input)}`);
  };

  const formatQueryParam = (queryId: string): string => {
    switch (type) {
      case "work":
        return `&workId=${queryId}`;
      case "recording":
        return `&recordingId=${queryId}`;
      case "batch":
        return `&type=batch`;
      case "import":
      default:
        return "";
    }
  };

  const updateSearchParams = (url: string) => {
    const params = new URLSearchParams(url);
    const limit = Number(params.get("limit"));
    const offset = Number(params.get("offset"));

    updateLoadMore(true);
    updateLimit(limit || 0);
    updateOffset(offset || 0);
  };

  return (
    <FillHeightContainer>
      <Container className="mt-5 b-3">
        <Breadcrumb>
          {id ? (
            <React.Fragment>
              <BreadcrumbItem>
                <Link to={`/search`}>Search</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{id}</BreadcrumbItem>
            </React.Fragment>
          ) : (
            <BreadcrumbItem>Search</BreadcrumbItem>
          )}
        </Breadcrumb>
      </Container>
      <RowFillHeight>
        <Container>
          <RowFillHeight withGutters>
            <ColFillHeight>
              {
                <div>
                  {type !== "import" && (
                    <form
                      onSubmit={searchSubmit}
                      style={{
                        marginBottom: compiledSearchResult.length > 0 ? -1 : 0
                      }}
                    >
                      <InputGroup className="mt-4">
                        <Input
                          placeholder="Use ISWC, ISRC, Society ID, etc."
                          value={input}
                          onChange={searchTextChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color={"primary"}>Search</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  )}
                  <ListGroup className="mt-4">
                    {compiledSearchResult.map((item, index) => (
                      <SearchHit
                        key={item.id}
                        copyId={decomposeReferenceId(item.id).referenceId}
                        organizations={organizations}
                        work={item}
                        queryParam={formatQueryParam(id)}
                      />
                    ))}
                  </ListGroup>
                  {compiledSearchResult.length > 0 && (
                    <div className="d-flex justify-content-center my-3">
                      {nextLink && (
                        <button
                          className={"btn btn-sm btn-outline-secondary"}
                          onClick={() => updateSearchParams(nextLink)}
                        >
                          load more
                        </button>
                      )}
                    </div>
                  )}
                </div>
              }
              {loadingState === ListLoadingState.FETCHED &&
                compiledSearchResult.length === 0 && (
                  <div className="row align-items-center flex-grow-1">
                    <div className="col-6 mx-auto d-flex w-100 justify-content-around">
                      <h3 className="text-muted">No Matches</h3>
                    </div>
                  </div>
                )}
              {loadingState === ListLoadingState.FETCHING && <SpinnerBox />}
            </ColFillHeight>
            <Col sm="12" xs="12" md="5" lg="5" xl="5" className={"mt-4"}>
              {searchHistory && searchHistory.length > 0 && (
                <div className="card">
                  <div className="card-header">Recent Searches</div>
                  <ul className="list-group list-group-flush">
                    {searchHistory.map((item, index) => (
                      <li
                        key={item.id + index}
                        className={`list-group-item  ${
                          index <= 4 || (index > 4 && collapseHistory)
                            ? "d-flex"
                            : "d-none"
                        }`}
                      >
                        <Link
                          className={
                            "w-100 d-flex justify-content-between text-muted"
                          }
                          style={{ lineHeight: 1 }}
                          to={item.link}
                        >
                          <span className={"text-truncate d-inline-block w-50"}>
                            {item.id}
                          </span>
                          <Badge
                            id={`search-item${index}`}
                            style={{ opacity: 0.6 }}
                            className="text-capitalize"
                          >
                            {item.type}
                          </Badge>
                          {item.type === "batch" && (
                            <UncontrolledPopover
                              placement="right"
                              trigger="hover"
                              target={`search-item${index}`}
                            >
                              <PopoverBody>Search for an Import ID</PopoverBody>
                            </UncontrolledPopover>
                          )}
                        </Link>
                      </li>
                    ))}
                    {searchHistory.length > 5 && (
                      <li
                        className="list-group-item text-primary"
                        onClick={() => setCollapseHistory(!collapseHistory)}
                      >
                        {collapseHistory ? "show less..." : "show more..."}
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </Col>
          </RowFillHeight>
        </Container>
      </RowFillHeight>
    </FillHeightContainer>
  );
};

export const Search = withRouter(SearchComponent);
