import { ExportLinksResponse } from "../../api.types";
import { ExportData } from "../../types";
import { Export } from "../../types";
import {
  ExportActionTypes,
  POST_EXPORT,
  POST_EXPORT_FAIL,
  POST_EXPORT_SUCCESS,
  SET_ACTIVE_FILTER_TAB
} from "./Export.action.types";
import {
  FETCH_EXPORT_DETAILS_SUCCESS,
  FETCH_EXPORT_DOWNLOAD_LINK_FAIL,
  FETCH_EXPORTS,
  FETCH_EXPORTS_FAIL,
  FETCH_EXPORTS_SUCCESS,
  SET_PERIODIC_EXPORTS_REFRESH
} from "./Export.action.types";

export const postExportAction = (): ExportActionTypes => ({
  type: POST_EXPORT
});

export const postExportSuccessAction = (
  data: ExportData
): ExportActionTypes => ({
  type: POST_EXPORT_SUCCESS,
  data
});

export const postExportFailAction = (error: string): ExportActionTypes => ({
  type: POST_EXPORT_FAIL,
  error
});

export function fetchExportsSuccessAction(
  exportData: Export[],
  links: ExportLinksResponse
): ExportActionTypes {
  return {
    type: FETCH_EXPORTS_SUCCESS,
    exportData,
    links
  };
}

export function fetchExportsFailAction(error: string): ExportActionTypes {
  return {
    type: FETCH_EXPORTS_FAIL,
    error
  };
}

export function fetchExportsAction(): ExportActionTypes {
  return {
    type: FETCH_EXPORTS
  };
}

export function fetchExportDownloadFailAction(
  error: string
): ExportActionTypes {
  return {
    type: FETCH_EXPORT_DOWNLOAD_LINK_FAIL,
    error
  };
}

export const fetchExportDetailsSuccessAction = (
  exportId: string,
  exportData: Export
): ExportActionTypes => ({
  type: FETCH_EXPORT_DETAILS_SUCCESS,
  exportId,
  exportData
});

export const setPeriodicExportsRefreshAction = (
  intervalId: number | undefined,
  interval?: number
): ExportActionTypes => ({
  type: SET_PERIODIC_EXPORTS_REFRESH,
  intervalId,
  interval
});

export const setActiveFilterTabAction = (tabId: string): ExportActionTypes => ({
  type: SET_ACTIVE_FILTER_TAB,
  tabId
});
