import * as React from "react";
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FileUpload } from "../../components/FileUpload/FileUpload.component";
import { FillHeightContainer } from "../../components/FillHeightContainer/FillHeightContainer";
import { ImportCard } from "../../components/ImportCard/ImportCard.component";
import { ImportsFilter } from "../../components/ImportsFilter/ImportsFilter.component";
import { SpinnerBox } from "../../components/SpinnerBox/SpinnerBox.component";
import { ImportStatus } from "../../types";
import { ListLoadingState } from "../../utils/LoadingState";
import { ImportProps } from "./Import";

interface FilterType {
  namespace?: string;
  status?: ImportStatus;
}

const ImportComponent = ({
  location,
  stopPeriodicRefresh,
  fetchImports,
  fetchOrganizations,
  uploadFiles,
  importsError,
  history,
  user,
  organizations,
  loadingState,
  importsList,
  fetchImportDetails,
  fetchExportByImportId,
  cancelImport,
  nextToken,
  downloadLogFile,
  downloadImport
}: ImportProps) => {
  const [mounted, setMounted] = useState(false);
  const [filterValues, setFilterValues] = useState<FilterType>({});
  const params = new URLSearchParams(location.search);
  const statusUncasted = params.get("status") || undefined;
  const status = statusUncasted
    ? ImportStatus[statusUncasted as keyof typeof ImportStatus]
    : undefined;
  const namespace = params.get("namespace") || undefined;

  useEffect(
    () => () => {
      stopPeriodicRefresh();
    },
    []
  );

  useEffect(() => {
    if (
      (filterValues.status !== status ||
        filterValues.namespace !== namespace) &&
      !mounted
    ) {
      fetchImports();
      setMounted(true);
    }
    if (status == null && namespace == null && !mounted) {
      fetchImports();
      fetchOrganizations();
      setMounted(true);
    }
  });

  const importFiles = (files: File[]) => {
    if (files) {
      uploadFiles(files);
    }
  };

  const onFilterChange = (value: FilterType) => {
    setFilterValues(value);
    formatUrl(value);
  };

  const formatUrl = (value: FilterType) => {
    let path = "";
    if (value.status != null) {
      path = `?status=${value.status}`;
    }
    if (value.namespace != null) {
      path += `${value.status != null ? "&" : "?"}namespace=${value.namespace}`;
    }
    history.push(`/import${path}`);
    fetchImports();
  };

  return (
    <FillHeightContainer className="container">
      <Breadcrumb className="mt-5 mb-5">
        <BreadcrumbItem active>Import</BreadcrumbItem>
      </Breadcrumb>
      {user && user.permissions && user.permissions.upload && (
        <FileUpload importFiles={importFiles} className={"mb-3"} />
      )}
      <ImportsFilter
        onValueChange={value => onFilterChange(value)}
        values={{ status, namespace }}
        organizations={organizations}
      />
      {importsList &&
        importsList.map(importItem => {
          return (
            <ImportCard
              key={importItem.importId}
              importItem={importItem}
              history={history}
              loadDetails={() => fetchImportDetails(importItem.importId)}
              fetchExportByImportId={fetchExportByImportId}
              downloadLogFile={downloadLogFile}
              cancelImport={cancelImport}
              organizations={organizations}
              downloadImport={downloadImport}
            />
          );
        })}
      {loadingState ===
        (ListLoadingState.FETCHING || ListLoadingState.FETCHING_MORE) && (
        <SpinnerBox />
      )}
      {!importsError &&
        nextToken &&
        [ListLoadingState.FETCHED, ListLoadingState.REFRESHING].indexOf(
          loadingState
        ) !== -1 && (
          <div className={"d-block mx-auto my-3"}>
            <button
              data-test-id="load-more-button"
              className={"btn btn-sm btn-outline-secondary"}
              onClick={() => fetchImports(true)}
            >
              {" "}
              load more
            </button>
          </div>
        )}
    </FillHeightContainer>
  );
};

export const ImportView = withRouter(ImportComponent);
