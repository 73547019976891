import axios, { AxiosPromise, AxiosResponse } from "axios";
import { push } from "react-router-redux";
import { Store } from "redux";
import { apiUrls, protectedUrls } from "./api.config";
import {
  InterceptRequestResponse,
  PasswordManageResponse,
  SigningResponse
} from "./api.types";
import { refreshSuccessAction } from "./containers/Signin/Signin.actions";

export interface LoginData {
  password: string;
  email: string;
}

export interface ResetConfirm {
  confirmationCode: string;
  password: string;
}

export function signin(credentials: LoginData): AxiosPromise {
  return axios.post<SigningResponse>(apiUrls.auth, JSON.stringify(credentials));
}

export function passwordReset(email: string): AxiosPromise {
  return axios.post<PasswordManageResponse>(`${apiUrls.auth}/password/forgot`, {
    email
  });
}

export function resetConfirm(
  confirmData: ResetConfirm,
  email: string
): AxiosPromise {
  return axios.post<PasswordManageResponse>(
    `${apiUrls.auth}/password/forgot/confirm`,
    JSON.stringify({
      ...confirmData,
      email
    })
  );
}

export function respondToChallenge(
  challenge: any,
  password: string
): AxiosPromise {
  return axios({
    method: challenge.method,
    url: `${apiUrls.auth}${challenge.href}`,
    data: {
      challenge: challenge.name,
      username: challenge.username,
      password
    },
    headers: { ...challenge.headers, "Content-Type": "application/json" }
  });
}

export const createAuthInterceptor = (store: Store) => {
  function isSecured(url: string): boolean {
    return protectedUrls.some((cur: string) => url.startsWith(cur));
  }
  axios.interceptors.request.use((config: any) => {
    const authToken = store.getState().signin.authTokens.authToken;
    if (authToken !== "" && isSecured(config.url || "")) {
      config.headers.Authorization = authToken;
    }
    return config;
  });
};

export const createRefreshInterceptor = (store: Store) => {
  let refreshing = false;
  let refreshSubscribers: any[] = [];
  const addSubscriber = (response: (token: string) => void) => {
    refreshSubscribers.push(response);
  };
  const onRefreshed = (token: string) => {
    refreshSubscribers.forEach((cb: (token: string) => void) => cb(token));
  };
  axios.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (errorResponse: any) => {
      // Reject promise if usual error
      if (
        !errorResponse ||
        !errorResponse.response ||
        [401, 403].indexOf(errorResponse.response.status) === -1
      ) {
        return Promise.reject(errorResponse);
      }

      if (!refreshing) {
        refreshing = true;
        axios
          .post<InterceptRequestResponse>(apiUrls.refresh, null, {
            headers: {
              Authorization: store.getState().signin.authTokens.refreshToken,
              "Content-Type": "application/json"
            }
          })
          .then((response: AxiosResponse<InterceptRequestResponse>) => {
            const { tokens } = response.data.response;
            store.dispatch(refreshSuccessAction(tokens));
            refreshing = false;
            onRefreshed(tokens.idToken);
            refreshSubscribers = [];
          })
          .catch((error: any) => {
            store.dispatch(push("/signin"));
            return Promise.reject(error);
          });
      }
      return new Promise((resolve: any) => {
        addSubscriber((token: string) => {
          // replace the expired token and retry
          resolve(
            axios({
              ...errorResponse.response.config,
              headers: {
                ...errorResponse.response.config.headers,
                Authorization: token
              }
            })
          );
        });
      });
    }
  );
};
