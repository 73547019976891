import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  ListGroupItem,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap";
import { InterestedParty, Organization, WorkRef } from "../../types";
import {
  decomposeReferenceId,
  resolveOrganizationName
} from "../../utils/helpers";
import "../styles.css";

import { CopyComponent } from "../Copy/Copy.component";
import { WorkBadge } from "../WorkBadge/WorkBadge.component";

interface InterestedPartyProps {
  party: InterestedParty;
  works?: WorkRef[];
  organizations: Organization[];
  copyId: string;
  index: string | number;
}

export const InterestedPartyComponent = ({
  party,
  works,
  organizations,
  copyId,
  index
}: InterestedPartyProps) => {
  const [isCopyVisible, setIsCopyVisible] = useState(false);
  return (
    <ListGroupItem
      onMouseOver={() => setIsCopyVisible(true)}
      onMouseOut={() => setIsCopyVisible(false)}
    >
      <div className="d-flex w-100 justify-content-between">
        <span>{party.name || "unknown"}</span>
        <span>
          <small style={{ opacity: 0.5 }}>
            {moment.unix(party.timestamp).format("MM/DD/YYYY h:ss a")}
          </small>
          {party.works && party.works.length && (
            <span className="ml-1">
              <WorkBadge id={party.name} works={party.works} />
            </span>
          )}
        </span>
      </div>
      <div
        style={{ opacity: 0.6 }}
        className="d-flex w-100 justify-content-between"
      >
        <span>
          <small className="mr-1">IPI</small>
          <small className="mr-1">{party.ipiNameNumber}</small>
          <CopyComponent isVisible={isCopyVisible} id={party.ipiNameNumber} />
        </span>
        {party.in && (
          <React.Fragment>
            <span>
              <Badge
                color="secondary"
                id={"party" + index + party.ipiNameNumber.replace(/\W/g, "")}
              >
                {party.in.length} Work
                {party.in.length > 1 && "s"}
              </Badge>
            </span>
            <UncontrolledPopover
              className="popOver"
              placement="right"
              trigger="hover"
              target={"party" + index + party.ipiNameNumber.replace(/\W/g, "")}
            >
              <PopoverBody>
                {party.in
                  .map(
                    partyId =>
                      works &&
                      works.find(
                        // tslint:disable-next-line: no-shadowed-variable
                        ({ id }) => id === partyId.work
                      )
                  )
                  .map(
                    ref =>
                      ref && (
                        <span key={ref.id} className="d-block">
                          <Link to={`/works/${ref.id}`} className="mr-2">
                            {ref.title
                              ? `${ref.title}`
                              : decomposeReferenceId(ref.id).referenceId}
                          </Link>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            {resolveOrganizationName(
                              organizations,
                              decomposeReferenceId(ref.id).CMO || ""
                            )}
                          </Badge>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            {ref.dataAuthority
                              ? `Authority ${ref.dataAuthority}`
                              : ``}
                          </Badge>
                        </span>
                      )
                  )}
              </PopoverBody>
            </UncontrolledPopover>
          </React.Fragment>
        )}
      </div>
    </ListGroupItem>
  );
};
