import {
  ADD_ALERT,
  Alert,
  AlertActionTypes,
  REMOVE_ALERT
} from "./Alerts.action.types";

export interface AlertsState {
  alerts: Alert[];
}

export const initialState: AlertsState = {
  alerts: []
};

export const alertReducer = (
  state = initialState,
  action: AlertActionTypes
): AlertsState => {
  switch (action.type) {
    case ADD_ALERT: {
      return {
        alerts: [
          ...state.alerts,
          { type: action.alertType, message: action.message }
        ]
      };
    }

    case REMOVE_ALERT: {
      return {
        alerts: state.alerts.filter((el, index) => index !== action.index)
      };
    }
    default:
      return state;
  }
};
