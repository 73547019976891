import { Work } from "../../types";
import { LoadingState } from "../../utils/LoadingState";
import {
  AggregatedActionTypes,
  FETCH_AGGREGATED,
  FETCH_AGGREGATED_FAIL,
  FETCH_AGGREGATED_SUCCESS,
  FETCH_RECORDING_GROUP_AGGREGATED,
  FETCH_RECORDING_GROUP_AGGREGATED_FAIL,
  FETCH_RECORDING_GROUP_AGGREGATED_SUCCESS,
  SET_RECORDING_GROUP_IS_OPEN
} from "./Aggregated.action.types";

export interface AggregatedState {
  id?: string;
  data?: Work;
  error?: string;
  errorMsg: string;
  statusCode?: number;
  loadingState: LoadingState;
}

export const initialState: AggregatedState = {
  errorMsg: "",
  loadingState: LoadingState.PRISTINE
};

export const aggregatedReducer = (
  state: AggregatedState = initialState,
  action: AggregatedActionTypes
): AggregatedState => {
  switch (action.type) {
    case FETCH_AGGREGATED:
      return Object.assign({}, state, {
        ...state,
        data: undefined,
        id: action.id,
        errorMsg: "",
        statusCode: undefined,
        loadingState: LoadingState.FETCHING
      });
    case FETCH_AGGREGATED_FAIL:
      return Object.assign({}, state, {
        ...state,
        loadingState: LoadingState.FAILED,
        errorMsg: action.error,
        statusCode: action.statusCode
      });
    case FETCH_AGGREGATED_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        data: action.aggregated,
        loadingState: LoadingState.FETCHED
      });
    case FETCH_RECORDING_GROUP_AGGREGATED:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            loadingState: {
              ...state.data.RecordingGroups.loadingState,
              [action.recordingGroup]: LoadingState.FETCHING
            }
          }
        },
        statusCode: undefined
      };
    case SET_RECORDING_GROUP_IS_OPEN:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            isOpen: {
              ...state.data.RecordingGroups.isOpen,
              [action.recordingGroup]: action.isOpen
            }
          }
        }
      };
    case FETCH_RECORDING_GROUP_AGGREGATED_SUCCESS:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            pagination: {
              ...state.data.RecordingGroups.groups,
              // TODO Use counts once present in API
              [action.recordingGroup]: action.isLastPage // workaround til counts are available
                ? (
                    (state.data.RecordingGroups.recordings || {})[
                      action.recordingGroup
                    ] || []
                  ).length + action.recordings.length // set to total to actual length to indicate that there isn't more
                : (
                    (state.data.RecordingGroups.recordings || {})[
                      action.recordingGroup
                    ] || []
                  ).length +
                  action.recordings.length +
                  1 // one more to indicate that there is more to fetch
            },
            recordings: {
              ...state.data.RecordingGroups.recordings,
              [action.recordingGroup]: [
                ...((state.data.RecordingGroups.recordings || {})[
                  action.recordingGroup
                ] || []),
                ...action.recordings
              ]
            },
            loadingState: {
              ...state.data.RecordingGroups.loadingState,
              [action.recordingGroup]: LoadingState.FETCHED
            },
            isOpen: {
              ...state.data.RecordingGroups.isOpen,
              [action.recordingGroup]: true
            }
          }
        },
        error: undefined
      };
    case FETCH_RECORDING_GROUP_AGGREGATED_FAIL:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            loadingState: { [action.recordingGroup]: LoadingState.FAILED }
          }
        },
        error: action.error,
        statusCode: action.statusCode
      };
    default:
      return state;
  }
};
