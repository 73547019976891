import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers, Reducer } from "redux";
import { aggregatedReducer } from "./containers/Aggregated/Aggregated.reducer";
import { alertReducer } from "./containers/Alerts/Alerts.reducer";
import { detailReducer } from "./containers/Detail/Detail.reducer";
import { exportReducer } from "./containers/Export/Export.reducer";
import { importReducer } from "./containers/Import/Import.reducer";
import { metricsReducer } from "./containers/Metrics/Metrics.reducer";
import { searchReducer } from "./containers/Search/Search.reducer";
import { signinReducer } from "./containers/Signin/Signin.reducer";
import { withPersistence } from "./utils/withPersistence";

export const rootReducers = {
  aggregated: aggregatedReducer,
  signin: signinReducer,
  detail: detailReducer,
  search: searchReducer,
  imports: importReducer,
  metrics: metricsReducer,
  export: exportReducer,
  alerts: alertReducer
};

export const createRootReducer = (history: History): Reducer =>
  withPersistence(
    combineReducers({
      ...rootReducers,
      router: connectRouter(history)
    })
  );

const rootReducer = combineReducers(rootReducers);

export type AppState = ReturnType<typeof rootReducer>;
