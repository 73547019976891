import { Action, Reducer } from "redux";
import {
  LOGIN_STATE,
  REFRESH_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "../containers/Signin/Signin.action.types";
import { AppState } from "./../App.reducer";
import { getAuth, setAuth } from "./storageManager";

export const withPersistence = (reducer: Reducer): Reducer => {
  return (state: AppState, action: Action): Reducer => {
    const nextState = reducer(state, action);
    if (typeof state === "undefined") {
      const auth = getAuth();
      return {
        ...nextState,
        signin: auth ? JSON.parse(auth) : nextState.signin
      };
    } else {
      if (
        [
          USER_LOGIN_SUCCESS,
          REFRESH_SUCCESS,
          USER_LOGIN_FAILURE,
          USER_LOGOUT,
          LOGIN_STATE
        ].indexOf(action.type) !== -1
      ) {
        setAuth(JSON.stringify(nextState.signin));
      }
      return nextState;
    }
  };
};
