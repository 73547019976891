import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Popover, PopoverBody } from "reactstrap";

interface Props {
  id: string;
  isVisible?: boolean;
}

export const CopyComponent = ({ id, isVisible }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const linkId = `tooltipTarget-${id.replace(/\W/g, "")}`;

  const handleClick = () => {
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 1000);
  };
  return (
    <Fragment>
      <span
        style={{ zIndex: 2 }}
        className={isVisible ? `d-inline` : `d-none`}
        onClick={e => e.preventDefault()}
      >
        <span id={linkId}>
          <CopyToClipboard text={id} onCopy={handleClick}>
            <FontAwesomeIcon color="#ababab" icon={faCopy} size="xs" />
          </CopyToClipboard>
        </span>
        <Popover placement="bottom" isOpen={isOpen} target={linkId}>
          <PopoverBody>copied</PopoverBody>
        </Popover>
      </span>
    </Fragment>
  );
};
