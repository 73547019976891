import { Metrics } from "../../types";

export const FETCH_METRICS_SUCCESS = "FETCH_METRICS_SUCCESS";
export const FETCH_METRICS_FAIL = "FETCH_METRICS_FAIL";
export const FETCH_METRICS = "FETCH_METRICS";

interface FetchMetricsSuccessAction {
  type: typeof FETCH_METRICS_SUCCESS;
  data: Metrics;
}

interface FetchMetricsFailAction {
  type: typeof FETCH_METRICS_FAIL;
  error: string;
}

interface FetchMetricsAction {
  type: typeof FETCH_METRICS;
}

export type MetricsActionTypes =
  | FetchMetricsSuccessAction
  | FetchMetricsFailAction
  | FetchMetricsAction;
