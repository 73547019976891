import * as React from "react";
import { useState } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";

import { ResetConfirm } from "../../auth";

interface Props {
  onConfirm: (e: React.FormEvent, confirm: ResetConfirm) => void;
  returnToLogin: () => void;
}

export const PasswordResetConfirmForm = ({
  onConfirm,
  returnToLogin
}: Props) => {
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmCode] = useState("");

  return (
    <Form
      style={{ minWidth: "430px" }}
      className={"p-5"}
      onSubmit={e => onConfirm(e, { confirmationCode, password })}
    >
      <h4 className={"mb-5"}>Confirm Password Reset</h4>
      <FormGroup>
        <Input
          placeholder={"Confirm Code"}
          value={confirmationCode}
          type={"text"}
          bsSize="lg"
          color={"info"}
          onChange={e => {
            setConfirmCode(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Input
          placeholder={"New Password"}
          value={password}
          type={"password"}
          bsSize="lg"
          color={"info"}
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Button type={"submit"} color="info" size="lg" block>
          Confirm
        </Button>
      </FormGroup>
      <FormGroup>
        <span className={"forgot-password"} onClick={returnToLogin}>
          Return to Sign In
        </span>
      </FormGroup>
    </Form>
  );
};
