import { User } from "../../Auth.actions";
import {
  ChallengeResponse,
  HANDLE_CHALLENGE,
  LOGIN_STATE,
  LoginStates,
  PASSWORD_RESET_CONFIRM_FAILURE,
  PASSWORD_RESET_FAILURE,
  REFRESH_SUCCESS,
  SET_EMAIL,
  SET_NEW_PASSWORD_FAILURE,
  SET_REDIRECT,
  SigninActionTypes,
  Tokens,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "./Signin.action.types";

export const signinSuccessAction = (
  user: User,
  tokens: Tokens
): SigninActionTypes => ({
  type: USER_LOGIN_SUCCESS,
  user,
  tokens
});

export const refreshSuccessAction = (tokens: Tokens): SigninActionTypes => ({
  type: REFRESH_SUCCESS,
  tokens
});

export const signinFailureAction = (error: string): SigninActionTypes => ({
  type: USER_LOGIN_FAILURE,
  error
});

export const signinStateManageAction = (
  signinState: LoginStates
): SigninActionTypes => ({
  type: LOGIN_STATE,
  signinState
});

export const resetFailureAction = (error: string): SigninActionTypes => ({
  type: PASSWORD_RESET_FAILURE,
  error
});

export const resetConfirmFailureAction = (
  error: string
): SigninActionTypes => ({
  type: PASSWORD_RESET_CONFIRM_FAILURE,
  error
});

export const setEmailAction = (email: string): SigninActionTypes => ({
  type: SET_EMAIL,
  email
});

export const setRedirectAction = (path: string): SigninActionTypes => ({
  type: SET_REDIRECT,
  path
});

export const handleChallengeAction = (
  challenge: ChallengeResponse
): SigninActionTypes => ({
  type: HANDLE_CHALLENGE,
  challenge
});

export const setNewPasswordFailureAction = (
  error: string
): SigninActionTypes => ({
  type: SET_NEW_PASSWORD_FAILURE,
  error
});

export const signOutAction = (): SigninActionTypes => ({
  type: USER_LOGOUT
});
