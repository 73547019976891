export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export type AlertTypes = "success" | "danger" | "warning";

export interface Alert {
  type: AlertTypes;
  message: string;
}

interface AddAlertAction {
  type: typeof ADD_ALERT;
  alertType: AlertTypes;
  message: string;
}

interface RemoveAlertAction {
  type: typeof REMOVE_ALERT;
  index: number;
}

export type AlertActionTypes = AddAlertAction | RemoveAlertAction;
