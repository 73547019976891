import * as React from "react";
import { useState } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";

interface Props {
  handleReset: (e: React.FormEvent, email: string) => void;
  returnToLogin: () => void;
}

export const PasswordResetForm = ({ handleReset, returnToLogin }: Props) => {
  const [email, setEmail] = useState("");
  return (
    <Form
      style={{ minWidth: "430px" }}
      className={"p-5"}
      onSubmit={e => handleReset(e, email)}
    >
      <h4 className={"mb-5"}>Reset Password</h4>
      <FormGroup>
        <Input
          placeholder={"E-mail"}
          value={email}
          type={"text"}
          bsSize="lg"
          color={"info"}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Button type={"submit"} color="info" size="lg" block>
          Reset
        </Button>
      </FormGroup>
      <FormGroup>
        <span className={"forgot-password"} onClick={() => returnToLogin()}>
          Return to Sign In
        </span>
      </FormGroup>
    </Form>
  );
};
