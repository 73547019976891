import { FieldArray } from "formik";
import * as React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Definitions } from "../../utils/Definitions";
import { InfoIconComponent } from "../InfoIcon/InfoIcon.component";

interface CheckboxField {
  id: string;
  name: string;
}

interface Props {
  checkboxFields: CheckboxField[];
  checkboxValues: string[];
  disabled: boolean;
}

export const CheckboxGroup = ({
  checkboxFields,
  checkboxValues,
  disabled
}: Props) => (
  <FieldArray
    name="linkClassification"
    render={arrayHelpers => (
      <div>
        {checkboxFields.map(checkboxField => (
          <div key={checkboxField.id}>
            <FormGroup check>
              <Label check>
                <Input
                  disabled={disabled}
                  name="linkClassification"
                  type="checkbox"
                  id={checkboxField.id}
                  value={checkboxField.id}
                  checked={checkboxValues.includes(checkboxField.id)}
                  onChange={e => {
                    if (e.target.checked) {
                      arrayHelpers.push(checkboxField.id);
                    } else {
                      const idx = checkboxValues.indexOf(checkboxField.id);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />{" "}
                {checkboxField.name}
                <InfoIconComponent
                  id={`info-${checkboxField.id.replace(/\W/g, "")}`}
                  infoText={
                    Definitions[
                      checkboxField.id.replace(
                        /\W/g,
                        ""
                      ) as keyof typeof Definitions
                    ]
                  }
                />
              </Label>
            </FormGroup>
          </div>
        ))}
      </div>
    )}
  />
);
