import * as React from "react";
import { useState } from "react";
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input
} from "reactstrap";

interface Props {
  errorMessage: string;
  resetPasswordState: (data: boolean) => void;
  handleSubmit: (
    e: React.FormEvent,
    data: { email: string; password: string; rememberMe: boolean }
  ) => void;
}

export const SigninForm = ({
  handleSubmit,
  errorMessage,
  resetPasswordState
}: Props) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  return (
    <Form
      data-test-id={"signin-form"}
      style={{ minWidth: "430px" }}
      className={"p-5"}
      onSubmit={e => handleSubmit(e, { email, password, rememberMe })}
    >
      <h4 className={"mb-5"}>Sign in to ELIXIR</h4>
      <FormGroup>
        <Input
          data-test-id={"signin-email"}
          invalid={!!errorMessage}
          placeholder={"Username"}
          value={email}
          type={"text"}
          bsSize="lg"
          color={"info"}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Input
          data-test-id={"signin-password"}
          invalid={!!errorMessage}
          placeholder={"Password"}
          type={"password"}
          bsSize="lg"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
        {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Button
          type={"submit"}
          disabled={password === "" || email === ""}
          color="primary"
          size="lg"
          block
        >
          Sign in
        </Button>
      </FormGroup>

      <FormGroup>
        <div className="d-inline-flex justify-content-between w-100">
          <CustomInput
            label="Remember Me"
            id="remember-me"
            type="checkbox"
            className="pb-2"
            checked={rememberMe}
            onChange={() => {
              setRememberMe(!rememberMe);
            }}
          />

          <span
            className={"forgot-password"}
            onClick={() => {
              resetPasswordState(true);
            }}
          >
            Forgot Password?
          </span>
        </div>
      </FormGroup>
    </Form>
  );
};
