import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ListGroupItem } from "reactstrap";

import { Organization, WorkRef } from "../../types";
import {
  decomposeReferenceId,
  displayItemsWithPipe,
  resolveOrganizationName
} from "../../utils/helpers";
import { CopyComponent } from "../Copy/Copy.component";

interface Props {
  work: WorkRef;
  organizations: Organization[];
  linkActive?: boolean;
}

export const SourceWorkComponent = ({
  work: { id, sourceId, sourceIdType },
  linkActive,
  organizations
}: Props) => {
  const { CMO, referenceId } = decomposeReferenceId(id);
  const submitter = resolveOrganizationName(organizations, CMO);
  const type = resolveOrganizationName(organizations, sourceIdType);
  const [isCopyVisible, setIsCopyVisible] = useState(false);
  return (
    <ListGroupItem
      data-test-id={"source-work-item"}
      tag={Link}
      action
      active={linkActive}
      to={`/works?sourceWorkId=${encodeURIComponent(id)}`}
      onMouseOver={() => setIsCopyVisible(true)}
      onMouseOut={() => setIsCopyVisible(false)}
    >
      <div className="d-flex justify-content-between">
        <small style={{ opacity: 0.5 }}>
          {displayItemsWithPipe(submitter, type && `Type ${type} ID`)}
        </small>
      </div>
      <span className="text-monospace position-relative">
        {sourceId} <CopyComponent isVisible={isCopyVisible} id={referenceId} />
      </span>
    </ListGroupItem>
  );
};
