import { Work } from "../../types";
import { LoadingState } from "../../utils/LoadingState";
import {
  DetailActionTypes,
  FETCH_DETAIL,
  FETCH_DETAIL_FAIL,
  FETCH_DETAIL_SUCCESS,
  FETCH_RECORDING_GROUP,
  FETCH_RECORDING_GROUP_FAIL,
  FETCH_RECORDING_GROUP_SUCCESS,
  SET_RECORDING_GROUP_IS_OPEN
} from "./Detail.action.types";

export interface DetailState {
  loadingState: LoadingState;
  data?: Work;
  errorMsg?: string;
  id?: string;
  statusCode?: number;
}

export const initialState: DetailState = {
  loadingState: LoadingState.PRISTINE
};

export const detailReducer = (
  state: DetailState = initialState,
  action: DetailActionTypes
): DetailState => {
  switch (action.type) {
    case FETCH_DETAIL: {
      return {
        ...state,
        id: action.id,
        data: undefined,
        errorMsg: undefined,
        statusCode: undefined,
        loadingState: LoadingState.FETCHING
      };
    }
    case FETCH_DETAIL_FAIL: {
      return {
        ...state,
        data: undefined,
        id: undefined,
        loadingState: LoadingState.FAILED,
        errorMsg: action.error,
        statusCode: action.statusCode
      };
    }
    case FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.work,
        loadingState: LoadingState.FETCHED
      };
    case FETCH_RECORDING_GROUP:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            loadingState: {
              ...state.data.RecordingGroups.loadingState,
              [action.recordingGroup]: LoadingState.FETCHING
            }
          }
        }
      };
    case SET_RECORDING_GROUP_IS_OPEN:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            isOpen: {
              ...state.data.RecordingGroups.isOpen,
              [action.recordingGroup]: action.isOpen
            }
          }
        }
      };
    case FETCH_RECORDING_GROUP_SUCCESS:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            recordings: {
              ...state.data.RecordingGroups.recordings,
              [action.recordingGroup]: [
                ...((state.data.RecordingGroups.recordings || {})[
                  action.recordingGroup
                ] || []),
                ...action.recordings
              ]
            },
            loadingState: {
              ...state.data.RecordingGroups.loadingState,
              [action.recordingGroup]: LoadingState.FETCHED
            },
            isOpen: {
              ...state.data.RecordingGroups.isOpen,
              [action.recordingGroup]: true
            }
          }
        },
        errorMsg: undefined
      };
    case FETCH_RECORDING_GROUP_FAIL:
      return {
        ...state,
        data: state.data && {
          ...state.data,
          RecordingGroups: {
            ...state.data.RecordingGroups,
            loadingState: { [action.recordingGroup]: LoadingState.FAILED }
          }
        },
        errorMsg: action.error
      };
    default:
      return state;
  }
};
