import * as React from "react";
import { Row } from "reactstrap";

interface Props {
  withGutters?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const RowFillHeight = ({ withGutters, className, children }: Props) => (
  <Row
    className={`h-100${!withGutters ? " no-gutters" : " "}${
      className ? " " + className : ""
    }`}
  >
    {children}
  </Row>
);
