import { Location } from "history";
import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";

interface Props extends RouteComponentProps {
  children: JSX.Element;
  location: Location;
}

const ScrollToTopComponent = ({ children, location }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

export const ScrollToTop = withRouter(ScrollToTopComponent);
