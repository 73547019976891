import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import { Organization, Work } from "../../types";
import {
  decomposeReferenceId,
  resolveOrganizationName
} from "../../utils/helpers";
import { CopyComponent } from "../Copy/Copy.component";

interface Props {
  queryParam: string;
  work: Work;
  organizations: Organization[];
  copyId: string;
}

export const SearchHit = ({
  queryParam,
  work,
  organizations,
  copyId
}: Props) => {
  const { referenceId, CMO } = decomposeReferenceId(work.id);
  const submitter = resolveOrganizationName(organizations, CMO);
  const [isCopyVisible, setIsCopyVisible] = useState(false);
  const splitId = work.id.split("::");
  const localId = splitId && splitId.length > 1 && splitId[1];
  return (
    <ListGroupItem
      onMouseOver={() => setIsCopyVisible(true)}
      onMouseOut={() => setIsCopyVisible(false)}
      tag={Link}
      to={`/aggregated-works/${localId}${queryParam ? "?" + queryParam : ""}`}
    >
      <div className="d-flex w-100 justify-content-between">
        <small style={{ opacity: 0.5 }}>
          <span>
            {submitter && `${submitter} | `}
            {work.title || "unknown"}
          </span>
        </small>
        <small className="text-muted">
          Imported {moment.unix(work.timestamp).format("MM/DD/YYYY h:ss a")}
        </small>
      </div>
      <div className="d-flex mt-3 no-padding row">
        {work.featuredArtists &&
          work.featuredArtists.map((artist, key) => {
            return (
              <small
                key={artist + key}
                className=" col-3 text-truncate text-muted"
              >
                {artist}
              </small>
            );
          })}
      </div>
      <div className="d-flex row no-padding">
        {work.collaborators &&
          work.collaborators.map((collaborator, index) => {
            return (
              <small
                key={collaborator + index}
                className="col-12 col-md-3 text-muted mr-3 text-truncate"
              >
                {collaborator}
              </small>
            );
          })}
      </div>
      <div className="d-flex">
        <span className="text-monospace position-relative">
          {referenceId} <CopyComponent isVisible={isCopyVisible} id={copyId} />
        </span>
      </div>
    </ListGroupItem>
  );
};
