import {
  faBan,
  faCheck,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import humanizeString from "humanize-string";
import moment from "moment";
import React from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledButtonDropdown
} from "reactstrap";
import { DownloadType, Export, ExportStatus, Organization } from "../../types";
import { Definitions } from "../../utils/Definitions";
import { isExportStatusLoading } from "../../utils/helpers";
import { IconCard } from "../IconCard/IconCard.component";
import { InfoIconComponent } from "../InfoIcon/InfoIcon.component";
import { Modal } from "../Modal/Modal.component";

const getOrganizationName = (
  organizations: Organization[],
  exportItem: Export
): string => {
  const org = organizations.find(
    ({ id }: Organization) => exportItem.filters.organizationId === id
  );
  return (org && org.name) || "";
};

interface Props {
  exportItem: Export;
  getDownloadLink: (id: string, type: string) => any;
  organizations: Organization[];
  cancelExport: (id: string) => void;
}

export const ExportCard = ({
  exportItem,
  getDownloadLink,
  organizations,
  cancelExport
}: Props): JSX.Element => {
  const statusIcon = (status: ExportStatus) => {
    switch (status) {
      case ExportStatus.REQUESTED:
      case ExportStatus.PROCESSING:
      case ExportStatus.PREPARING:
      case ExportStatus.TRANSFORMING:
        return <Spinner size="sm" color="primary" />;
      case ExportStatus.FAILED:
        return <FontAwesomeIcon icon={faTimes} color="#D0021B" />;
      case ExportStatus.COMPLETED:
        return <FontAwesomeIcon icon={faCheck} color="#4B91E2" />;
      case ExportStatus.CANCELLED:
        return <FontAwesomeIcon icon={faBan} color="#ffc107" />;
      default:
        return <FontAwesomeIcon icon={faSpinner} color="#4B91E2" />;
    }
  };

  const loading = isExportStatusLoading(exportItem.status);
  const createDownloadFile = (href: string) => {
    const link = document.createElement("a");
    link.href = href;
    link.download = "download";
    link.target = "_blank";
    link.click();
  };
  const normalizeExportType = (type: string) => {
    const humanized = humanizeString(type);
    const splited = humanized.split(" ");
    if (splited[1] === "iswc") {
      return `${splited[0]} ${splited[1].toUpperCase()}`;
    }
    return humanized;
  };

  return (
    <IconCard id={exportItem.exportId} icon={statusIcon(exportItem.status)}>
      <Row data-test-id={"export-card"}>
        <Col>
          <h5>
            <span>{`${
              loading ? `Creating Export` : exportItem.statusReason
            }`}</span>
          </h5>
        </Col>
        <Col sm="auto" className="text-md-right text-sm-left">
          <small className="text-muted">
            {moment.unix(exportItem.created).format("MM/DD/YYYY h:mm:ss a")}
          </small>
          <div>
            <small className={"text-muted"} style={{ opacity: 0.5 }}>
              {exportItem.exportId}
            </small>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {exportItem.filters && (
            <small>
              {exportItem.filters.fromDate && exportItem.filters.toDate && (
                <div className={"mb-1"}>
                  <span className="text-muted">
                    From{" "}
                    {moment
                      .unix(Number(exportItem.filters.fromDate))
                      .format("MM/DD/YYYY")}{" "}
                    to{" "}
                    {moment
                      .unix(Number(exportItem.filters.toDate))
                      .format("MM/DD/YYYY")}
                  </span>
                </div>
              )}
              {exportItem.filters.importId && (
                <div className={"mb-1"}>
                  <span className="text-muted">
                    Import Id {exportItem.filters.importId}
                  </span>
                </div>
              )}
              {exportItem.filters.nameIPI && (
                <div className={"mb-1"}>
                  <span className="text-muted">
                    IPI Name Number {exportItem.filters.nameIPI}
                  </span>
                </div>
              )}
              {exportItem.filters.linkClassification && (
                <ul className="list-inline text-muted mb-1">
                  Confidence:{" "}
                  {exportItem.filters.linkClassification.map(classification => (
                    <li
                      key={classification}
                      id={`${classification}-${exportItem.exportId}`}
                      className="text-capitalize list-inline-item"
                    >
                      {classification}
                      <InfoIconComponent
                        id={`${classification}-${exportItem.exportId}`}
                        infoText={
                          Definitions[
                            classification as keyof typeof Definitions
                          ]
                        }
                      />
                    </li>
                  ))}
                </ul>
              )}
              <div className={"mb-1"}>
                <span className="text-muted text-capitalize">
                  Conflicts:{" "}
                  {exportItem.filters.conflicts
                    ? exportItem.filters.conflicts
                    : "Include"}
                </span>
              </div>
              {exportItem.filters.exportType && (
                <div className={"mb-1"}>
                  <span className="text-muted text-capitalize">
                    Export type:{" "}
                    {normalizeExportType(exportItem.filters.exportType)}
                  </span>
                </div>
              )}
              {exportItem.filters.organizationId && (
                <div className={"mb-1"}>
                  <span className="text-muted text-capitalize">
                    Organization:{" "}
                    {getOrganizationName(organizations, exportItem)}
                  </span>
                </div>
              )}
            </small>
          )}
        </Col>
        {!exportItem.downloadable && !loading && (
          <Col className="d-flex align-items-end">
            <div className="ml-auto small text-warning">
              Export Contains no Data
            </div>
          </Col>
        )}
        {(exportItem.downloadable || loading) && (
          <Col className="d-flex flex-column">
            <div className="mt-auto ml-auto">
              {loading && (
                <Modal
                  onConfirm={(): void => cancelExport(exportItem.exportId)}
                  title="Cancel an Export"
                  message="Cancelling an export will stop current progress. Any changes already made will not be rolled back."
                >
                  <Button outline color="danger" size="sm">
                    Cancel
                  </Button>
                </Modal>
              )}
              {exportItem.downloadable && (
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="primary" size="sm" caret>
                    Download
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      data-test-id={"download-export-json"}
                      onClick={() => {
                        getDownloadLink(
                          exportItem.exportId,
                          DownloadType.JSON
                        ).then((link: string) => createDownloadFile(link));
                      }}
                    >
                      JSON
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        getDownloadLink(
                          exportItem.exportId,
                          DownloadType.CSV
                        ).then((link: string) => createDownloadFile(link))
                      }
                    >
                      CSV
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}
            </div>
          </Col>
        )}
      </Row>
    </IconCard>
  );
};
