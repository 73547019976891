import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { Batch, Import } from "../../types";
import { LoadingState } from "../../utils/LoadingState";

interface Props {
  importItem: Import;
  loadDetails: () => void;
  viewBatchList: (batch?: Batch) => void;
}

export const ImportListButton = ({
  importItem,
  loadDetails,
  viewBatchList
}: Props) => {
  const [waitingForDetails, setWaitingForDetails] = useState(false);

  function triggerViewBatchList(batch: Batch) {
    setWaitingForDetails(false);
    viewBatchList(batch);
  }

  useEffect(() => {
    if (
      importItem.batches &&
      importItem._loadingStateDetails === LoadingState.FETCHED &&
      waitingForDetails
    ) {
      triggerViewBatchList(importItem.batches[0]);
    }
  }, [importItem._loadingStateDetails, waitingForDetails]);

  return (
    <Button
      className="ml-2"
      data-test-id={"list-import-works"}
      disabled={importItem._loadingStateDetails === LoadingState.FETCHING}
      color="primary"
      size="sm"
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (
          importItem._loadingStateDetails === LoadingState.FETCHED &&
          importItem.batches &&
          importItem.batches.length > 0
        ) {
          viewBatchList(importItem.batches[0]);
        } else {
          setWaitingForDetails(true);
          loadDetails();
        }
      }}
    >
      List Import Works{" "}
      {importItem._loadingStateDetails === LoadingState.FETCHING &&
        waitingForDetails && <Spinner size="sm" color="light" />}
      {importItem._loadingStateDetails === LoadingState.FAILED && (
        <FontAwesomeIcon icon={faTimes} color="#FFF" />
      )}
    </Button>
  );
};
