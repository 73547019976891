import * as React from "react";
import { Spinner } from "reactstrap";

interface Props {
  className?: string;
}

export const SpinnerBox = ({ className }: Props) => {
  return (
    <div className={"row align-items-center flex-grow-1 " + className || ""}>
      <div className="col-6 mx-auto d-flex w-100 justify-content-around">
        <Spinner color="secondary" />
      </div>
    </div>
  );
};
