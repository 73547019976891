import React, { Fragment } from "react";
import { Alert } from "reactstrap";
import { AlertProps } from "./Alerts";

export const AlertsComponent = ({ alerts, removeAlert }: AlertProps) => {
  return (
    <Fragment>
      {alerts.map((alert, index) => (
        <div
          key={index}
          className="fixed-top container mt-1"
          style={{ left: "50%", transform: "translate(-50%, 0)" }}
        >
          <Alert
            color={alert.type}
            isOpen={true}
            toggle={() => removeAlert(index)}
          >
            {alert.message}
          </Alert>
        </div>
      ))}
    </Fragment>
  );
};
