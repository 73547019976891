import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  ListGroupItem,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap";
import { CopyComponent } from "../Copy/Copy.component";
import { WorkBadge } from "../WorkBadge/WorkBadge.component";

import { Identifier, Organization, WorkRef } from "../../types";
import {
  decomposeReferenceId,
  displayItemsWithPipe,
  resolveOrganizationName
} from "../../utils/helpers";

interface Props {
  work: Identifier | WorkRef;
  workIdQuery?: string;
  organizations: Organization[];
  relationship?: {
    source: string;
  };
  works?: string[];
  workRefs?: WorkRef[];
  copyId: string;
  index: number;
}

export const WorkComponent = ({
  work,
  workIdQuery,
  organizations,
  relationship,
  works,
  workRefs,
  copyId,
  index
}: Props) => {
  const LINKS_DISPLAY_LIMIT = 15;
  const { isDSPId, referenceId } = decomposeReferenceId(work.id);
  const linkActive = !!workIdQuery ? work.id.endsWith(workIdQuery) : false;
  const [isCopyVisible, setIsCopyVisible] = useState(false);
  const submitter = resolveOrganizationName(
    organizations,
    decomposeReferenceId(work.id).CMO
  );
  const type = resolveOrganizationName(organizations, work.sourceIdType);
  return (
    <ListGroupItem
      data-test-id={"work-id"}
      active={linkActive}
      tag={isDSPId || linkActive ? "div" : Link}
      to={`/search/${encodeURIComponent(work.id)}?type=work`}
      action={!isDSPId && !linkActive}
      onMouseOver={() => setIsCopyVisible(true)}
      onMouseOut={() => setIsCopyVisible(false)}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="small" style={{ opacity: 0.5 }}>
            {displayItemsWithPipe(
              submitter,
              `Type ${type} ID`,
              (relationship && relationship.source) || ""
            )}
          </div>
          <div className="text-monospace position-relative">
            {work.sourceId}{" "}
            <CopyComponent isVisible={isCopyVisible} id={copyId} />
          </div>
        </div>
        {works && works.length && <WorkBadge id={referenceId} works={works} />}
        {((work as Identifier).in && (
          <React.Fragment>
            <Badge
              style={{ opacity: 0.6 }}
              className="align-middle"
              color="secondary"
              id={`links-${index}-${copyId.replace(/\W/g, "_")}`}
            >
              {((work as Identifier).in || []).length} Work
              {((work as Identifier).in || []).length > 1 && "s"}
            </Badge>

            <UncontrolledPopover
              placement="right"
              trigger="hover"
              target={`links-${index}-${copyId.replace(/\W/g, "_")}`}
              className="popOver"
            >
              <PopoverBody>
                {((work as Identifier).in || [])
                  .slice(0, LINKS_DISPLAY_LIMIT - 1)
                  .map(item => {
                    const ref =
                      workRefs &&
                      workRefs.length &&
                      workRefs.find(
                        ({ id: workRefId }) => workRefId === item.work
                      );
                    return (
                      ref && (
                        <span key={ref.id} className="d-block">
                          <Link to={`/works/${ref.id}`} className="mr-2">
                            {ref.title
                              ? `${ref.title}`
                              : decomposeReferenceId(ref.id).referenceId}
                          </Link>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            {resolveOrganizationName(
                              organizations,
                              decomposeReferenceId(ref.id).CMO || ""
                            )}
                          </Badge>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            {resolveOrganizationName(
                              organizations,
                              decomposeReferenceId(ref.id).CMO || ""
                            )}
                          </Badge>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            {ref.dataAuthority
                              ? `Authority ${ref.dataAuthority}`
                              : ``}
                          </Badge>
                        </span>
                      )
                    );
                  })}
                {((work as Identifier).in || []).length >
                  LINKS_DISPLAY_LIMIT && (
                  <div className="text-muted w-100 text-center">...</div>
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </React.Fragment>
        )) ||
          null}
      </div>
    </ListGroupItem>
  );
};
