import * as React from "react";
import { UncontrolledAlert, UncontrolledAlertProps } from "reactstrap";

export const FixedAlert = (props: UncontrolledAlertProps) => (
  <div
    className="fixed-top container mt-1"
    style={{ left: "50%", transform: "translate(-50%, 0)" }}
  >
    <UncontrolledAlert {...props}>{props.children}</UncontrolledAlert>
  </div>
);
