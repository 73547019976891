import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { fetchOrganizationsEffect } from "../Import/Import.effects";
import { setActiveFilterTabAction } from "./Export.actions";
import { ExportComponent } from "./Export.component";
import {
  cancelExportEffect,
  createExportEffect,
  fetchExportDownloadLinkEffect,
  fetchExportsEffect,
  setPeriodicExportsRefreshEffect
} from "./Export.effects";
import { ExportFilterType } from "./types";

const mapStateToProps = ({
  export: {
    exports,
    link,
    loadingState,
    data,
    exportCount,
    exportLoadingState,
    previewLoadingState,
    filterProps,
    activeFilterTab
  },
  imports: { organizations }
}: AppState) => ({
  data,
  exportCount,
  exportLoadingState,
  previewLoadingState,
  filterProps,
  organizations,
  link,
  exports,
  loadingState,
  activeFilterTab
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  createExport: (filters: ExportFilterType) =>
    dispatch(createExportEffect(filters)),
  fetchOrganizations: () => dispatch(fetchOrganizationsEffect()),
  fetchExports: () => dispatch(fetchExportsEffect()),
  fetchExportDownloadLink: (id: string, type: string) =>
    dispatch(fetchExportDownloadLinkEffect(id, type)),
  stopPeriodicExportsRefresh: () =>
    dispatch(setPeriodicExportsRefreshEffect(0)),
  cancelExport: (id: string) => dispatch(cancelExportEffect(id)),
  setActiveFilterTab: (tabId: string) =>
    dispatch(setActiveFilterTabAction(tabId))
});

export const ExportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportComponent);

export type ExportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;
