import numeral from "numeral";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Jumbotron,
  Row
} from "reactstrap";
import { FillHeightContainer } from "../../components/FillHeightContainer/FillHeightContainer";
import { InfoIconComponent } from "../../components/InfoIcon/InfoIcon.component";
import { SpinnerBox } from "../../components/SpinnerBox/SpinnerBox.component";
import { Definitions } from "../../utils/Definitions";
import { LoadingState } from "../../utils/LoadingState";
import { MetricsProps } from "./Metrics";
import "./styles.css";

export const MetricsComponent = ({
  data: { global = {} },
  loadingState,
  fetchMetrics,
  history
}: MetricsProps) => {
  useEffect(() => {
    if (loadingState === LoadingState.PRISTINE) {
      fetchMetrics();
    }
  });
  const [input, setInput] = useState("");
  return (
    <FillHeightContainer>
      <Container>
        <h1 className="sectionHeader">Metrics</h1>
      </Container>
      {loadingState === LoadingState.FETCHED && (
        <Container>
          <Row className="justify-content-between flex-column flex-lg-row">
            <Col className="pr-lg-1">
              <Jumbotron className="jumboLarge pl-4 pr-4 text-muted">
                <h2 className="jumboHeaderLarge">
                  {numeral(global.worksTotal).format("0,0")}
                </h2>
                <h6 className="jumboSubHeaderLarge">
                  Works
                  <InfoIconComponent
                    id={`totalWorks`}
                    infoText={Definitions.totalWorks}
                  />
                </h6>
              </Jumbotron>
            </Col>
            <Col className="pr-lg-1 pl-lg-1 flex-column">
              <Jumbotron className="jumbo mb-lg-2 p-3 pl-4 pr-4 text-muted">
                <h2 className="jumboHeader">
                  {numeral(global.linksTotal).format("0,0")}
                </h2>
                <h6 className="jumboSubHeader">
                  Links Imported
                  <InfoIconComponent
                    id={`totalLinks`}
                    infoText={Definitions.totalLinks}
                  />
                </h6>
              </Jumbotron>
              <Jumbotron className="jumbo p-3 pl-4 pr-4 text-muted">
                <h2 className="jumboHeader">
                  {numeral(global.linksDerived).format("0,0")}
                </h2>
                <h6 className="jumboSubHeader">
                  Links Shared Through Elixir
                  <InfoIconComponent
                    id={`totalSharedLinks`}
                    infoText={Definitions.totalSharedLinks}
                  />
                </h6>
              </Jumbotron>
            </Col>
            <Col className="p-lg-6 pl-lg-1">
              <Jumbotron className="jumboLarge pl-4 pr-4 text-muted">
                <h2 className="jumboHeaderLarge">
                  {numeral(global.isrcsUnique).format("0,0")}
                </h2>
                <h6 className="jumboSubHeaderLarge">ISRCs</h6>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      )}
      {loadingState === LoadingState.FETCHING && <SpinnerBox />}
      <Container>
        <h1 className="sectionHeader">Search</h1>
        <form onSubmit={() => history.push(`search/${input}`)}>
          <InputGroup className="mt-4">
            <Input
              value={input}
              placeholder="Use ISWC, ISRC, Society ID, etc."
              onChange={e => setInput(e.currentTarget.value)}
            />
            <InputGroupAddon addonType="append">
              <Button color={"primary"}>Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </form>
      </Container>
    </FillHeightContainer>
  );
};
