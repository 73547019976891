import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Col,
  ListGroupItem,
  PopoverBody,
  Row,
  UncontrolledPopover
} from "reactstrap";

import { Organization, Recording, WorkRef } from "../../types";
import {
  decomposeReferenceId,
  displayItemsWithPipe,
  resolveOrganizationName
} from "../../utils/helpers";
import { CopyComponent } from "../Copy/Copy.component";
import "../styles.css";

interface RecordingProps {
  recording: Recording;
  organizations: Organization[];
  recordingId?: string;
  zIndex?: number;
  key: string | number;
  isSub?: boolean;
  works?: WorkRef[];
  copyId: string;
}

export const RecordingComponent = ({
  recording,
  recordingId,
  organizations,
  zIndex,
  key,
  isSub,
  works,
  copyId
}: RecordingProps) => {
  const isInAggregatedView = works && works.length;
  const isDSPId = recording.identifier.namespace.startsWith("TRACK:");
  const sourceOrganizations = recording.in.map(({ id }) =>
    resolveOrganizationName(organizations, decomposeReferenceId(id).CMO)
  );
  const sourceSuppliers = recording.in.map(({ supplierId }) => supplierId);
  const maxTimestamp = Math.max(
    ...recording.in.map(({ timestamp }) => timestamp)
  );
  const extractedSupplierId =
    (sourceSuppliers.length === 1 && sourceSuppliers[0]) || "";
  const linkActive = !!recordingId
    ? recording.identifier.fullId.endsWith(recordingId)
    : false;
  const navigable = !(isDSPId || linkActive);
  const [isCopyVisible, setIsCopyVisible] = useState(false);
  return (
    <ListGroupItem
      active={linkActive}
      tag={navigable ? Link : "div"}
      to={`/search/${encodeURIComponent(
        recording.identifier.fullId
      )}?type=recording`}
      action={navigable}
      style={{ zIndex }}
      onMouseOver={() => setIsCopyVisible(true)}
      onMouseOut={() => setIsCopyVisible(false)}
    >
      <Row noGutters className="align-items-end">
        <Col>
          <div>
            {isSub ? "\u00A0\u00A0\u00A0" : ""}
            <small style={{ opacity: 0.5 }}>
              {displayItemsWithPipe(
                sourceOrganizations.join(", "),
                ...(recording.linkClassification
                  ? [recording.linkClassification + ""]
                  : []),
                ...(recording.dataAuthority
                  ? [`Authority ${recording.dataAuthority}`]
                  : []),
                ...(!isInAggregatedView
                  ? [`Supplier ${extractedSupplierId}`]
                  : [])
              )}
            </small>
          </div>
          <span>{isSub ? "\u00A0\u00A0\u00A0" : ""}</span>
          <span className="text-monospace">
            {recording.identifier.id}{" "}
            <CopyComponent isVisible={isCopyVisible} id={copyId} />
          </span>
        </Col>
        <Col xs="auto text-right">
          <div>
            <small style={{ opacity: 0.5 }}>
              {moment.unix(maxTimestamp).format("MM/DD/YYYY h:ss a")}
            </small>
          </div>
          <span>{recording.title || "\u00A0"}</span>
          {isInAggregatedView && recording.in && (
            <React.Fragment>
              <span style={{ opacity: 0.6 }}>
                <Badge
                  color="secondary"
                  id={`links-${key}-${copyId.replace(/\W/g, "_")}`}
                >
                  {recording.in.length} Work
                  {recording.in.length > 1 && "s"}
                </Badge>
              </span>
              <UncontrolledPopover
                placement="right"
                trigger="hover"
                target={`links-${key}-${copyId.replace(/\W/g, "_")}`}
                className="popOver"
              >
                <PopoverBody>
                  {recording.in.map(item => {
                    const work =
                      works &&
                      works.length &&
                      works.find(({ id }) => id === item.work);
                    return (
                      work && (
                        <span key={item.id} className="d-block">
                          <Link to={`/works/${item.work}`} className="mr-2">
                            {work.title
                              ? `${work.title}`
                              : decomposeReferenceId(item.id).referenceId}
                          </Link>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            {resolveOrganizationName(
                              organizations,
                              decomposeReferenceId(item.id).CMO || ""
                            )}
                          </Badge>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            Supplier {item.supplierId}
                          </Badge>
                          <Badge className="ml-1" style={{ opacity: 0.6 }}>
                            {item.dataAuthority
                              ? `Authority ${item.dataAuthority}`
                              : ``}
                          </Badge>
                        </span>
                      )
                    );
                  })}
                </PopoverBody>
              </UncontrolledPopover>
            </React.Fragment>
          )}
        </Col>
      </Row>
    </ListGroupItem>
  );
};
