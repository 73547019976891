import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { fetchAggregatedEffect } from "../Aggregated/Aggregated.effects";
import { fetchOrganizationsEffect } from "../Import/Import.effects";
import { setRecordingGroupIsOpenAction } from "./Detail.actions";
import { Detail } from "./Detail.component";
import { fetchDetailEffect, fetchRecordingGroupEffect } from "./Detail.effects";

const mapStateToProps = ({
  imports: { organizations },
  detail: { data, loadingState, errorMsg, statusCode },
  aggregated: { data: aggregatedData, loadingState: aggregatedLoadingState }
}: AppState) => ({
  data,
  aggregatedData,
  loadingState,
  aggregatedLoadingState,
  errorMsg,
  organizations,
  statusCode
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  fetchOrganizations: () => dispatch(fetchOrganizationsEffect()),
  fetchDetail: (id: string) => dispatch(fetchDetailEffect(id)),
  fetchRecordingGroup: (recordingGroup: string, offset: number) =>
    dispatch(fetchRecordingGroupEffect(recordingGroup, offset)),
  setRecordingGroupIsOpen: (recordingGroup: string, isOpen: boolean) =>
    dispatch(setRecordingGroupIsOpenAction(recordingGroup, isOpen)),
  fetchAggregated: (iswcId: string) => dispatch(fetchAggregatedEffect(iswcId))
});
export const DetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Detail);

export type DetailsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{
    id: string;
    workId: string;
    recordingId: string;
  }>;
