import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Badge, Collapse, ListGroup, ListGroupItem } from "reactstrap";

interface Props {
  active?: boolean;
  children: any;
  customBadge?: React.ReactNode;
  disabled?: boolean;
  isOpen: boolean;
  isSub?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  setIsOpen?: (isOpen: boolean) => void;
  title: string;
  zIndex?: number;
}

export const CollapsableList = ({
  active,
  children,
  customBadge,
  disabled,
  isOpen,
  isSub,
  onClick,
  setIsOpen,
  title,
  zIndex
}: Props) => {
  const isDisabled =
    disabled != null ? disabled : !children || children.length === 0;
  const showBadge = disabled != null ? !disabled : children;
  return (
    <ListGroup
      className="list-group-flush"
      data-test-id={`collapsible-${title}`}
    >
      <ListGroupItem
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (onClick) {
            onClick(e);
          } else {
            e.preventDefault();
          }
          if (setIsOpen) {
            setIsOpen(!isOpen);
          }
        }}
        style={{ zIndex, opacity: isDisabled ? 0.7 : 1 }}
        href="#"
        tag="a"
        action
        disabled={isDisabled}
        active={active}
        className="w-100 card-header d-flex justify-content-between bg-light"
      >
        <span>
          {isSub ? "\u00A0\u00A0\u00A0" : ""}
          <FontAwesomeIcon
            icon={faChevronRight}
            size="xs"
            className={isOpen ? "text-muted rotate down" : "text-muted rotate"}
            style={{ marginLeft: "6px" }}
          />
          <span className="ml-2">{title}</span>
        </span>
        {showBadge && (
          <span>
            {customBadge || (
              <Badge color="secondary" pill>
                {children.length}
              </Badge>
            )}
          </span>
        )}
      </ListGroupItem>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </ListGroup>
  );
};
