import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";
import Container from "reactstrap/lib/Container";
import { clearAuth } from "../../utils/storageManager";
import { HeaderProps } from "./Header";

const HeaderComponent = ({ signOut, location }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const logout = () => {
    clearAuth();
    signOut();
  };

  return (
    <div className={"sticky-top"}>
      <Navbar color="light" light expand="md">
        <Container>
          <NavbarBrand href="/">Elixir</NavbarBrand>
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  data-test-id={"import-nav"}
                  tag={Link}
                  to="/import"
                  className={
                    location.pathname === "/import" ? "disabled-link" : ""
                  }
                >
                  Import
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to={"/export"}
                  className={
                    location.pathname === "/export" ? "disabled-link" : ""
                  }
                >
                  Export
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to={"/metrics"}
                  className={
                    location.pathname === "/metrics" ? "disabled-link" : ""
                  }
                >
                  Metrics
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  data-test-id={"search-nav"}
                  tag={Link}
                  to="/search"
                  className={
                    location.pathname === "/search" ? "disabled-link" : ""
                  }
                >
                  Search
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  data-test-id={"sign-out"}
                  outline
                  onClick={logout}
                  color="secondary"
                  size="sm"
                  className="lg-5 mt-1 ml-4"
                >
                  Sign Out
                  <span className="ml-1">
                    <FontAwesomeIcon icon="sign-out-alt" />
                  </span>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export const Header = withRouter(HeaderComponent);
