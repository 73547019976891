import axios from "axios";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { apiUrls } from "../../api.config";
import { MetricsResponse } from "../../api.types";
import { AppState } from "../../App.reducer";
import { Errors } from "../../utils/UnexpectedErrors";
import {
  fetchMetricsAction,
  fetchMetricsFailAction,
  fetchMetricsSuccessAction
} from "./Metrics.actions";

export const fetchMetricsEffect = (): ThunkAction<
  void,
  AppState,
  void,
  Action<string>
> => (dispatch: ThunkDispatch<AppState, void, Action<string>>) => {
  dispatch(fetchMetricsAction());
  axios
    .get<MetricsResponse>(`${apiUrls.statistics}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(fetchMetricsSuccessAction(response.data.response.statistics));
      } else {
        dispatch(fetchMetricsFailAction(response.data.error.message));
      }
    })
    .catch(e => {
      if (e.response) {
        dispatch(
          fetchMetricsFailAction(
            (e.response.data.error && e.response.data.error.message) ||
              e.response.data
          )
        );
      } else {
        dispatch(fetchMetricsFailAction(Errors.uncatchError));
      }
      // tslint:disable-next-line: no-console
      console.log(e);
    });
};
