import * as React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { LoadingState } from "../../utils/LoadingState";
import { SimpleDatePicker } from "../SimpleDatePicker/SimpleDatePicker.component";

interface Fields {
  fromDate: {
    day: string;
    month: string;
    year: string;
  };
  toDate: {
    day: string;
    month: string;
    year: string;
  };
}

interface Props {
  values: Fields;
  errors: Fields;
  touched: Fields;
  handleChange: () => void;
  previewLoadingState: LoadingState;
  submitCount: number;
}

export const FilterByDate = ({
  values,
  errors,
  touched,
  handleChange,
  previewLoadingState,
  submitCount
}: Props) => (
  <div>
    <Row>
      <Col lg={true}>
        <FormGroup data-test-id={"from-date"}>
          <Label className="text-muted">From</Label>
          <SimpleDatePicker
            disabled={previewLoadingState === LoadingState.FETCHING}
            activeValues={values.fromDate}
            name="fromDate"
            errors={errors.fromDate}
            onChange={handleChange}
            touched={touched.fromDate && !!Object.keys(touched.fromDate).length}
            submitCount={submitCount}
          />
        </FormGroup>
      </Col>
      <Col lg={true}>
        <FormGroup data-test-id={"to-date"}>
          <Label className="text-muted">To</Label>
          <SimpleDatePicker
            disabled={previewLoadingState === LoadingState.FETCHING}
            activeValues={values.toDate}
            name="toDate"
            errors={errors.toDate}
            onChange={handleChange}
            touched={touched.toDate && !!Object.keys(touched.toDate).length}
            submitCount={submitCount}
          />
        </FormGroup>
      </Col>
    </Row>
  </div>
);
