import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { SigninActionTypes } from "../Signin/Signin.action.types";
import { signOutAction } from "../Signin/Signin.actions";
import { Header } from "./Header.component";

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  signOut: (): SigninActionTypes => dispatch(signOutAction())
});

export const HeaderContainer = connect(
  null,
  mapDispatchToProps
)(Header);

export type HeaderProps = ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;
