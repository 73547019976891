import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { LoginData, ResetConfirm } from "../../auth";
import { LoginStateTypes } from "./Signin.action.types";
import { setRedirectAction, signinStateManageAction } from "./Signin.actions";
import { SigninView } from "./Signin.component";
import {
  confirmResetEffect,
  resetPasswordEffect,
  setNewPasswordEffect,
  signinEffect
} from "./Signin.effects";

const mapStateToProps = ({ signin }: AppState) => ({
  errorMessage: signin.errorMessage,
  signinState: signin.signinState,
  redirect: signin.redirect
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  signin: (credentials: LoginData) => dispatch(signinEffect(credentials)),
  setRedirect: (path: string) => dispatch(setRedirectAction(path)),
  changeFormTo: (signinState: LoginStateTypes) =>
    dispatch(signinStateManageAction(signinState)),
  resetPassword: (password: string) => dispatch(resetPasswordEffect(password)),
  confirmReset: (resetData: ResetConfirm) =>
    dispatch(confirmResetEffect(resetData)),
  setNewPassword: (password: string) => dispatch(setNewPasswordEffect(password))
});

export const SigninContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninView);

export type LoginPageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;
