import * as React from "react";
import { Fragment, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import { FixedAlert } from "../../components/FixedAlert/FixedAlert.component";
import { Page404 } from "../../components/Page404/Page404.component";
import { SourceWorks } from "../../components/SourceWorks/SourceWorks.component";
import { SpinnerBox } from "../../components/SpinnerBox/SpinnerBox.component";
import { WorkCard } from "../../components/WorkCard/WorkCard.component";
import { Identifier } from "../../types";
import { decomposeReferenceId } from "../../utils/helpers";
import { LoadingState } from "../../utils/LoadingState";
import { AggregatedProps } from "./Aggregated";

export const Aggregated = ({
  data,
  match: {
    params: { iswcId }
  },
  errorMsg,
  statusCode,
  fetchAggregated,
  organizations,
  fetchRecordingGroup,
  setRecordingGroupIsOpen,
  loadingState,
  fetchOrganizations,
  location: { search, pathname }
}: AggregatedProps) => {
  const params = new URLSearchParams(search);
  const workId = params.get("workId") || undefined;

  const recordingId = params.get("recordingId") || undefined;
  const iswcIdParam = iswcId || "";

  const isAggregatedLoaded = (identifier: Identifier[]) => {
    const { referenceId } = decomposeReferenceId(pathname);
    const index = identifier.findIndex(
      ({ sourceId }) => sourceId === referenceId
    );
    return index !== -1;
  };

  useEffect(() => {
    if (!data) {
      fetchAggregated(iswcIdParam);
    }

    if (data && !isAggregatedLoaded(data && data.Identifier)) {
      fetchAggregated(iswcIdParam);
    }
  }, []);

  useEffect(() => {
    if (!organizations.length) {
      fetchOrganizations();
    }
  }, []);
  return (
    <Row noGutters className="justify-content-center h-100 w-100">
      <Col>
        <div className="h-100 d-flex flex-column pt-5">
          <Container className="mb-3">
            <Breadcrumb>
              <BreadcrumbItem>Aggregated Works</BreadcrumbItem>
              <BreadcrumbItem active>{data && data.title}</BreadcrumbItem>
            </Breadcrumb>
          </Container>
          <Row noGutters className="justify-content-center flex-grow-1">
            <Container>
              {loadingState === LoadingState.FETCHING && (
                <SpinnerBox className="h-100" />
              )}
              <Row>
                {loadingState === LoadingState.FETCHED && data && (
                  <WorkCard
                    recordingId={recordingId}
                    workId={workId}
                    id={iswcIdParam}
                    data={data}
                    isDetailView={false}
                    organizations={organizations}
                    recordingsUrlSegment="recordings-aggregated"
                    onFetchRecordingGroup={(recordingGroup, offset) =>
                      fetchRecordingGroup(recordingGroup, offset)
                    }
                    onSetIsOpen={(recordingGroup, isOpen) =>
                      setRecordingGroupIsOpen(recordingGroup, isOpen)
                    }
                  />
                )}
                <SourceWorks
                  works={data && data.works}
                  organizations={organizations}
                  isAggregatedView={true}
                  aggregatedLink={`/aggregated-works/${iswcId}`}
                />
                {loadingState === LoadingState.FAILED && (
                  <Fragment>
                    {statusCode === 404 ? (
                      <Page404 meta={iswcIdParam}>
                        The Requested Work Does Not Exist
                      </Page404>
                    ) : (
                      <FixedAlert color="danger">{errorMsg}</FixedAlert>
                    )}
                  </Fragment>
                )}
              </Row>
            </Container>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
