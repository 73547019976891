import * as React from "react";
import { Redirect, Route } from "react-router";

export const PrivateRoute = ({
  auth,
  component: Component,
  exact,
  path,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      exact={exact}
      path={path}
      render={(routeProps): React.ReactNode =>
        auth ? (
          // @ts-ignore
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: {
                from:
                  (routeProps.location.pathname || "") +
                  routeProps.location.search
              }
            }}
          />
        )
      }
    />
  );
};
