import * as React from "react";
import { Link } from "react-router-dom";
import { Col, ListGroupItem } from "reactstrap";
import { Organization, WorkRef } from "../../types";
import { CollapsedSourceWorks } from "../CollapsedSourceWorks/CollapsedSourceWorks.component";
import { SourceWorkComponent } from "../SourceWork/SourceWork.component";

interface Props {
  works?: WorkRef[];
  organizations: Organization[];
  aggregatedLink: string;
  isAggregatedView?: boolean;
  sourceWorkId?: string;
}

export const SourceWorks = ({
  works,
  organizations,
  aggregatedLink,
  isAggregatedView,
  sourceWorkId
}: Props) => {
  const LIMIT = 3;
  const MAX_INITIAL_LENGTH = 7;

  const trimSourceWorks = (data: WorkRef[]) => {
    if (data.length <= MAX_INITIAL_LENGTH) {
      return { visibleList: data };
    }

    if (isAggregatedView && data.length >= MAX_INITIAL_LENGTH) {
      return {
        visibleList: data.slice(0, MAX_INITIAL_LENGTH),
        successorList: data.slice(MAX_INITIAL_LENGTH, data.length)
      };
    }

    const activeWorkPosition =
      data.findIndex(el => el.id.endsWith(sourceWorkId || "")) || 0;

    if (!isAggregatedView && data.length >= MAX_INITIAL_LENGTH) {
      const predecessors = data.slice(0, activeWorkPosition - LIMIT);
      const successor = data.slice(activeWorkPosition + LIMIT + 1, data.length);

      return {
        predecessorsList:
          activeWorkPosition < predecessors.length ? [] : predecessors,
        visibleList:
          activeWorkPosition < predecessors.length
            ? data.slice(0, activeWorkPosition + LIMIT)
            : data.slice(
                activeWorkPosition - LIMIT,
                activeWorkPosition + LIMIT + 1
              ),
        successorList: successor
      };
    }
    return { visibleList: data };
  };

  const { predecessorsList, visibleList, successorList } = trimSourceWorks(
    works || []
  );

  return (
    <Col sm="12" xs="12" md="5" lg="5" xl="5" className="pb-3">
      {works && (
        <div className="card">
          <div className="card-header">Source Works</div>
          <ul className="list-group list-group-flush">
            <ListGroupItem
              tag={Link}
              action
              active={isAggregatedView}
              to={aggregatedLink}
            >
              Aggregated
            </ListGroupItem>
            {predecessorsList && predecessorsList.length > 0 && (
              <CollapsedSourceWorks
                dataTestId={"load-predecessors"}
                works={predecessorsList}
                organizations={organizations}
                isAggregatedView={isAggregatedView}
                sourceWorkId={sourceWorkId}
              />
            )}
            {visibleList.map(work => (
              <SourceWorkComponent
                organizations={organizations}
                linkActive={
                  isAggregatedView
                    ? false
                    : work.id.endsWith(sourceWorkId || "")
                }
                work={work}
                key={work.id}
              />
            ))}
            {successorList && successorList.length > 0 && (
              <CollapsedSourceWorks
                dataTestId={"load-successors"}
                works={successorList}
                organizations={organizations}
                isAggregatedView={isAggregatedView}
                sourceWorkId={sourceWorkId}
              />
            )}
          </ul>
        </div>
      )}
    </Col>
  );
};
