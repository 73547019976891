import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import { fetchOrganizationsEffect } from "../Import/Import.effects";
import { AggregatedActionTypes } from "./Aggregated.action.types";
import { setRecordingGroupIsOpenAction } from "./Aggregated.actions";
import { Aggregated } from "./Aggregated.component";
import {
  fetchAggregatedEffect,
  fetchRecordingGroupEffect
} from "./Aggregated.effects";

const mapStateToProps = ({
  aggregated: { data, loadingState, errorMsg, statusCode },
  imports: { organizations }
}: AppState) => ({
  data,
  loadingState,
  errorMsg,
  statusCode,
  organizations
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  fetchOrganizations: () => dispatch(fetchOrganizationsEffect()),
  fetchAggregated: (iswcId: string) => dispatch(fetchAggregatedEffect(iswcId)),
  fetchRecordingGroup: (recordingGroup: string, offset: number) =>
    dispatch(fetchRecordingGroupEffect(recordingGroup, offset)),
  setRecordingGroupIsOpen: (
    recordingGroup: string,
    isOpen: boolean
  ): AggregatedActionTypes =>
    dispatch(setRecordingGroupIsOpenAction(recordingGroup, isOpen))
});

export const AggregatedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Aggregated);

export type AggregatedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ iswcId: string }>;
