import { Metrics } from "../../types";
import { LoadingState } from "../../utils/LoadingState";
import {
  FETCH_METRICS,
  FETCH_METRICS_FAIL,
  FETCH_METRICS_SUCCESS,
  MetricsActionTypes
} from "./Metrics.action.types";

export interface MetricsState {
  data: Metrics | null;
  errorMsg: string;
  loadingState: LoadingState;
}

export const initialState: MetricsState = {
  data: null,
  errorMsg: "",
  loadingState: LoadingState.PRISTINE
};

export const metricsReducer = (
  state: MetricsState = initialState,
  action: MetricsActionTypes
): MetricsState => {
  switch (action.type) {
    case FETCH_METRICS:
      return Object.assign({}, state, {
        ...state,
        data: null,
        errorMsg: "",
        loadingState: LoadingState.FETCHING
      });
    case FETCH_METRICS_FAIL:
      return Object.assign({}, state, {
        ...state,
        loadingState: LoadingState.FAILED,
        errorMsg: action.error
      });
    case FETCH_METRICS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        data: action.data,
        loadingState: LoadingState.FETCHED
      });
    default:
      return state;
  }
};
