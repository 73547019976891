import { Import, Organization } from "../../types";
import { ListLoadingState, LoadingState } from "../../utils/LoadingState";
import {
  DOWNLOAD_IMPORT_FAIL,
  FETCH_IMPORT_DETAILS,
  FETCH_IMPORT_DETAILS_FAIL,
  FETCH_IMPORT_DETAILS_SUCCESS,
  FETCH_IMPORTS,
  FETCH_IMPORTS_FAIL,
  FETCH_IMPORTS_SUCCESS,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_FAIL,
  FETCH_ORGANIZATIONS_SUCCESS,
  ImportsActionTypes,
  SET_PERIODIC_REFRESH,
  UPLOAD_FILE,
  UPLOAD_FILE_FAIL,
  UPLOAD_FILE_PROGRESS
} from "./Import.action.types";

export interface ImportState {
  detailsError: string;
  importsError: string;
  fileImportError: string;
  fileDownloadError: string;
  nextToken?: string;
  firstToken?: string;
  importsList: Import[];
  organizations: Organization[];
  loadingState: ListLoadingState;
  organizationsLoadingState: LoadingState;
  intervalId?: number;
  interval?: number;
  cancellable: boolean;
  cancelImportUrl: string;
  fetchLogFileUrl: string;
  sourceFileUrl: string;
}

export const initialState: ImportState = {
  detailsError: "",
  importsError: "",
  fileDownloadError: "",
  fileImportError: "",
  importsList: [],
  organizations: [],
  loadingState: ListLoadingState.PRISTINE,
  organizationsLoadingState: LoadingState.PRISTINE,
  cancellable: false,
  cancelImportUrl: "",
  fetchLogFileUrl: "",
  sourceFileUrl: ""
};

export const importReducer = (
  state: ImportState = initialState,
  action: ImportsActionTypes
): ImportState => {
  switch (action.type) {
    case FETCH_IMPORTS: {
      const loadingState = action.nextPage
        ? ListLoadingState.FETCHING_MORE
        : state.loadingState === ListLoadingState.PRISTINE
        ? ListLoadingState.FETCHING
        : ListLoadingState.REFRESHING;
      return {
        ...state,
        importsError: "",
        loadingState,
        importsList:
          loadingState === ListLoadingState.FETCHING ? [] : state.importsList
      };
    }
    case FETCH_IMPORTS_SUCCESS: {
      const placeholders = state.importsList.filter(
        ({ _uploadProgress, importId: placeholderImportId }) =>
          _uploadProgress != null &&
          !action.imports.find(
            ({ importId }) => placeholderImportId === importId
          )
      );
      const imports = action.imports.map(item => ({
        _loadingStateFullErrorLogUrl: LoadingState.PRISTINE,
        _loadingStateDetails: LoadingState.PRISTINE,
        ...(state.importsList.find(
          ({ importId }) => importId === item.importId
        ) || {}),
        ...item
      }));
      return {
        ...state,
        importsList: action.append
          ? [...state.importsList, ...imports]
          : [...placeholders, ...imports],
        loadingState: ListLoadingState.FETCHED,
        firstToken: action.append ? state.firstToken : action.nextToken,
        nextToken: action.nextToken,
        cancelImportUrl: action.cancelImportUrl,
        fetchLogFileUrl: action.fetchLogFileUrl,
        sourceFileUrl: action.sourceFileUrl
      };
    }
    case FETCH_IMPORTS_FAIL: {
      return {
        ...state,
        importsError: action.error,
        loadingState: ListLoadingState.FAILED
      };
    }
    case FETCH_IMPORT_DETAILS: {
      return {
        ...state,
        importsList: state.importsList.map(importItem => {
          if (
            importItem.importId === action.importId &&
            importItem._loadingStateDetails !== LoadingState.FETCHED
          ) {
            importItem._loadingStateDetails = LoadingState.FETCHING;
          }
          return importItem;
        }),
        detailsError: ""
      };
    }
    case FETCH_IMPORT_DETAILS_FAIL: {
      return {
        ...state,
        detailsError: action.error,
        importsList: state.importsList.map(importItem => {
          if (
            importItem.importId === action.importId &&
            importItem._loadingStateDetails !== LoadingState.FETCHED
          ) {
            importItem._loadingStateDetails = LoadingState.FAILED;
          }
          return importItem;
        })
      };
    }
    case FETCH_IMPORT_DETAILS_SUCCESS: {
      const index = state.importsList.findIndex(
        importItem => importItem.importId === action.importId
      );
      return index !== -1
        ? {
            ...state,
            importsList: [
              ...state.importsList.slice(0, index),
              {
                ...state.importsList[index],
                ...action.importDetail,
                _loadingStateDetails: LoadingState.FETCHED
              },
              ...state.importsList.slice(index + 1)
            ],
            detailsError: ""
          }
        : state;
    }
    case FETCH_ORGANIZATIONS: {
      return {
        ...state,
        importsError: "",
        organizationsLoadingState: LoadingState.FETCHING
      };
    }
    case FETCH_ORGANIZATIONS_FAIL: {
      return {
        ...state,
        importsError: action.error,
        organizationsLoadingState: LoadingState.FAILED
      };
    }
    case FETCH_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        organizations: action.organizations,
        organizationsLoadingState: LoadingState.FETCHED
      };
    }
    case UPLOAD_FILE: {
      return {
        ...state,
        importsList: [action.placeholder, ...state.importsList]
      };
    }
    case UPLOAD_FILE_PROGRESS: {
      const placeholderIndex = state.importsList.findIndex(importItem => {
        return importItem.importId === action.importId;
      });
      return placeholderIndex !== -1
        ? {
            ...state,
            importsList: [
              ...state.importsList.slice(0, placeholderIndex),
              {
                ...state.importsList[placeholderIndex],
                _uploadProgress: action.progress
              },
              ...state.importsList.slice(placeholderIndex + 1)
            ]
          }
        : state;
    }

    case UPLOAD_FILE_FAIL: {
      return {
        ...state,
        fileImportError: action.error
      };
    }
    case SET_PERIODIC_REFRESH: {
      return {
        ...state,
        intervalId: action.intervalId,
        interval: action.interval
      };
    }
    case DOWNLOAD_IMPORT_FAIL: {
      return {
        ...state,
        fileDownloadError: action.error
      };
    }
    default:
      return state;
  }
};
