import { default as React, Fragment, useState } from "react";
import { Collapse, ListGroupItem } from "reactstrap";
import { Organization, WorkRef } from "../../types";
import { SourceWorkComponent } from "../SourceWork/SourceWork.component";

interface Props {
  works: WorkRef[];
  organizations: Organization[];
  isAggregatedView?: boolean;
  sourceWorkId?: string;
  dataTestId: string;
}

export const CollapsedSourceWorks = ({
  works,
  organizations,
  isAggregatedView,
  sourceWorkId,
  dataTestId
}: Props) => {
  const [showList, setShowList] = useState(false);

  return (
    <Fragment>
      {!showList && (
        <ListGroupItem
          data-test-id={dataTestId}
          style={{ color: "#003bff" }}
          onClick={() => {
            setShowList(!showList);
          }}
        >
          ...show {works.length} more
        </ListGroupItem>
      )}
      <Collapse
        data-test-id={"collapse"}
        isOpen={showList}
        style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}
      >
        {works.map(work => (
          <SourceWorkComponent
            organizations={organizations}
            linkActive={
              isAggregatedView ? false : work.id.endsWith(sourceWorkId || "")
            }
            work={work}
            key={work.id}
          />
        ))}
      </Collapse>
    </Fragment>
  );
};
