import classnames from "classnames";
import { withFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { CheckboxGroup } from "../../components/CheckboxGroup/CheckboxGroup.component";
import { ExportButtonRow } from "../../components/ExportButtonRow/ExportButtonRow.component";
import { ExportCard } from "../../components/ExportCard/ExportCard.component";
import { FillHeightContainer } from "../../components/FillHeightContainer/FillHeightContainer";
import { FilterByDate } from "../../components/FilterByDate/FilterByDate.component";
import { InfoIconComponent } from "../../components/InfoIcon/InfoIcon.component";
import { SpinnerBox } from "../../components/SpinnerBox/SpinnerBox.component";
import { ConflictType, Date, ExportType } from "../../types";
import { Definitions } from "../../utils/Definitions";
import { ExportFiltersSchema } from "../../utils/form-validators";
import { ListLoadingState, LoadingState } from "../../utils/LoadingState";
import { ExportProps } from "./Export";
import { FormValues } from "./types";

const linkClassifications = [
  { id: "valid", name: "Valid" },
  { id: "probable", name: "Probable" },
  { id: "possible", name: "Possible" },
  { id: "invalid", name: "Invalid" }
];

const initialValues = {
  fromDate: {
    day: "",
    month: "",
    year: ""
  },
  toDate: {
    day: "",
    month: "",
    year: ""
  },
  linkClassification: [],
  nameIPI: "",
  importId: "",
  isValid: false,
  exportType: ExportType.ISWC,
  organizationId: "",
  conflicts: ConflictType.INCLUDE
};

// TODO Move entire formik part to a dumb component
// TODO Refactor prop types
const InnerForm = ({
  exports,
  fetchExports,
  loadingState,
  fetchExportDownloadLink,
  stopPeriodicExportsRefresh,
  organizations,
  errors,
  touched,
  values,
  handleChange,
  resetForm,
  exportLoadingState,
  previewLoadingState,
  fetchOrganizations,
  cancelExport,
  handleSubmit,
  submitCount,
  activeFilterTab,
  setActiveFilterTab
}: ExportProps & {
  errors: any;
  touched: any;
  isValid: any;
  values: any;
  handleChange: any;
  resetForm: any;
  handleSubmit: any;
  submitCount: any;
}) => {
  useEffect(() => {
    fetchExports();
    if (!organizations.length) {
      fetchOrganizations();
    }
    return () => stopPeriodicExportsRefresh();
  }, []);

  const toggle = (tab: string) => {
    if (activeFilterTab !== tab) {
      setActiveFilterTab(tab);
      resetForm({
        ...initialValues,
        linkClassification: values.linkClassification,
        exportType: values.exportType,
        conflicts: values.conflicts
      });
    }
  };

  const isPreviewFetching = previewLoadingState === LoadingState.FETCHING;
  const isExportTypeAsSupplied = values.exportType === ExportType.SUPPLIED;

  return (
    <FillHeightContainer>
      <Breadcrumb className="container mt-5 mb-0">
        <BreadcrumbItem active>Export</BreadcrumbItem>
      </Breadcrumb>
      <Container className="mt-3 mb-5">
        <Form onSubmit={handleSubmit}>
          <Nav tabs>
            <NavItem>
              <NavLink
                data-test-id={"date-filter-tab"}
                href="#"
                className={classnames({ active: activeFilterTab === "1" })}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  e.preventDefault();
                  toggle("1");
                }}
              >
                Filter By Date
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-test-id={"IPI-filter-tab"}
                href="#"
                className={classnames({ active: activeFilterTab === "2" })}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  e.preventDefault();
                  toggle("2");
                }}
              >
                Filter By IPI Name Number
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-test-id={"import-id-filter-tab"}
                href="#"
                className={classnames({ active: activeFilterTab === "3" })}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  e.preventDefault();
                  toggle("3");
                }}
              >
                Filter By Import Id
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeFilterTab}>
            <TabPane tabId="1" className="pt-3">
              <FilterByDate
                errors={errors}
                touched={touched}
                values={values}
                handleChange={handleChange}
                previewLoadingState={previewLoadingState}
                submitCount={submitCount}
              />
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="6" className="pt-3">
                  <FormGroup>
                    <Label for="nameIPI" className="text-muted">
                      IPI Name Number
                    </Label>
                    <Input
                      data-test-id={"IPI-input"}
                      invalid={!!(submitCount && errors.nameIPI)}
                      disabled={isPreviewFetching}
                      type="text"
                      name="nameIPI"
                      id="nameIPI"
                      value={values.nameIPI}
                      onChange={handleChange}
                    />
                    <FormFeedback data-test-id={"IPI-validation-message"}>
                      {errors.nameIPI}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="6" className="pt-3">
                  <FormGroup>
                    <Label for="importId" className="text-muted">
                      Import Id
                    </Label>
                    <Input
                      invalid={!!(submitCount && errors.importId)}
                      disabled={isPreviewFetching}
                      type="text"
                      name="importId"
                      id="importId"
                      value={values.importId}
                      onChange={handleChange}
                    />
                    <FormFeedback data-test-id={"import-id-validation-message"}>
                      {errors.importId}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <div className="row pt-3">
            <FormGroup className="col-4 col-md-3">
              <Label className="text-muted">Confidence</Label>
              <CheckboxGroup
                checkboxFields={linkClassifications}
                checkboxValues={values.linkClassification}
                disabled={isPreviewFetching}
              />
            </FormGroup>
            <FormGroup className="col-4 col-md-3">
              <Label for="conflicts" className="text-muted">
                Conflicts
              </Label>
              <CustomInput
                disabled={isPreviewFetching || isExportTypeAsSupplied}
                type="radio"
                required={false}
                id={ConflictType.EXCLUDE}
                name="conflicts"
                label="Exclude"
                value={ConflictType.EXCLUDE}
                checked={values.conflicts === ConflictType.EXCLUDE}
                onChange={handleChange}
              >
                <InfoIconComponent
                  id={`info-${ConflictType.EXCLUDE}`}
                  infoText={Definitions[ConflictType.EXCLUDE]}
                />
              </CustomInput>
              <CustomInput
                disabled={isPreviewFetching || isExportTypeAsSupplied}
                type="radio"
                required={false}
                id={ConflictType.ONLY}
                name="conflicts"
                label="Only"
                value={ConflictType.ONLY}
                checked={values.conflicts === ConflictType.ONLY}
                onChange={handleChange}
              >
                <InfoIconComponent
                  id={`info-${ConflictType.ONLY}`}
                  infoText={Definitions[ConflictType.ONLY]}
                />
              </CustomInput>
              <CustomInput
                disabled={isPreviewFetching || isExportTypeAsSupplied}
                type="radio"
                required={false}
                id={ConflictType.INCLUDE}
                name="conflicts"
                label="Include"
                value={ConflictType.INCLUDE}
                checked={values.conflicts === ConflictType.INCLUDE}
                onChange={handleChange}
              >
                <InfoIconComponent
                  id={`info-${ConflictType.INCLUDE}`}
                  infoText={Definitions[ConflictType.INCLUDE]}
                />
              </CustomInput>
            </FormGroup>
            <FormGroup className="col-4 col-md-3">
              <Label for="exportType" className="text-muted">
                Export Type
              </Label>
              <CustomInput
                disabled={isPreviewFetching}
                type="radio"
                id={ExportType.ISWC}
                name="exportType"
                label="By ISWC"
                value={ExportType.ISWC}
                checked={values.exportType === ExportType.ISWC}
                onChange={e => {
                  resetForm({
                    ...values,
                    organizationId: initialValues.organizationId
                  });
                  handleChange(e);
                }}
              />
              <CustomInput
                disabled={isPreviewFetching}
                type="radio"
                id={ExportType.SUPPLIER_ID}
                name="exportType"
                label="By Supplier Id"
                value={ExportType.SUPPLIER_ID}
                checked={values.exportType === ExportType.SUPPLIER_ID}
                onChange={handleChange}
              />
              <CustomInput
                disabled={isPreviewFetching}
                type="radio"
                id={ExportType.SUPPLIED}
                name="exportType"
                label="As Supplied"
                value={ExportType.SUPPLIED}
                checked={values.exportType === ExportType.SUPPLIED}
                onChange={e => {
                  resetForm({
                    ...values,
                    conflicts: undefined
                  });
                  handleChange(e);
                }}
              />
            </FormGroup>
          </div>

          {values.exportType &&
            ["bySupplierId", "asSupplied"].includes(values.exportType) && (
              <FormGroup>
                <Col sm="6">
                  <Row>
                    <Label className="text-muted" for="organizationId">
                      Organization
                    </Label>
                    <Input
                      invalid={!!(submitCount && errors.organizationId)}
                      type="select"
                      name="organizationId"
                      id="organizationId"
                      onChange={handleChange}
                      value={values.organizations}
                    >
                      <option value="">Select</option>
                      {organizations.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors.organizationId}</FormFeedback>
                  </Row>
                </Col>
              </FormGroup>
            )}
          <ExportButtonRow
            previewLoadingState={previewLoadingState}
            exportLoadingState={exportLoadingState}
          />
        </Form>
      </Container>
      <Container className="pt-4">
        <React.Fragment>
          {exports.map(exportItem => (
            <ExportCard
              cancelExport={cancelExport}
              key={exportItem.exportId}
              exportItem={exportItem}
              getDownloadLink={fetchExportDownloadLink}
              organizations={organizations}
            />
          ))}
          {loadingState === ListLoadingState.FETCHING && (
            <SpinnerBox className="h-100" />
          )}
        </React.Fragment>
      </Container>
    </FillHeightContainer>
  );
};

// TODO Move to Export.ts
export const ExportComponent = withFormik<ExportProps, FormValues>({
  mapPropsToValues: (): FormValues => ({ ...initialValues }),
  validationSchema: ({ activeFilterTab }: ExportProps) =>
    ExportFiltersSchema(activeFilterTab),

  handleSubmit: (values: FormValues, { props }: { props: ExportProps }) => {
    const convertDate = (date: Date) =>
      moment(`${date.day}${date.month}${date.year}`, "DMMMYYYY")
        .format("YYYY-MM-DD")
        .valueOf();
    const fromDate = values.fromDate.day ? convertDate(values.fromDate) : "";
    const toDate = values.fromDate.day ? convertDate(values.toDate) : "";

    props.createExport({
      ...values,
      dates: { fromDate, toDate }
    });
  }
})(InnerForm);
