import * as React from "react";
import { Col, Row } from "reactstrap";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const FillHeightContainer = ({ className, children }: Props) => {
  return (
    <Row className="h-100" noGutters>
      <Col>
        <div
          className={`h-100 d-flex flex-column${
            className ? " " + className : ""
          }`}
        >
          {children}
        </div>
      </Col>
    </Row>
  );
};
