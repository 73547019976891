import { SearchLogEntry, Work } from "../../types";
import { ListLoadingState } from "../../utils/LoadingState";
import {
  FETCH_SEARCH,
  FETCH_SEARCH_FAIL,
  FETCH_SEARCH_SUCCESS,
  SearchActionTypes
} from "./Search.action.types";

export interface SearchState {
  searchResult: Work[];
  searchHistory: SearchLogEntry[];
  errorMsg: string;
  loadingState: ListLoadingState;
  nextLink?: string;
}

export const initialState: SearchState = {
  searchResult: [],
  searchHistory: [],
  errorMsg: "",
  loadingState: ListLoadingState.PRISTINE
};

export const searchReducer = (
  state: SearchState = initialState,
  action: SearchActionTypes
): SearchState => {
  switch (action.type) {
    case FETCH_SEARCH:
      const addToSearchLog =
        state.searchHistory.findIndex(
          (item: SearchLogEntry) => item.id === action.id
        ) === -1;
      const loadingState = action.nextPage
        ? ListLoadingState.FETCHING_MORE
        : ListLoadingState.FETCHING;
      return {
        ...state,
        searchResult:
          loadingState === ListLoadingState.FETCHING ? [] : state.searchResult,
        errorMsg: "",
        loadingState,
        nextLink: "",
        searchHistory: [
          ...(addToSearchLog
            ? [
                {
                  id: action.id,
                  link: `/search/${action.id}${
                    action.searchType ? "?type=" + action.searchType : ""
                  }`,
                  type: action.searchType
                }
              ]
            : []),
          ...state.searchHistory
        ]
      };
    case FETCH_SEARCH_FAIL:
      return {
        ...state,
        errorMsg: action.error,
        searchResult: [],
        loadingState: ListLoadingState.FAILED,
        nextLink: ""
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        searchResult:
          state.loadingState === ListLoadingState.FETCHING_MORE
            ? state.searchResult.concat(action.searchResult)
            : action.searchResult,
        loadingState: ListLoadingState.FETCHED,
        nextLink: action.nextLink
      };

    default:
      return state;
  }
};
