import * as React from "react";
import { useState } from "react";
import {
  Button,
  Modal as ReactstrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

interface Props {
  children: React.ReactNode;
  onConfirm: () => void;
  title: string;
  message: string;
}

export const Modal = ({ children, onConfirm, title, message }: Props) => {
  const [isOpen, updateIsOpen] = useState(false);
  const toggle = () => updateIsOpen(!isOpen);

  return (
    <React.Fragment>
      <div onClick={toggle}>{children}</div>
      <ReactstrapModal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onConfirm();
              toggle();
            }}
          >
            Confirm
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </ReactstrapModal>
    </React.Fragment>
  );
};
