import axios, { AxiosResponse } from "axios";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { apiUrls } from "../../api.config";
import {
  RecordingGroupResponse,
  WorkAggregatedResponse
} from "../../api.types";
import { AppState } from "../../App.reducer";
import { Errors } from "../../utils/UnexpectedErrors";
import {
  fetchAggregatedAction,
  fetchAggregatedFailAction,
  fetchAggregatedSuccessAction,
  fetchRecordingGroupAction,
  fetchRecordingGroupFailAction,
  fetchRecordingGroupSuccessAction
} from "./Aggregated.actions";

export const fetchAggregatedEffect = (
  id: string,
  recordingGroup?: string,
  offset?: number,
  limit: number = 10
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => {
  dispatch(fetchAggregatedAction(id));
  axios
    .get<WorkAggregatedResponse>(`${apiUrls.aggregatedWork}/${id}`)
    .then((response: AxiosResponse<WorkAggregatedResponse>) => {
      if (response.status === 200) {
        dispatch(
          fetchAggregatedSuccessAction(response.data.response.aggregatedWork)
        );
        if (recordingGroup != null && offset != null && limit != null) {
          dispatch(fetchRecordingGroupEffect(recordingGroup, offset, limit));
        }
      } else {
        dispatch(
          fetchAggregatedFailAction(
            response.data.error.message,
            response.status
          )
        );
      }
    })

    .catch(e => {
      if (e.response) {
        dispatch(
          fetchAggregatedFailAction(
            (e.response.data.error && e.response.data.error.message) ||
              e.response.data.message,
            e.response.status
          )
        );
      } else {
        dispatch(fetchAggregatedFailAction(Errors.uncatchError));
      }
    });
};

export const fetchRecordingGroupEffect = (
  recordingGroup: string,
  offset: number,
  limit: number = 10
): ThunkAction<void, AppState, void, Action<string>> => (
  dispatch: ThunkDispatch<AppState, void, Action<string>>,
  getState: () => AppState
) => {
  const state = getState().aggregated;
  dispatch(fetchRecordingGroupAction(recordingGroup));
  axios
    .get<RecordingGroupResponse>(
      `${apiUrls.aggregatedWork}/${
        state.id
      }/recordings/${recordingGroup}?offset=${offset}&limit=${limit}`
    )
    .then(response => {
      if (response.status === 200) {
        dispatch(
          fetchRecordingGroupSuccessAction(
            recordingGroup,
            response.data.response.recordings,
            response.data.response._links.next == null
          )
        );
      } else {
        dispatch(
          fetchRecordingGroupFailAction(
            recordingGroup,
            response.data.error.message,
            response.status
          )
        );
      }
    })

    .catch(e => {
      if (e.response) {
        dispatch(
          fetchRecordingGroupFailAction(
            recordingGroup,
            (e.response.data.error && e.response.data.error.message) ||
              e.response.data.message,
            e.response.status
          )
        );
      } else {
        dispatch(
          fetchRecordingGroupFailAction(recordingGroup, Errors.uncatchError)
        );
      }
    });
};
