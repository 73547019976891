import { Recording, Work } from "../../types";

export const FETCH_AGGREGATED_SUCCESS = "FETCH_AGGREGATED_SUCCESS";
export const FETCH_AGGREGATED_FAIL = "FETCH_AGGREGATED_FAIL";
export const FETCH_AGGREGATED = "FETCH_AGGREGATED";
export const FETCH_RECORDING_GROUP_AGGREGATED =
  "FETCH_RECORDING_GROUP_AGGREGATED";
export const FETCH_RECORDING_GROUP_AGGREGATED_SUCCESS =
  "FETCH_RECORDING_GROUP_AGGREGATED_SUCCESS";
export const FETCH_RECORDING_GROUP_AGGREGATED_FAIL =
  "FETCH_RECORDING_GROUP_AGGREGATED_FAIL";
export const SET_RECORDING_GROUP_IS_OPEN = "SET_RECORDING_GROUP_IS_OPEN";

interface FetchAggregatedAction {
  type: typeof FETCH_AGGREGATED;
  id: string;
}

interface FetchAggregatedSuccessAction {
  type: typeof FETCH_AGGREGATED_SUCCESS;
  aggregated: Work;
}

interface FetchAggregatedFailAction {
  type: typeof FETCH_AGGREGATED_FAIL;
  error: string;
  statusCode?: number;
}

interface FetchRecordingGroupAction {
  type: typeof FETCH_RECORDING_GROUP_AGGREGATED;
  recordingGroup: string;
}

interface FetchRecordingGroupSuccessAction {
  type: typeof FETCH_RECORDING_GROUP_AGGREGATED_SUCCESS;
  recordingGroup: string;
  recordings: Recording[];
  isLastPage: boolean;
}

interface FetchRecordingGroupFailAction {
  type: typeof FETCH_RECORDING_GROUP_AGGREGATED_FAIL;
  recordingGroup: string;
  error: string;
  statusCode?: number;
}

interface SetRecordingGroupIsOpenAction {
  type: typeof SET_RECORDING_GROUP_IS_OPEN;
  isOpen: boolean;
  recordingGroup: string;
}

export type AggregatedActionTypes =
  | FetchAggregatedAction
  | FetchAggregatedSuccessAction
  | FetchAggregatedFailAction
  | FetchRecordingGroupAction
  | FetchRecordingGroupSuccessAction
  | FetchRecordingGroupFailAction
  | SetRecordingGroupIsOpenAction;
