import { Import, Organization } from "../../types";
import {
  DOWNLOAD_IMPORT_FAIL,
  FETCH_IMPORT_DETAILS,
  FETCH_IMPORT_DETAILS_FAIL,
  FETCH_IMPORT_DETAILS_SUCCESS,
  FETCH_IMPORTS,
  FETCH_IMPORTS_FAIL,
  FETCH_IMPORTS_SUCCESS,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_FAIL,
  FETCH_ORGANIZATIONS_SUCCESS,
  ImportsActionTypes,
  SET_PERIODIC_REFRESH,
  UPLOAD_FILE,
  UPLOAD_FILE_FAIL,
  UPLOAD_FILE_PROGRESS
} from "./Import.action.types";

export const fetchImportsAction = (nextPage?: boolean): ImportsActionTypes => ({
  type: FETCH_IMPORTS,
  nextPage
});

export const fetchImportsSuccessAction = (
  imports: Import[],
  append: boolean,
  cancelImportUrl: string,
  fetchLogFileUrl: string,
  sourceFileUrl: string,
  nextToken?: string
): ImportsActionTypes => ({
  type: FETCH_IMPORTS_SUCCESS,
  imports,
  append,
  cancelImportUrl,
  fetchLogFileUrl,
  sourceFileUrl,
  ...(nextToken ? { nextToken } : {})
});

export const fetchImportsFailAction = (error: string): ImportsActionTypes => ({
  type: FETCH_IMPORTS_FAIL,
  error
});

export const fetchImportDetailsAction = (
  importId: string
): ImportsActionTypes => ({
  type: FETCH_IMPORT_DETAILS,
  importId
});

export const fetchImportDetailsFailAction = (
  error: string,
  importId: string
): ImportsActionTypes => ({
  type: FETCH_IMPORT_DETAILS_FAIL,
  error,
  importId
});

export const fetchImportDetailsSuccessAction = (
  importId: string,
  importDetail: Import
): ImportsActionTypes => ({
  type: FETCH_IMPORT_DETAILS_SUCCESS,
  importId,
  importDetail
});

export const fetchOrganizationsAction = (): ImportsActionTypes => ({
  type: FETCH_ORGANIZATIONS
});

export const fetchOrganizationsFailAction = (
  error: string
): ImportsActionTypes => ({
  type: FETCH_ORGANIZATIONS_FAIL,
  error
});

export const fetchOrganizationsSuccessAction = (
  organizations: Organization[]
): ImportsActionTypes => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  organizations
});

export const uploadFileAction = (placeholder: Import): ImportsActionTypes => ({
  type: UPLOAD_FILE,
  placeholder
});

export const uploadFileProgressAction = (
  progress: number,
  importId: string
): ImportsActionTypes => ({
  type: UPLOAD_FILE_PROGRESS,
  progress,
  importId
});

export const uploadFileFailAction = (error: string): ImportsActionTypes => ({
  type: UPLOAD_FILE_FAIL,
  error
});

export const setPeriodicRefreshAction = (
  intervalId: number | undefined,
  interval: number
): ImportsActionTypes => ({
  type: SET_PERIODIC_REFRESH,
  intervalId,
  interval
});

export const downloadImportFailAction = (
  error: string
): ImportsActionTypes => ({
  type: DOWNLOAD_IMPORT_FAIL,
  error
});
