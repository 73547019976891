import { Recording, Work } from "../../types";

export const FETCH_DETAIL = "FETCH_DETAIL";
export const FETCH_DETAIL_SUCCESS = "FETCH_DETAIL_SUCCESS";
export const FETCH_DETAIL_FAIL = "FETCH_DETAIL_FAIL";
export const FETCH_RECORDING_GROUP = "FETCH_RECORDING_GROUP";
export const FETCH_RECORDING_GROUP_SUCCESS = "FETCH_RECORDING_GROUP_SUCCESS";
export const FETCH_RECORDING_GROUP_FAIL = "FETCH_RECORDING_GROUP_FAIL";
export const SET_RECORDING_GROUP_IS_OPEN = "SET_RECORDING_GROUP_IS_OPEN";

interface FetchDetailAction {
  type: typeof FETCH_DETAIL;
  id: string;
}

interface FetchDetailSuccessAction {
  type: typeof FETCH_DETAIL_SUCCESS;
  work: Work;
}

interface FetchDetailFailAction {
  type: typeof FETCH_DETAIL_FAIL;
  error: string;
  statusCode?: number;
}

interface FetchRecordingGroupAction {
  type: typeof FETCH_RECORDING_GROUP;
  recordingGroup: string;
}

interface FetchRecordingGroupSuccessAction {
  type: typeof FETCH_RECORDING_GROUP_SUCCESS;
  recordingGroup: string;
  recordings: Recording[];
}

interface FetchRecordingGroupFailAction {
  type: typeof FETCH_RECORDING_GROUP_FAIL;
  recordingGroup: string;
  error: string;
}

interface SetRecordingGroupIsOpenAction {
  type: typeof SET_RECORDING_GROUP_IS_OPEN;
  isOpen: boolean;
  recordingGroup: string;
}

export type DetailActionTypes =
  | FetchDetailAction
  | FetchDetailSuccessAction
  | FetchDetailFailAction
  | FetchRecordingGroupAction
  | FetchRecordingGroupSuccessAction
  | FetchRecordingGroupFailAction
  | SetRecordingGroupIsOpenAction;
