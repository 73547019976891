import { Work } from "../../types";

export const FETCH_SEARCH = "FETCH_SEARCH";
export const FETCH_SEARCH_FAIL = "FETCH_SEARCH_FAIL";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";

interface FetchSearchAction {
  type: typeof FETCH_SEARCH;
  searchType: string;
  id: string;
  nextPage: boolean;
}

interface FetchSearchFailAction {
  type: typeof FETCH_SEARCH_FAIL;
  error: string;
}

interface FetchSearchSuccessAction {
  type: typeof FETCH_SEARCH_SUCCESS;
  searchResult: Work[];
  nextLink: string;
}

export type SearchActionTypes =
  | FetchSearchSuccessAction
  | FetchSearchAction
  | FetchSearchFailAction;
