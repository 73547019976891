import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../App.reducer";
import {
  fetchDetailEffect,
  fetchRecordingGroupEffect
} from "../Detail/Detail.effects";
import { fetchOrganizationsEffect } from "../Import/Import.effects";
import { Recordings } from "./Recordings.component";

const mapStateToProps = ({
  detail: { data, loadingState },
  imports: { organizations }
}: AppState) => ({
  data,
  loadingState,
  organizations
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action<string>>
) => ({
  fetchOrganizations: () => dispatch(fetchOrganizationsEffect()),
  fetchDetail: (
    id: string,
    recordingGroup?: string,
    offset?: number,
    limit?: number
  ) => dispatch(fetchDetailEffect(id, recordingGroup, offset, limit)),
  fetchRecordingGroup: (
    recordingGroup: string,
    offset: number,
    limit: number
  ) => dispatch(fetchRecordingGroupEffect(recordingGroup, offset, limit))
});

export const RecordingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Recordings);

export type RecordingsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{
    id: string;
    recordingGroup: string;
  }>;
