import { Recording, Work } from "../../types";
import {
  AggregatedActionTypes,
  FETCH_AGGREGATED,
  FETCH_AGGREGATED_FAIL,
  FETCH_AGGREGATED_SUCCESS,
  FETCH_RECORDING_GROUP_AGGREGATED,
  FETCH_RECORDING_GROUP_AGGREGATED_FAIL,
  FETCH_RECORDING_GROUP_AGGREGATED_SUCCESS,
  SET_RECORDING_GROUP_IS_OPEN
} from "./Aggregated.action.types";

export function fetchAggregatedAction(id: string): AggregatedActionTypes {
  return {
    type: FETCH_AGGREGATED,
    id
  };
}

export function fetchAggregatedSuccessAction(
  aggregated: Work
): AggregatedActionTypes {
  return {
    type: FETCH_AGGREGATED_SUCCESS,
    aggregated
  };
}

export function fetchAggregatedFailAction(
  error: string,
  statusCode?: number
): AggregatedActionTypes {
  return {
    type: FETCH_AGGREGATED_FAIL,
    error,
    statusCode
  };
}

export function fetchRecordingGroupAction(
  recordingGroup: string
): AggregatedActionTypes {
  return {
    type: FETCH_RECORDING_GROUP_AGGREGATED,
    recordingGroup
  };
}

export function fetchRecordingGroupSuccessAction(
  recordingGroup: string,
  recordings: Recording[],
  isLastPage: boolean
): AggregatedActionTypes {
  return {
    type: FETCH_RECORDING_GROUP_AGGREGATED_SUCCESS,
    recordingGroup,
    recordings,
    isLastPage
  };
}

export function fetchRecordingGroupFailAction(
  recordingGroup: string,
  error: string,
  statusCode?: number
): AggregatedActionTypes {
  return {
    type: FETCH_RECORDING_GROUP_AGGREGATED_FAIL,
    recordingGroup,
    error,
    statusCode
  };
}

export function setRecordingGroupIsOpenAction(
  recordingGroup: string,
  isOpen: boolean
): AggregatedActionTypes {
  return {
    type: SET_RECORDING_GROUP_IS_OPEN,
    recordingGroup,
    isOpen
  };
}
