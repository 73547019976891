const rememberMe = () => !!localStorage.getItem("rememberMe");

export const setAuth = (auth: string) =>
  rememberMe()
    ? localStorage.setItem("auth", auth)
    : sessionStorage.setItem("auth", auth);

export const getAuth = () =>
  rememberMe() ? localStorage.getItem("auth") : sessionStorage.getItem("auth");

export const clearAuth = () =>
  rememberMe()
    ? localStorage.removeItem("auth")
    : sessionStorage.removeItem("auth");
