export enum Env {
  QA = "QA",
  TEST = "TEST"
}

export const env =
  window.location.href.indexOf("qa.elixir.ascap.com") > -1 ? Env.QA : Env.TEST;

export const baseUrl =
  env === Env.QA
    ? "https://core-api.qa.elixir.ascap.com"
    : "https://core-api.elixir.onstage.dev";

export const apiUrls = {
  auth: `${baseUrl}/authentication`,
  refresh: `${baseUrl}/authentication/refresh`,
  organizations: `${baseUrl}/authentication/organizations`,
  workIds: `${baseUrl}/graph/search`,
  work: `${baseUrl}/graph/work`,
  aggregatedWork: `${baseUrl}/graph/aggregated-work`,
  statistics: `${baseUrl}/graph/statistics`,
  initUpload: `${baseUrl}/upload`,
  status: `${baseUrl}/import-status`,
  postExport: `${baseUrl}/export`,
  previewExport: `${baseUrl}/export/preview`,
  exports: `${baseUrl}/export/exports`,
  exportDetail: `${baseUrl}/export/export`,
  importCancel: `${baseUrl}/importer/import/`
};

export const protectedUrls = [
  apiUrls.organizations,
  apiUrls.workIds,
  apiUrls.work,
  apiUrls.aggregatedWork,
  apiUrls.statistics,
  apiUrls.initUpload,
  apiUrls.status,
  apiUrls.postExport,
  apiUrls.previewExport,
  apiUrls.exports,
  apiUrls.exportDetail,
  apiUrls.importCancel
];
